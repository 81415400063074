import { OrgRoleType } from "./org-role-type.enum";

export class UserMappingWithRelatedOrgs {
  public organizationId: string;
  public organizationName: string;
  public isGovAgency: boolean;
  public isOperator: boolean;
  public isRegisteredAgent: boolean;
  public isServiceComp: boolean;
  public orgRoleName: string;
  public orgRoleId: string;
  public relationType: string;
  public operatorName: string;
  public operatorOrgId: string;
  public operatorNumber: string;

  constructor(obj?: UserMappingWithRelatedOrgs) {
    if (obj) {
      this.organizationId = obj.organizationId;
      this.organizationName = obj.organizationName;
      this.isGovAgency = obj.isGovAgency;
      this.isOperator = obj.isOperator;
      this.isRegisteredAgent = obj.isRegisteredAgent;
      this.isServiceComp = obj.isServiceComp;
      this.orgRoleName = obj.orgRoleName;
      this.orgRoleId = obj.orgRoleId;
      this.relationType = obj.relationType;
      this.operatorName = obj.operatorName;
      this.operatorOrgId = obj.operatorOrgId;
      this.operatorNumber = obj.operatorNumber;
    }
  }

  public get OrgRole(): OrgRoleType {
    if (this.orgRoleName == "Supervisor") return OrgRoleType.Supervisor;
    if (this.orgRoleName == "DataSubmitter") return OrgRoleType.DataSubmitter;
    if (this.orgRoleName == "User") return OrgRoleType.User;
    if (this.orgRoleName == "Representative") return OrgRoleType.Representative;
    if (this.orgRoleName == "Delete") return OrgRoleType.Delete;
    return OrgRoleType.None;
  }
}
