<ng-container *ngIf="existingCASNumbersWithNgSelectDisplayField && existingCASNumbersWithNgSelectDisplayField.length > 0" >
  <ng-select #casDropdown [(ngModel)]="casShortNumberValue" (ngModelChange)="casNumberDropdownChanged($event)"
             (search)="casNumberDropdownSearch($event)" [ngClass]="{'invalid' : !isValid}"
             [items]="existingCASNumbersWithNgSelectDisplayField" bindLabel="numberAndDescription"
             [placeholder]="'Select a CAS Number'"
             [style.height]="cellHeight" [style.width]="cellWidth" (blur)="focusOut()" class="cas-number">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div [ngClass]="{'ng-select-common-name-item': item.isCommonName}">{{item.numberAndDescription}}</div>
    </ng-template>
  </ng-select>
</ng-container>
<input #casInput *ngIf="!existingCASNumbersWithNgSelectDisplayField || existingCASNumbersWithNgSelectDisplayField.length == 0"
       [(ngModel)]="casNumberValue" [ngClass]="{'invalid' : !isValid}" maxlength="10" [style.height]="cellHeight" [style.width]="cellWidth" (keyup)="onKeyUp($event)" (blur)="focusOut()" />
