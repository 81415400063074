import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-impersonate-user-cell',
  templateUrl: './impersonate-user-cell.component.html',
  styleUrls: ['./impersonate-user-cell.component.scss']
})
export class ImpersonateUserCellComponent implements ICellRendererAngularComp {
  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public emailConfirmed: boolean = null;
  public isActivated: boolean = null;
  public isDenied: boolean = null;
  public readOnly: boolean;
  public disabled: boolean = true;
  public isPrimaryOrg: boolean = true;

  constructor() { }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.emailConfirmed = this.data.emailConfirmed;
    this.isActivated = this.data.isActivated;
    this.isDenied = this.data.isDenied;
    this.isPrimaryOrg = (this.data.primaryOrganizationId && this.data.organizationId === this.data.primaryOrganizationId);
    this.disabled = this.data.status !== "Active";
    if (this.data.status === "Locked") {
      this.disabled = !this.isActivated || this.isDenied || !this.isPrimaryOrg;
    }
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  public impersonate() {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "impersonate", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

}
