import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavSubMenuHeaderDirectiveDirective } from '../../ui/components/main-nav/nav-sub-menu-header.directive';

@Injectable({
  providedIn: 'root'
})
export class MainNavService {
  /**************************************************************************************************************************/
  //TODO Make Observables?
  menuKey: Subject<string> = new Subject<string>();
  menuShiftTab: Subject<string> = new Subject<string>();
  subMenuHeaderTabClick: Subject<NavSubMenuHeaderDirectiveDirective> = new Subject<NavSubMenuHeaderDirectiveDirective>();
  subHeaderFocus: Subject<NavSubMenuHeaderDirectiveDirective> = new Subject<NavSubMenuHeaderDirectiveDirective>();
  closeOpenSubMenu: Subject<boolean> = new Subject<boolean>();
  selectedSubMenuHeader: Subject<NavSubMenuHeaderDirectiveDirective> = new Subject<NavSubMenuHeaderDirectiveDirective>();
  selectedSubMenuHeaderNoExpand: Subject<NavSubMenuHeaderDirectiveDirective> = new Subject<NavSubMenuHeaderDirectiveDirective>();
  setSelectedSubMenuHeader: Subject<string> = new Subject<string>();
  selectedSubMenuHeaderByEsc: Subject<boolean> = new Subject<boolean>();
  setFocusOutsideNav: Subject<boolean> = new Subject<boolean>();
  isSubMenuParentOver: Subject<NavSubMenuHeaderDirectiveDirective> = new Subject<NavSubMenuHeaderDirectiveDirective>();
  isSubMenuParentOut: Subject<NavSubMenuHeaderDirectiveDirective> = new Subject<NavSubMenuHeaderDirectiveDirective>();
  /**************************************************************************************************************************/
  constructor() { }

  //set selected header and expand sub menu
  //1. sub menu header focus event on desktop, 2. sub menu header tab click, 3. sub menu shift tab
  public broadcastSelectedHeaderExpand(value: NavSubMenuHeaderDirectiveDirective) {
    this.selectedSubMenuHeader.next(value);
  }
  //select a sub menu header but not expand the sub menu
  //1. triggered by selectSubMenuHeaderByEsc
  public broadcastSelectedHeaderNoExpand(value: NavSubMenuHeaderDirectiveDirective) {
    this.selectedSubMenuHeaderNoExpand.next(value);
  }

  //1. Sub menu header select the first menu item, 2. sub menu item arrow up, 3. sub menu item arrow down
  public broadcastSetSelectedHeader(value: string) {
    this.setSelectedSubMenuHeader.next(value);
  }
  //esc on submenu item, need to close menu and select top level sub menu button
  //1. sub menu item esc
  public broadcastSelectHeaderBySubItemEsc(value: boolean) {
    this.selectedSubMenuHeaderByEsc.next(value);
  }
  //open submenu, tab keyboard event
  //1. sub menu header arrow right, 2. sub menu item tab, 3. sub menu right arrow
  public broadcastMenuKey(value: string) {
    this.menuKey.next(value);
  }
  //open menu, shift tab event
  //1. sub header shift tab, 2. sub header arrow left, 3. sub menu item shift tab, 4. sub menu item arrow left
  public broadcastMenuShiftTab(value: string) {
    this.menuShiftTab.next(value);
  }
  //sub header button focus
  //1. sub header focus
  public broadcastSubHeaderFocus(value: NavSubMenuHeaderDirectiveDirective) {
    this.subHeaderFocus.next(value);
  }
  //close any open sub menu
  public broadcastCloseOpenSubMenu(value: boolean) {
    this.closeOpenSubMenu.next(value);
  }
  //move foucs outside on nav bar
  public broadcastSetFocusOutsideNavMenu(before: boolean): void {
    this.setFocusOutsideNav.next(before);
  }

  public broadcastIsSubMenuParentOver(value: NavSubMenuHeaderDirectiveDirective) {
    this.isSubMenuParentOver.next(value);
  }
  public broadcastIsSubMenuParentOut(value: NavSubMenuHeaderDirectiveDirective) {
    this.isSubMenuParentOut.next(value);
  }
}
