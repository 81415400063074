import { Injectable, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, tap } from 'rxjs';
import { LoggingService } from '../../common/services/logging.service';
import { CaptchaSettings } from '../models/captcha-settings.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CreateAssessmentRequestModel } from '../models/create-assessment-request.model';


@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

  captchaSettings: CaptchaSettings | null = null;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  //This isn't going to change, so once fetched, keep it.
  public getCaptchaSettings(forceRefresh: boolean = false): Observable<CaptchaSettings> {
    if (this.captchaSettings && !forceRefresh) {
      return of(this.captchaSettings);
    }
    else {
      return this.http.get<CaptchaSettings>(`/api/recaptcha/captchasettings`).pipe(tap(res => this.captchaSettings = res));
    }
  }

  public createAssessment(createAssessmentRequest: CreateAssessmentRequestModel): Observable<number> {
    return this.http.post<number>(`/api/recaptcha/createassessment`, createAssessmentRequest);
  }

  public requestToken(action: string): Observable<string>  {
    return this.recaptchaV3Service.execute(action);
  }

}
