import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'admin-functions-cell',
  templateUrl: './admin-functions-cell.component.html',
  styleUrls: ['./admin-functions-cell.component.scss']
})
export class AdminFunctionsCellComponent implements OnInit, ICellRendererAngularComp {
  public data: any;
  public api: GridApi;
  public rowIndex: number = -1;
  public readOnly: boolean = true;
  public params: ICellRendererParams;
  constructor(private router: Router) {
    console.log('opendeletecell constructed');
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    console.log('ag init', this.data)
  }
  refresh(params: ICellRendererParams): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  ngOnInit(): void {
  }

  viewPdf(): void {
    window.open("/api/file/GetDisclosurePdfByName/" + this.data.disclosureId + "/" + encodeURIComponent(this.data.pdfName), "_blank");
  }

  delete(): void {
    if ((this.api as any).onActionTrigger) {
      (this.api as any).onActionTrigger({ row: this.rowIndex, action: "delete", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  move(): void {
    this.params.node.setExpanded(!this.params.node.expanded);
  }
}
