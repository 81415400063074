import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, RowEditingStartedEvent, RowEditingStoppedEvent, RowNode, StartEditingCellParams } from 'ag-grid-community';
import { finalize, Subscription } from 'rxjs';
import { isNotNullOrEmpty } from '../../../../common/functions/is-not-null-or-empty.function';
import { DisclosureValidationStatement } from '../../../../common/models/disclosures/disclosure-validation-statement.model';
import { DisclosureValidationIdType } from '../../../models/disclosure-validation-id-type.enum';
import { InlineEditorActionTriggerParams } from '../../../models/inline-editor-cell-renderer/inline-editor-action-trigger-params.model';
import { IInlineEditorCellRendererParams } from '../../../models/inline-editor-cell-renderer/inline-editor-cell-renderer-params.model';
import { InlineEditorTriggerActionType } from '../../../models/inline-editor-cell-renderer/inline-editor-trigger-action-type.enum';

@Component({
  selector: 'inline-editor-cell-renderer',
  templateUrl: './inline-editor-cell-renderer.component.html',
  styleUrls: ['./inline-editor-cell-renderer.component.scss']
})
export class InlineEditorCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  private cellName: string = '';
  public params: IInlineEditorCellRendererParams;
  public data: any;
  public originalData: any;
  public currentStateOfData: any;
  public api: GridApi<any>;
  public readOnly: boolean = true;
  public canDelete: boolean = false;
  public onlyHandleDelete: boolean = false;
  public canAdd: boolean = true;
  public canSave: boolean = true;
  public canRenew: boolean = false;
  public allowMultipleOngoingEdits: boolean = false;
  private columnToStartEditKey: string = '0';
  public disclosureValidationStatements: Array<DisclosureValidationStatement> = [];
  private subscription: Subscription = new Subscription();

  constructor() {
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.api) {
      if (this.allowMultipleOngoingEdits) {
        this.api.removeEventListener("rowEditingStarted", this.rowEditingStartedEventHandler.bind(this));
      }
      //else {
      //  this.api.removeEventListener("rowEditingStopped", this.rowEditingStoppedEventHandler.bind(this));
      //}
    }
  }

  rowEditingStoppedEventHandler(event: RowEditingStartedEvent): void {
    let itemId = this.params.itemRowIdGetter(this.params);
    if (itemId == event.node.id) {
      this.stopEditing(true, true);
      //event.event.stopImmediatePropagation();
    }
  }

  rowEditingStartedEventHandler(event: RowEditingStartedEvent): void {
    let itemId = this.params.itemRowIdGetter(this.params);
    if (itemId == event.node.id)
      this.startEditing(true);
  }

  rowIsBeingEdited(): boolean {
    let editingCells = this.params.api.getEditingCells();
    if (editingCells && editingCells.length > 0 && editingCells.some(x => x.rowIndex == this.params.rowIndex))
      return true;
    else
      return false;
  }

  public agInit(params: IInlineEditorCellRendererParams): void {
    this.data = params.data;
    this.currentStateOfData = JSON.parse(JSON.stringify(params.data));
    this.api = params.api;
    this.cellName = params?.colDef?.cellRenderer?.toString() || '';
    this.params = params;

    if (this.params.allowMultipleOngoingEdits) {
      this.allowMultipleOngoingEdits = this.params.allowMultipleOngoingEdits
    }
    
    if (params.validityChangedEvent) {
      let validityChanged$ = params.validityChangedEvent.subscribe((result: Array<DisclosureValidationStatement>) => {
        this.params.disclosureValidationStatements = result;
        this.filterDisclosureValidation();
        this.subscription.add(validityChanged$);
      }, error => {
        console.log('Error listening for updated validation info ' + error);
        this.subscription.add(validityChanged$);
      });
    }

    if (params.dataChangedEvent/* && this.params.node.rowPinned*/) {
      let dataChanged$ = params.dataChangedEvent.subscribe((event: { value: any, field: string, itemId?: string }) => {
        if ((isNotNullOrEmpty(event.itemId) && this.params.itemRowIdGetter(this.params) == event.itemId) ||
          (params.node.rowPinned == "bottom" && event.itemId == "b-0")) {
          this.currentStateOfData[event.field] = event.value;
          if (this.params.canAdd !== null && this.params.canAdd !== undefined) {
            this.canAdd = this.params.canAdd instanceof Function ? this.params.canAdd(this.currentStateOfData) : this.params.canAdd;
          }

          if (this.params.canSave !== null && this.params.canSave !== undefined) {
            this.canSave = this.params.canSave instanceof Function ? this.params.canSave(this.currentStateOfData) : this.params.canSave;
          }
        }
        this.subscription.add(dataChanged$);
      }, error => {
        console.log('Error listening for updated validation info ' + error);
        this.subscription.add(dataChanged$);
      });
    }

    this.filterDisclosureValidation();

    this.originalData = JSON.parse(JSON.stringify(this.data));

    if (params.columnToStartEditKey !== null && params.columnToStartEditKey !== undefined)
      this.columnToStartEditKey = params.columnToStartEditKey;

    let isRowPinned = this.params.node.rowPinned;

    if (this.api) {
      if (this.allowMultipleOngoingEdits) {
        this.api.addEventListener("rowEditingStarted", this.rowEditingStartedEventHandler.bind(this));
      }
      //else {
      //  this.api.addEventListener("rowEditingStopped", this.rowEditingStoppedEventHandler.bind(this));
      //}
    }

    if (this.params.onlyHandleDelete !== null && this.params.onlyHandleDelete !== undefined) {
      this.onlyHandleDelete = this.params.onlyHandleDelete;
    }

    if (this.params.canDelete !== null && this.params.canDelete !== undefined) {
      this.canDelete = this.params.canDelete instanceof Function ? this.params.canDelete(params) : this.params.canDelete;
    }

    if (this.params.canAdd !== null && this.params.canAdd !== undefined) {
      this.canAdd = this.params.canAdd instanceof Function ? this.params.canAdd(params) : this.params.canAdd;
    }

    if (this.params.canSave !== null && this.params.canSave !== undefined) {
      this.canSave = this.params.canSave instanceof Function ? this.params.canSave(params.data) : this.params.canSave;
    }

    if (this.params.canRenew !== null && this.params.canRenew !== undefined) {
      this.canRenew = this.params.canRenew instanceof Function ? this.params.canRenew(params) : this.params.canRenew;
    }

    this.readOnly = params.isBeingEdited ? !params.isBeingEdited(params) : true;
  
  }

  filterDisclosureValidation() {
    //Filter out statements that don't pertain to our data type
    let itemId = this.params.itemRowIdGetter(this.params);
    if (this.params.idType) {
      switch (this.params.idType) {
        case DisclosureValidationIdType.Purpose:
          this.disclosureValidationStatements = this.params.disclosureValidationStatements.filter(x => x.purposeId == itemId && (x.ingredientsId == null || x.ingredientsId == undefined));
          break;
        case DisclosureValidationIdType.Ingredient:
          this.disclosureValidationStatements = this.params.disclosureValidationStatements.filter(x => x.ingredientsId == itemId);
          break;
        case DisclosureValidationIdType.WaterSource:

          break;
      }
    }

  }

  public refresh(params: any): boolean {
    return false;
  }

  public startEditing(hereFromEventListener: boolean = false): void {

    if (this.params.onActionTriggerHandler) {
      this.params.onActionTriggerHandler({
        context: this.params.context,
        row: this.params.node.rowIndex,
        rowId: this.params.itemRowIdGetter(this.params),
        action: InlineEditorTriggerActionType.StartedEditingRow,
        data: this.data
      } as InlineEditorActionTriggerParams);


      if (this.params.isBeingEdited !== undefined && this.params.isBeingEdited !== null) {
        this.readOnly = !this.params.isBeingEdited(this.params);
      }
    }

    if (!hereFromEventListener) {
      //Needed to get style rules to refresh and disable non edited rows.
      this.api.redrawRows();
      this.api.startEditingCell({
        rowIndex: this.params.node.rowIndex,
        colKey: this.columnToStartEditKey,
        rowPinned: this.params.node.rowPinned
      } as StartEditingCellParams);
    }

    console.log('end of togglereadonly Purpose', this.readOnly);
  }

  public stopEditing(revertData: boolean = true, hereFromEventHandler: boolean = false): void {
    //this.readOnly = true;

    if ((this.originalData || this.params.originalData) && revertData) {

      if (this.params.originalData != null && this.params.originalData != undefined) {
        if (this.params.originalData instanceof Function) {
          var paramData = this.params.originalData(this.currentStateOfData);
          if(paramData)
          this.originalData = JSON.parse(JSON.stringify(this.params.originalData(this.currentStateOfData)));
        }
        else {
          this.originalData = JSON.parse(JSON.stringify(this.params.originalData));
        }

      }

      let rowId = this.params.itemRowIdGetter(this.params);
      let rowNode: RowNode<any>;

      if (this.params.node.rowPinned == 'bottom') {
        rowNode = (this.api as GridApi).getPinnedBottomRow(this.params.node.rowIndex);
      }
      else if (this.params.node.rowPinned == 'top') {
        rowNode = (this.api as GridApi).getPinnedTopRow(this.params.node.rowIndex);
      }
      else {
        rowNode = (this.api as GridApi).getRowNode(rowId);
      }

      rowNode.data = JSON.parse(JSON.stringify(this.originalData));
      this.params.data = JSON.parse(JSON.stringify(this.originalData));
      if (this.params.node.detailNode) {
        this.params.node.detailNode.data = JSON.parse(JSON.stringify(this.originalData));
      }
    }

    if (this.params.onActionTriggerHandler) {
      this.params.onActionTriggerHandler({
        context: this.params.context,
        row: this.params.node.rowIndex,
        rowId: this.params.itemRowIdGetter(this.params),
        action: InlineEditorTriggerActionType.StoppedEditingRow,
        data: this.data
      } as InlineEditorActionTriggerParams);
    }

    if (this.params.isBeingEdited !== undefined && this.params.isBeingEdited !== null) {
      this.readOnly = !this.params.isBeingEdited(this.params);
    }

    if (!hereFromEventHandler) {
      this.api.stopEditing(revertData);
      //Needed to get style rules to refresh and re-enable non edited rows.
      this.api.redrawRows();
    }

    console.log('end of togglereadonly Purpose', this.readOnly);
  }

  public add(): void {
    this.stopEditing(false, false);

    if (this.params.onActionTriggerHandler) {
      this.params.onActionTriggerHandler({
        context: this.params.context,
        row: this.params.node.rowIndex,
        rowId: this.params.itemRowIdGetter(this.params),
        action: InlineEditorTriggerActionType.AddItemAsNewRow,
        data: this.data
      } as InlineEditorActionTriggerParams);
    }
  }

  public save(): void {
    this.stopEditing(false, false);
    if (this.params.onActionTriggerHandler) {
      this.params.onActionTriggerHandler({
        context: this.params.context,
        row: this.params.node.rowIndex,
        rowId: this.params.itemRowIdGetter(this.params),
        action: InlineEditorTriggerActionType.SaveRow,
        data: this.data
      } as InlineEditorActionTriggerParams);
    }
  }

  public deleteRow(): void {
    this.readOnly = true;
    this.api.stopEditing(true);

    if (this.params.onActionTriggerHandler) {
      this.params.onActionTriggerHandler({
        context: this.params.context,
        row: this.params.node.rowIndex,
        rowId: this.params.itemRowIdGetter(this.params),
        action: InlineEditorTriggerActionType.DeleteRow,
        data: this.data
      } as InlineEditorActionTriggerParams);
    }
  }

  public renew(): void {
    if (this.params.onActionTriggerHandler) {
      this.params.onActionTriggerHandler({
        context: this.params.context,
        row: this.params.node.rowIndex,
        rowId: this.params.itemRowIdGetter(this.params),
        action: InlineEditorTriggerActionType.RenewRow,
        data: this.data
      } as InlineEditorActionTriggerParams);
    }
  }

  hasError(): boolean {
    if (this.disclosureValidationStatements && this.disclosureValidationStatements.length > 0) {
      let errorExists = this.disclosureValidationStatements.filter(x => x.messageType == "Error" || x.messageType == "Critical Error").length > 0;
      if (errorExists)
        return true;
    }
    return false;
  }

  hasWarning(): boolean {
    if (this.disclosureValidationStatements && this.disclosureValidationStatements.length > 0) {
      let warningExists = this.disclosureValidationStatements.filter(x => x.messageType == "Warning").length > 0;
      if (warningExists)
        return true;
    }
    return false;
  }

  getErrorOrWarningMessage(type: string) {
    let message = "";

    if (this.hasError && type == "validationError") {
      let errorStatements = this.disclosureValidationStatements.filter(x => x.messageType == "Error" || x.messageType == "Critical Error");
      errorStatements.forEach(x => message += x.message + " ");
    }
    else if (this.hasError && type == "validationWarning") {
      let warningStatements = this.disclosureValidationStatements.filter(x => x.messageType == "Warning")
      warningStatements.forEach(x => message += x.message + " ");
    }

    return message;
  }
}
