import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggingService } from './logging.service';
import { PatchOperation } from '../models/patch-operation.model';
import { ThirdPartyPurpose } from '../models/disclosures/3rd-party-purpose';
import { ThirdPartyIngredient } from '../models/disclosures/3rd-party-ingredient';
import { Purpose } from '../models/disclosures/purpose.model';
import { Ingredient } from '../models/disclosures/ingredient.model';
import { CASNumber } from '../models/disclosures/cas-number.model';
import { CASNumberShort } from '../models/disclosures/cas-number-short.model';
import { CasNumberWithIngredientCounts } from '../models/disclosures/cas-number-with-ingredient-counts.model';
import { CASNumberSynonym } from '../models/disclosures/cas-number-synonym.model';
import { isNotNullOrEmpty } from '../functions/is-not-null-or-empty.function';
import { CasNumberSynonymWithUseCount } from '../models/disclosures/cas-number-synonym-with-use-count.model';

@Injectable()
export class IngredientService {
  constructor(private http: HttpClient, private loggingService: LoggingService) { }
  public ValidateCASNumberFormat(cn: string) {
    return this.http.get<any>(`/api/Ingredient/ValidateCASNumberFormat/${cn}`);
  }
  public GetAllCASNumbers() {
    return this.http.get<CASNumber[]>(`/api/Ingredient/GetAllCASNumbers/`);
  }
  public GetAllShortCASNumbers() {
    return this.http.get<CASNumberShort[]>(`/api/Ingredient/GetAllShortCASNumbers/`);
  }
  public GetAllCasNumbersWithIngredientCounts() {
    return this.http.get<CasNumberWithIngredientCounts[]>(`/api/Ingredient/GetAllCasNumbersWithIngredientCounts/`);
  }
  public GetCASNumbersByPartial(cn: string) {
    return this.http.get<CASNumberShort[]>(`/api/Ingredient/GetCASNumbersByPartial/${cn}`);
  }
  public GetCasNumberSynonymsByCasNumberId(casNumberId: string) {
    return this.http.get<CasNumberSynonymWithUseCount[]>(`/api/Ingredient/GetCasNumberSynonymsByCasNumberId/${casNumberId}`);
  }
  public GetAllCasNumberSynonyms() : Observable<CasNumberSynonymWithUseCount[]>{
    return this.http.get<CasNumberSynonymWithUseCount[]>(`/api/Ingredient/GetAllCasNumberSynonyms/`);
  }
  public SetCasNumberSynonymAsCommonName(casNumberSynonymIdToSetAsCommonName: string) {
    return this.http.post<boolean>(`/api/Ingredient/SetCasNumberSynonymAsCommonName/${casNumberSynonymIdToSetAsCommonName}`, null);
  }
  public patchCasNumber(casNumberId: string, patch: PatchOperation[]): Observable<CASNumber> {
    return this.http.patch<CASNumber>(`/api/Ingredient/UpdateCasNumber/${casNumberId}`, patch);
  }
  public patchCasNumberSynonym(casNumberSynonymId: string, patch: PatchOperation[]): Observable<CASNumberSynonym> {
    return this.http.patch<CASNumberSynonym>(`/api/Ingredient/UpdateCasNumberSynonym/${casNumberSynonymId}`, patch);
  }
  public GetAllIngredients(): Observable<Ingredient[]> {
    return this.http.get<Ingredient[]>(`/api/Ingredient/GetAllIngredients/`);
  }
  public GetIngredientsByCasNumber(casNumber: string): Observable<Ingredient[]> {
    return this.http.get<Ingredient[]>(`/api/Ingredient/GetIngredientsByCasNumber/${casNumber}`);
  }
  public get3rdPartyPurposes(orgId: string): Observable<ThirdPartyPurpose[]> {
    return this.http.get<ThirdPartyPurpose[]>(`/api/Ingredient/GetMy3rdPartyPurposes/${orgId}`);
  }
  public RenewMy3rdPartyPurpose(orgId: string, purposeId: string): Observable<ThirdPartyPurpose> {
    return this.http.get<ThirdPartyPurpose>(`/api/Ingredient/RenewMy3rdPartyPurpose/${orgId}/${purposeId}`);
  }
  public addMy3rdPartyPurpose(orgId: string, purpose: ThirdPartyPurpose): Observable<Array<ThirdPartyPurpose>> {
    return this.http.post<Array<ThirdPartyPurpose>>(`/api/Ingredient/AddMy3rdPartyPurpose/${orgId}`, purpose);
  }
  public putMy3rdPartyPurpose(orgId: string, purpose: ThirdPartyPurpose): Observable<ThirdPartyPurpose> {
    return this.http.put<ThirdPartyPurpose>(`/api/Ingredient/UpdateMy3rdPartyPurpose/${orgId}`, purpose);
  }
  public patchMy3rdPartyPurpose(orgId: string, refThirdPartyPurposeId: string, patch: PatchOperation[]): Observable<ThirdPartyPurpose> {
    return this.http.patch<ThirdPartyPurpose>(`/api/Ingredient/UpdateMyThirdPartyPurpose/${orgId}/${refThirdPartyPurposeId}`, patch);
  }
  public addMy3rdPartyIngredient(orgId: string, ingredient: ThirdPartyIngredient): Observable<ThirdPartyIngredient> {
    return this.http.post<ThirdPartyIngredient>(`/api/Ingredient/AddMy3rdPartyIngredient/${orgId}`, ingredient);
  }
  public putMy3rdPartyIngredient(orgId: string, ingredient: ThirdPartyIngredient): Observable<ThirdPartyIngredient> {
    return this.http.put<ThirdPartyIngredient>(`/api/Ingredient/UpdateMy3rdPartyIngredient/${orgId}`, ingredient);
  }
  public patchMy3rdPartyIngredient(orgId: string, refThirdPartyIngredientId: string, patch: PatchOperation[]): Observable<ThirdPartyIngredient> {
    return this.http.patch<ThirdPartyIngredient>(`/api/Ingredient/UpdateMyThirdPartyIngredient/${orgId}/${refThirdPartyIngredientId}`, patch);
  }
  public addPurposeToDisclosure(orgId: string, dislosureId: string, purpose: any): Observable<Purpose> {
    return this.http.post<Purpose>(`/api/Ingredient/AddMyDisclosurePurpose/${orgId}/${dislosureId}`, purpose);
  }
  public addIngredientToPurpose(orgId: string, purposeId: string, ingredient: any): Observable<Ingredient> {
    return this.http.post<Ingredient>(`/api/Ingredient/AddMyDisclosureIngredient/${orgId}/${purposeId}`, ingredient);
  }
  public putMyPurpose(orgId: string, disclosureId: string, purposeId: string, purpose: Purpose): Observable<Purpose> {
    return this.http.put<Purpose>(`/api/Ingredient/UpdateMyDisclosurePurpose/${orgId}/${disclosureId}/${purposeId}`, purpose);
  }
  public putMyIngredient(orgId: string, ingredientId: string, purposeId: string, ingredient: Ingredient): Observable<Ingredient> {
    return this.http.put<Ingredient>(`/api/Ingredient/UpdateMyDisclosureIngredient/${orgId}/${ingredientId}/${purposeId}`, ingredient);
  }
  public patchMyPurpose(orgId: string, disclosureId: string, purposeId: string, patch: PatchOperation[]): Observable<Purpose> {
    return this.http.patch<Purpose>(`/api/Ingredient/UpdateMyDisclosurePurpose/${orgId}/${disclosureId}/${purposeId}`, patch);
  }
  public patchMyIngredient(orgId: string, ingredientId: string, purposeId: string, patch: PatchOperation[]): Observable<Ingredient> {
    return this.http.patch<Ingredient>(`/api/Ingredient/UpdateMyDisclosureIngredient/${orgId}/${ingredientId}/${purposeId}`, patch);
  }
  public deleteThirdPartyIngredient(ingredientId:string):Observable<any>{
    return this.http.delete(`/api/Ingredient/DeleteThirdPartyIngredient/${ingredientId}`);
  }
  public deleteThirdPartyPurpose(ingredientId:string):Observable<any>{
    return this.http.delete(`/api/Ingredient/DeleteThirdPartyPurpose/${ingredientId}`);
  }
  public readonly validCASAlternatives = ["confidential","trade secret","proprietary","cas not assigned"];
  public isFracCasValid(cn: string): boolean {
    if (isNotNullOrEmpty(cn)) {
      if (this.validCASAlternatives.filter(x => x.toLowerCase() == cn.toLowerCase()).length > 0) return true;
      return this.isCasValid(cn);
    }
    else return false;
  }

  //https://metamolecular.com/blog/2013/10/11/validating-cas-numbers-in-javascript/
  fullCasRegex = /([0-9]{2,7})-([0-9]{2})-[0-9]/;
  isCasValid(cas: string) {
    if (!cas.match(this.fullCasRegex)) {
      return false;
    }

    return this.getCheckDigit(cas).toString() === cas.slice(-1);
  }
  getCheckDigit(cas: string) {
    var match = cas.match(/([0-9]{2,7})-([0-9]{2})-[0-9]/);
    var digits = (match[1] + match[2]).split('').reverse();
    var sum = 0;

    for (var i = 0; i < digits.length; i++) {
      sum += (i + 1) * parseInt(digits[i]);
    }

    return sum % 10;
  }
}
