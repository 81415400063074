import { CASNumberShort } from "../../../../common/models/disclosures/cas-number-short.model";
import { CASNumber } from "../../../../common/models/disclosures/cas-number.model";

export class CASNumberWithNgSelectDisplayField extends CASNumberShort {
  numberAndDescription: string;
  isATemporaryEntry: boolean;

  constructor(casNumber: CASNumberShort) {
    super();

    if (!casNumber || !casNumber.ingredientName) {
      this.isATemporaryEntry = true;
    }
    else {
      this.isATemporaryEntry = false;
    }

    this.casNumber = casNumber.casNumber;
    this.commonName = casNumber.commonName;
    this.isCommonName = casNumber.isCommonName;
    this.ingredientName = casNumber.ingredientName;
    if (casNumber.ingredientName !== null && casNumber.ingredientName !== "" && casNumber.ingredientName !== undefined) {
      this.numberAndDescription = casNumber.casNumber + " : " + casNumber.ingredientName;
    }
    else {
      this.numberAndDescription = casNumber.casNumber;
    }
  }
}
