import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'open-delete-cell',
  templateUrl: './open-delete-cell.component.html',
  styleUrls: ['./open-delete-cell.component.scss']
})
export class OpenDeleteCellComponent implements OnInit, ICellRendererAngularComp {
    public data: any;
  public api: any;
  public rowIndex: number = -1;
  public readOnly: boolean = true;
  constructor(private router: Router) {
    console.log('opendeletecell constructed');
  }

  agInit(params: ICellRendererParams<any, any>): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    console.log('ag init', this.data)
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  ngOnInit(): void {
  }

  open(): void {
    this.router.navigate(['/disclosure/edit-disclosure/', this.data.disclosureId]);
  }

  delete(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "delete", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }
}
