
export class OrgRole {
    //[Required]
    //MaxLength(16)
    public orgRoleId: string | undefined;
    //MaxLength(50)
    public value: string | undefined;
    //MaxLength(100)
    public description: string | undefined;
    //MaxLength(100)
    public createdBy: string | undefined;
    //MaxLength(8)
    public createdDate: string | undefined;
    //MaxLength(100)
    public lastModifiedBy: string | undefined;
    //MaxLength(8)
    public lastModifiedDate: string | undefined;
    public static USERROLEID = "E11949AD-DA31-459B-94BE-6AEFFE4495AE";
    public static SUPERVISORROLEID = "0BB027BC-34BA-4ACF-B235-94FFAF83CCA2";
    public static DATASUBMITTERID = "38ADD49F-B1DE-4CBC-974A-0835742A304A";
}
