import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChangeEvent } from 'ag-grid-community/dist/lib/widgets/agCheckbox';

@Component({
  selector: 'input-with-character-filter',
  templateUrl: './input-with-character-filter.component.html',
  styleUrls: ['./input-with-character-filter.component.scss']
})
export class InputWithCharacterFilterComponent implements OnInit {
  errors: any[] = [];
  message: string = null;

  @Input() public formControlName: string = "";
  @Input() public formGroup: FormGroup;
  @Input() public labelText: string = "";
  @Input() public regexOfCharsToExclude: RegExp = /[^\w\d\s'&"\-,.=+_!@#$%*():;\/\\]/gi;

  @ViewChild('input', { static: false }) public htmlInput: any;

  constructor() { }

  ngOnInit(): void {    
  }

  onChangeEvent(event: any) {
    let control = this.formGroup.get(this.formControlName);
    let data: string = control.value as string
    if (this.regexOfCharsToExclude.test(data)) {
      data = data.replace(this.regexOfCharsToExclude, "");
      control.setValue(data);
    }
  }
}
