import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Report } from '../../admin/models/reports/report.model';
import { SimpleReport } from '../../admin/models/reports/simple-report.model';
import { LoggingService } from '../../common/services/logging.service';
import { DownloadFilter } from '../models/disclosures/download-filter.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private http: HttpClient, private loggingService: LoggingService) {

  }
  public getReportOptions(reportType: string) {
    return this.http.get<Report>(`/api/report/GetReportOptionsByReportType/${reportType}`);
  }
  public getAvailableReportsBasic(orgId: string) {
    return this.http.get<SimpleReport[]>(`/api/report/GetSimpleReportOptions/${orgId}`);
  }
  public getAvailableAdminReportsBasic() {
    return this.http.get<SimpleReport[]>(`/api/report/GetAdminReportOptions`);
  }
  public getAvailableReports(orgId: string) {
    return this.http.get<any[]>(`/api/report/GetReportOptions/${orgId}`);
  }
  public getReportData(reportType: string, df: DownloadFilter) {
    return this.http.post(`/api/report/GetReportData/${reportType}`, df);
  }
}
