import { OrgRoleType } from "./users/org-role-type.enum"

export interface PermittedRoles{
    orgType: OrgType,
    roles: OrgRoleType[],
    isAdmin?: boolean // for the admin role
}

export type OrgType = 'RegisteredAgent' | 'Service' | 'Operator'

/**
 * Common roles for user access permissions
 */
export const CommonRoles = {
    Admin: {
        orgType: 'Operator' as OrgType,
        roles: [] as OrgRoleType[],
        isAdmin: true
    },
    RegisteredAgtSupervisor: {
        orgType: 'RegisteredAgent' as OrgType,
        roles: [OrgRoleType.Supervisor]
    },
    RegisteredAgtUser: {
        orgType: 'RegisteredAgent' as OrgType,
        roles: [OrgRoleType.DataSubmitter]
    },
    ServiceSupervisor: {
        orgType: 'Service' as OrgType,
        roles: [OrgRoleType.Supervisor]
    },
    ServiceUser: {
        orgType: 'Service' as OrgType,
        roles: [OrgRoleType.DataSubmitter]
    },
    OperatorSubmitter: {
        orgType: 'Operator' as OrgType,
        roles: [OrgRoleType.DataSubmitter]
    },
    OperatorSupervisor: {
        orgType: 'Operator' as OrgType,
        roles: [OrgRoleType.Supervisor]
    },
    AllSupervisors: [ 
        {
            orgType: 'Operator' as OrgType,
            roles: [OrgRoleType.Supervisor]
        },
        {
            orgType: 'Service' as OrgType,
            roles: [OrgRoleType.Supervisor]
        },
        {
            orgType: 'RegisteredAgent' as OrgType,
            roles: [OrgRoleType.Supervisor]
        }
    ],
    AllSubmitters: [
        {
            orgType: 'RegisteredAgent' as OrgType,
            roles: [OrgRoleType.DataSubmitter]
        },
        {
            orgType: 'Service' as OrgType,
            roles: [OrgRoleType.DataSubmitter]
        },
        {
            orgType: 'Operator' as OrgType,
            roles: [OrgRoleType.DataSubmitter]
        },

    ]
}