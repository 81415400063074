import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FFUser } from '../common/models/users/ff-user.model';
import { UserService } from '../common/services/user.service';
import { Organization } from '../common/models/organizations/organization.model';
import { AuthService } from '../common/services/auth.service';

@Component({
  selector: 'app-fetch-data',
  templateUrl: './fetch-data.component.html'
})
export class FetchDataComponent {
  public forecasts: WeatherForecast[] = [];
  public userInfo: FFUser | null = null;
  public claims:any[] = [];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private authService: AuthService, private userService: UserService) {
    http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
      this.forecasts = result;
    }, error => console.error(error));
    this.authService.getClaims().subscribe(res => { this.claims = res; }, err => { });
    this.authService.getUserInfo().then(res => this.userInfo = res);
    this.userService.getOrgsShort().then(res => {});
    this.userService.getUsersShort().then(res => {});
    this.userService.getMyCompany("0898C2D7-5A42-450D-B7F6-A11832E65F57").subscribe(res => { });
  }
}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}
