import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ColumnResizedEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { OnLeavingEditorParams } from '../../../models/base-cell-editor/on-leaving-editor-params.model';
import { ISettableCheckBoxEditorParams } from '../../../models/settable-checkbox-editor-params.model';

@Component({
  selector: 'app-settable-checkbox-editor',
  templateUrl: './settable-checkbox-editor.component.html',
  styleUrls: ['./settable-checkbox-editor.component.scss']
})

export class SettableCheckboxEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  public cellWidth: string = "";
  public cellHeight: string = "";
  public isEnabled: boolean = true;
  public isChecked: boolean = false;
  public value: string = "";
  public val: boolean = false;
  public originalValue: string = "";

  private params: ISettableCheckBoxEditorParams;

  @ViewChild('checkBoxInput') input: ElementRef;

  constructor() {
  }

  public agInit(params: ISettableCheckBoxEditorParams): void {
    this.params = params;
    

    (this.params.api as GridApi).addEventListener("columnResized",
      (event: ColumnResizedEvent) => {
        if ((event.column && event.column.getId() == this.params.colDef.colId) || event.column === null || this.params.colDef.colId === undefined)
          this.cellWidth = (params.column.getActualWidth()) - 2 + 'px';
      }
    );

    this.setInitialState(params);
    this.cellWidth = (params.column.getActualWidth()) - 2 + 'px';
    this.cellHeight = (params.node.rowHeight) + 'px';
  }

  public ngAfterViewInit() {
  }

  getValue(): any {
    if (this.value != null && this.value != undefined)
      return this.value;
  }

  setInitialState(params: ISettableCheckBoxEditorParams) {
    this.value = params.currentValue(params);
    if (params.value != undefined && params.value != null) {
      this.originalValue = JSON.parse(JSON.stringify(params.value));
    }

    this.isChecked = params.isChecked(this.params);

    if (params.isEnabled != null && params.isEnabled != undefined) {
      if (params.isEnabled instanceof Function)
        this.isEnabled = params.isEnabled(params);
      else
        this.isEnabled = params.isEnabled;
    }
    else
      this.isEnabled = true;
  }

  public ngOnDestroy() {
    this.focusOut();
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  focusOut(): void {
    //On focus out, check if the value has changed, if so patch it
    if (this.originalValue != this.value && this.params.onLeavingEditor && !this.params.node.isRowPinned()) {
      this.params.onLeavingEditor({
        data: this.params.data,
        patchInfo: [{
          fieldToPatch: "/" + this.params.colDef.field,
          valueToPatch: this.value
        }]
      } as OnLeavingEditorParams);

      this.params.node.data[this.params.colDef.field] = JSON.parse(JSON.stringify(this.value));
      this.originalValue = JSON.parse(JSON.stringify(this.value));
    }
  };

  focusIn(): void {
    if (this.input.nativeElement) {
      let input = this.input.nativeElement as HTMLInputElement;
      //If the input is disabled, just move on to next cell
      if (input.disabled) {
        input.disabled = false;
        input.focus();
        input.disabled = true;
      }
      else {
        this.input.nativeElement.focus();
      }
    }
  };

  setValue(newValue: string) {
    this.value = newValue;
  }

  public selectionChanged(event:any) {
    this.value = this.params.valueOnSelect(this.params);
    this.params.data[this.params.colDef.field] = this.value;
    this.isChecked = this.params.isChecked(this.params);

    if (this.params.isEnabled instanceof Function) {
      this.isEnabled = this.params.isEnabled(this.params);
    }
  }
}
