import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnResizedEvent, GridApi } from 'ag-grid-community';
import { finalize, Subscription } from 'rxjs';
import { DisclosureValidationStatement } from '../../../../common/models/disclosures/disclosure-validation-statement.model';
import { IValidatedFieldCellRendererParams } from '../../../models/validated-field-cell-renderer-params.model';

@Component({
  selector: 'validated-field-cell-renderer',
  templateUrl: './validated-field-cell-renderer.component.html',
  styleUrls: ['./validated-field-cell-renderer.component.scss']
})
export class ValidatedFieldCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public cellWidth: string = "";
  public cellHeight: string = "";
  public isValid: boolean = true;
  public params: IValidatedFieldCellRendererParams;
  public fieldValue: any;
  private amountToSubstractForWidth: number = 25;
  private amountToSubstractForHeight: number = 8;
  private subscription: Subscription = new Subscription();
  public makeFocusable: boolean = false;
  constructor() {
  }

  refresh(params: IValidatedFieldCellRendererParams): boolean {
    return false;
  }

  getTabIndex(): string {
    return this.params.rowIndex.toString();
  }

  public agInit(params: IValidatedFieldCellRendererParams): void {
    this.params = params;
    if (this.params.makeFocusable) {
      this.makeFocusable = this.params.makeFocusable;
    }

    //Need to substract more when this is the inner renderer of a group cell
    if (params.colDef.cellRenderer == "agGroupCellRenderer") {
      this.amountToSubstractForWidth += 47;
    }

    if (params.validityChangedEvent && params.isValid instanceof Function) {
      let validityChanged$ = params.validityChangedEvent.pipe(finalize(() => {
        this.subscription.add(validityChanged$);
      })).subscribe((result: Array<DisclosureValidationStatement>) => {
        this.isValid = params.isValid(result, this.params.fieldName, this.params.idType, this.params.node.id);
      }, error => {
        console.log('Error listening for updated validation info ' + error);
      });
    }

    if (params.initialDisclosureValidationStatements && params.initialDisclosureValidationStatements.length > 0) {
      this.isValid = params.isValid(params.initialDisclosureValidationStatements, this.params.fieldName, this.params.idType, this.params.node.id);
    }

    (this.params.api as GridApi).addEventListener("columnResized",
      (event: ColumnResizedEvent) => {
        if ((event.column && event.column.getId() == this.params.colDef.colId) || event.column === null || this.params.colDef.colId === undefined) {
          this.cellWidth = (params.column.getActualWidth()) - this.amountToSubstractForWidth + 'px';
          this.cellHeight = (params.node.rowHeight) - this.amountToSubstractForHeight + 'px';
        }
      }
    );

    this.fieldValue = this.isNotNullOrUndefined(params.valueFormatted) ? params.valueFormatted : this.isNotNullOrUndefined(params.value)? params.value : "";

    this.cellWidth = (params.column.getActualWidth()) - this.amountToSubstractForWidth + 'px';
    this.cellHeight = (params.node.rowHeight) - this.amountToSubstractForHeight + 'px';   
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  isNotNullOrUndefined(value: any): boolean {
    return value !== null && value !== undefined;
  }

  public setValidityStatus(isValid: boolean) {
    this.isValid = isValid;
  }

  public ngAfterViewInit() {
  }
}
