import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AuthorizeInterceptor } from './api-authorization/authorize.interceptor';


import { AuthService } from 'src/app/common/services/auth.service';
import { LoggingService } from 'src/app/common/services/logging.service';
import { UserService } from 'src/app/common/services/user.service';
import { DropdownService } from 'src/app/common/services/dropdown.service';
import { FracFocusCommonModule} from './common/common.module';

import { AppRoutingModule } from './app-routing.module';
import { MainService } from 'src/app/common/ui/services/main.service';
import { MainNavService } from 'src/app/common/ui/services/main-nav.service';

import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AboutComponent } from './about/about.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ReCaptchaService } from './common/services/recaptcha.service';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FetchDataComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    FracFocusCommonModule,
    IconSpriteModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeInterceptor,
      multi: true
    },
    AuthService,
    LoggingService,
    UserService,
    DropdownService,
    MainService,
    MainNavService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    ReCaptchaService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
