import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-history-cell-renderer',
  templateUrl: './history-cell-renderer.component.html',
  styleUrls: ['./history-cell-renderer.component.scss']
})
export class HistoryCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  public data: any;
  public api: any;
  public rowIndex: number = -1;
  public historyContainerHeight: string = "";
  history: any[];
  public linkTitle: string = "Close history"

  constructor() { }

  agInit(params: any): void {
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.history = params.history;

    //default height: History / Close History row
    const defaultHeight = 35;
    //27: single history row with margin bottom
    const rowHeight = 27;

    let historyLength = this.history.length;

    if (historyLength === 0) {
      //For "No history data found" row
      historyLength = 1;
    }
    
    let calculatedHeight = defaultHeight + (historyLength * rowHeight);
    this.historyContainerHeight = calculatedHeight + "px";
    if (params.parent) {
      const parent = params.parent;
      switch (parent) {
        case "manage-org-users":
          this.linkTitle = "Close user's account history";
          break;
      
        default:
          break;
      }
      
    }

  }

  refresh(params: any): boolean {
    return false;
  }

  public formatDate(date: string) {
    return new Date(date).toLocaleDateString();
  }

  public closeHistory(): void {
    if (this.api.onActionTrigger) {
        this.api.onActionTrigger({ row: this.rowIndex, action: "hideHistory", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

}
