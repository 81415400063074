import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-send-activation-email-cell',
  templateUrl: './send-activation-email-cell.component.html',
  styleUrls: ['./send-activation-email-cell.component.scss']
})
export class SendActivationEmailCellComponent implements ICellRendererAngularComp {
  public data: any;
  public val: boolean = false;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public columnName: any;
  private cellName: string = '';
  public isPending: boolean = false;
  public disabled: boolean = true;

  constructor() { }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.val = params.value;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.cellName = params?.colDef?.cellRenderer?.toString() || '';
    this.columnName = (params.column as any).colId;

    this.isPending = this.data.status === "Pending";
    this.disabled = this.data.disabled === undefined ? true : this.data.disabled;
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  public sendActivationEmail(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "sendActivationEmail", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

}
