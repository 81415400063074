<div [popper]="tooltipcontent"
     [popperTrigger]="popperTrigger"
     [popperPlacement]="popperPlacement"
     [popperApplyClass]="'popperSpecialStyle'"
     [popperPositionFixed]=true>
  <ui-icon-button [title]="title" [color]="iconColor | iconButtonColorEnumPipe" [iconName]="'icon-info'" [heightInPx]="heightInPx" [widthInPx]="widthInPx" [screenReaderText]="screenReaderText" ></ui-icon-button>
</div>
<popper-content #tooltipcontent>
    <span *ngIf="!isHtmlContent">{{screenReaderText}}</span>
    <div *ngIf="isHtmlContent" [innerHTML]="screenReaderText"></div>
</popper-content>

