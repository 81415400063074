<h1 id="tableLabel">Weather forecast</h1>

<p>This component demonstrates fetching data from the server.</p>
<div *ngIf="userInfo">
  <ul>
    <li *ngFor="let m of userInfo.userMappings"><span *ngIf="m.organization && m.orgRole">{{m.organization.organizationName}} - {{m.orgRole.value}}</span></li>
  </ul>

</div>
<p *ngIf="!claims"><em>Loading...</em></p>

<table class='table table-striped' aria-labelledby="tableLabel" *ngIf="claims">
  <thead>
    <tr>
      <th>Type</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let c of claims">
      <td>{{ c.type }}</td>
      <td>{{ c.value }}</td>
    </tr>
  </tbody>
</table>
