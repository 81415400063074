import { Directive, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MainNavService } from '../../services/main-nav.service';

@Directive({
	selector: '[appNavSubMenuItemDirective]'
})
export class NavSubMenuItemDirectiveDirective {

	//*************************************************/
	//EVENTS THAT CLOSE THE SUB MENU
	//tab
	@HostListener('keydown.tab', ['$event'])
	onTab(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSetFocusOutsideNavMenu(false);
		this._mainNavService.broadcastCloseOpenSubMenu(true);	
	}
	//shift tab
	@HostListener('keydown.shift.tab', ['$event'])
	onShiftTab(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSetFocusOutsideNavMenu(true);
		this._mainNavService.broadcastCloseOpenSubMenu(true);	
	}
	//**************************************************************************/
	//arrow right
	@HostListener('keydown.arrowright', ['$event'])
	onRightArrow(e: KeyboardEvent) {
		e.preventDefault();
		//this._mainNavService.broadcastCloseOpenSubMenu(true);	
		this._mainNavService.broadcastMenuKey("s-ar");
		this._mainNavService.broadcastSetSelectedHeader("firstSubItem");
	}
	//arrow left
	@HostListener('keydown.arrowleft', ['$event'])
	onLeftArrow(e: KeyboardEvent) {
		e.preventDefault();
		//this._mainNavService.broadcastCloseOpenSubMenu(true);	
		this._mainNavService.broadcastMenuKey("s-al");
		this._mainNavService.broadcastSetSelectedHeader("firstSubItem");
	}
	//**************************************************************************/
	//esc
	@HostListener('keydown.esc', ['$event'])
	onEsc(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSelectHeaderBySubItemEsc(true);
	}
	//************************************************/


	//*************************************************/
	//EVENTS THAT MOVE BETWEEN SUB MENU ITEMS
	//arrow down
	@HostListener('keydown.arrowdown', ['$event'])
	onDownArrow(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSetSelectedHeader("nextSubItem");
	}
	//arrow up
	@HostListener('keydown.arrowup', ['$event'])
	onUpArrow(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSetSelectedHeader("previousSubItem");
	}
	//*************************************************/

	//***************************************************/
	//EVENT THAT TRIGGERS GO TO URL
	//space bar
	@HostListener('keydown.space', ['$event'])
	onEnter(e: KeyboardEvent) {
		e.preventDefault();
		//get anchor element
    const target = e.target as HTMLAnchorElement;
    if (target && target.pathname) {
      //navigate to the href
      this.router.navigate([target.pathname]);
		}
	}
	//***************************************************/

	constructor(
    private _mainNavService: MainNavService,
    private router: Router,
		public elRef?: ElementRef) { }

}
