import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Status } from "../models/lookup/status.model";
import { ClientConfig } from '../models/client-config.model';
import { OrganizationDefault } from '../models/organizations/organization-defaults.model';
import { AnalyticsOptions } from '../models/analytics-options.model';
import { LoggingService } from './logging.service';
import { BasicNotification } from '../../admin/models/notifications/basic-notification.model';
import { StateShort } from '../models/lookup/state-short.model';
import { WellType } from '../models/disclosures/well-type.model';
import { County } from '../models/disclosures/county.model';

@Injectable()
export class DropdownService {
  private wellTypes: any[] = [];
  private waterSourceTypes: any[] = [];
  private states: any[] = [];
  private counties: any[] = [];
  AllStatus: Array<Status> = [];
  private clientConfig: ClientConfig = null;
  private organizationDefaults: OrganizationDefault[] = null;
  private _analyticsOptions: AnalyticsOptions = null;
  private globalDefaults: any[] | null = null;
  public currentBasicNotifications: BasicNotification[] | null = null;
  public setBasicNotifications: BehaviorSubject<BasicNotification[]> = new BehaviorSubject([]);
  constructor(private http: HttpClient, private loggingService: LoggingService) { }

  public getAnalyticsOptions(forceRefetch: boolean = false): Promise<AnalyticsOptions> {

    return new Promise((resolve, reject) => {
      if (this._analyticsOptions && !forceRefetch) {
        resolve(this._analyticsOptions);
      } else {
        this.http.get<AnalyticsOptions>('/api/Dropdown/AnalyticsOptions').subscribe((response: AnalyticsOptions) => {
          this._analyticsOptions = response;
          resolve(this._analyticsOptions);
        }, (error: any) => {
          this.loggingService.error("Error getting Analytics Options", "Error getting Analytics Options: " + error);
          reject(error);
        });
      }
    });
  }

  public getStatus(): Promise<Array<Status>> {
    return new Promise((resolve, reject) => {
      if (this.AllStatus && this.AllStatus.length) {
        resolve(this.AllStatus);
      } else {
        this.http.get<Status>('/api/Dropdown/AllStatus').subscribe((response: any) => {
          this.AllStatus = response;
          resolve(this.AllStatus);
        }, (error: any) => {
          this.loggingService.error("Error getting status list", "Error getting status list: " + error);
          reject(error);
        });
      }
    });
  }

  public getCountiesForState(stAb: string): Observable<Array<County>> {
    return this.http.get<any>('/api/Dropdown/CountiesByState/' + stAb);
  }

  public getCounties(forceRefetch: boolean = false): Promise<Array<County>> {
    return new Promise((resolve, reject) => {
      if (this.counties && this.counties.length && !forceRefetch) {
        resolve(this.counties);
      } else {
        this.http.get<any>('/api/Dropdown/AllCounties').subscribe((response:Array<County>) => {
          this.counties = response;
          resolve(this.counties);
        }, (error: any) => {
          this.loggingService.error("Error getting county list", "Error getting county list: " + error);
          reject(error);
        });
      }
    });
  }
  public getStates(forceRefetch: boolean = false): Promise<Array<StateShort>> {
    return new Promise((resolve, reject) => {
      if (this.states && this.states.length && !forceRefetch) {
        resolve(this.states);
      } else {
        this.http.get<any>('/api/Dropdown/AllStates').subscribe((response: any) => {
          this.states = response;
          this.states?.sort(function(a, b) {
            var stateA = a.stateName.toUpperCase();
            var stateB = b.stateName.toUpperCase();
            if (stateA < stateB) {
              return -1;
            }
            if (stateA > stateB) {
              return 1;
            }
            return 0;
          });
          resolve(this.states);
        }, (error: any) => {
          this.loggingService.error("Error getting state list", "Error getting state list: " + error);
          reject(error);
        });
      }
    });
  }
  public getWellTypes(forceRefetch: boolean = false): Promise<Array<WellType>> {
    return new Promise((resolve, reject) => {
      if (this.wellTypes && this.wellTypes.length && !forceRefetch) {
        resolve(this.wellTypes);
      } else {
        this.http.get<any>('/api/Dropdown/AllWellTypes').subscribe((response: any) => {
          this.wellTypes = response;
          resolve(this.wellTypes);
        }, (error: any) => {
          this.loggingService.error("Error getting Well Types list", "Error getting WellTypes list: " + error);
          reject(error);
        });
      }
    });
  }
  public getWaterSourceTypes(forceRefetch: boolean = false): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      if (this.waterSourceTypes && this.waterSourceTypes.length && !forceRefetch) {
        resolve(this.waterSourceTypes);
      } else {
        this.http.get<any>('/api/Dropdown/AllWaterSourceTypes').subscribe((response: any) => {
          this.waterSourceTypes = response;
          resolve(this.waterSourceTypes);
        }, (error: any) => {
          this.loggingService.error("Error getting WaterSourceTypes list", "Error getting WaterSourceTypes list: " + error);
          reject(error);
        });
      }
    });
  }
  public getClientConfig(forceRefetch: boolean = false): Promise<ClientConfig> {
    return new Promise((resolve, reject) => {
      if (this.clientConfig && !forceRefetch) {
        resolve(this.clientConfig);
      } else {
        this.http.get<any>('/api/Dropdown/ClientConfig').subscribe((response: ClientConfig) => {
          this.clientConfig = response;
          resolve(this.clientConfig);
        }, (error: any) => {
          this.loggingService.error("Error getting Client Configuration", "Error getting ClientConfig: " + error);
          reject(error);
        });
      }
    });
  }

  public getOrganizationDefaults(organizationId: string, forceRefetch: boolean = false): Promise<OrganizationDefault[]> {
    return new Promise((resolve, reject) => {
      if (this.organizationDefaults && !forceRefetch) {
        resolve(this.organizationDefaults);
      } else {
        this.http.get<any>(`/api/Dropdown/OrganizationDefaults/${organizationId}`).subscribe((response: OrganizationDefault[]) => {
          this.organizationDefaults = response;
          resolve(this.organizationDefaults);
        }, (error: any) => {
          this.loggingService.error("Error getting organization defaults", "Error getting OrganizationDefaults: " + error);
          reject(error);
        });
      }
    });
  }
  public getCountyAtLocation(lon:number, lat:number) {
    return this.http.get<any>(`/api/Dropdown/CountyAtLocation/${lon}/${lat}`);
  }
  public getCountyByAPINum(apiNum: string) {
    return this.http.get<any>(`/api/Dropdown/CountyByAPINum/${apiNum}`);
  }
  public getCalculatedPoint(lon: number, lat: number, proj:string) {
    return this.http.get<any>(`/api/Dropdown/ProjectPoint/${proj}/${lon}/${lat}`);
  }

  public getGlobalDefaults(forceRefetch: boolean = false): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.globalDefaults && !forceRefetch) {
        resolve(this.globalDefaults);
      } else {
        this.http.get<any[]>('/api/Dropdown/GlobalDefaults').subscribe((response: any) => {
          this.globalDefaults = response;
          resolve(this.globalDefaults);
        }, (error: any) => {
          this.loggingService.error("Error getting Global Defaults", "Error getting Global Defaults: " + error);
          reject(error);
        });
      }
    });
  }

  public getCurrentBasicNotifications(forceRefetch: boolean = false): Promise<BasicNotification[]> {
    return new Promise((resolve, reject) => {
      if (this.currentBasicNotifications && !forceRefetch) {
        resolve(this.currentBasicNotifications);
      } else {
        this.http.get<BasicNotification[]>('/api/Dropdown/CurrentBasicNotifications').subscribe((response: BasicNotification[]) => {
          this.currentBasicNotifications = response;
          this.setBasicNotifications.next(response);
          resolve(this.currentBasicNotifications);
        }, (error: any) => {
          this.loggingService.error("Error getting Current Basic Notifications", "Error getting Current Basic Notifications: " + error);
          reject(error);
        });
      }
    });
  }
}
