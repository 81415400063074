<div class="button-container">
  <button *ngIf="isPendingApproval" (click)="approve()" uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'SMALL' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe">
    Approve
  </button>
  <button *ngIf="!isPendingApproval && isEmailConfirmed && !isActivated && !isDenied" (click)="activate()" uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'SMALL' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe">
    Activate
  </button>
  <button *ngIf="!isPendingApproval && isEmailConfirmed && isActivated && !isDenied" (click)="deactivate()" uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'SMALL' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe">
    Deactivate
  </button>
  <button *ngIf="!isDenied && (isPendingApproval || !isEmailConfirmed)" (click)="deny()" uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'SMALL' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe">
    Deny
  </button>
  <button *ngIf="isPendingApproval || !isEmailConfirmed" (click)="delete()" uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'SMALL' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe">
    Delete
  </button>
  <button *ngIf="!isEmailConfirmed && !emailSent" (click)="resendEmail()" uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'SMALL' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe">
    Resend Email
  </button>
</div>
