import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IRadioButtonCellEditorParams } from '../../../models/radio-button-cell-editor-params.model';

@Component({
  selector: 'radio-button-cell-editor',
  templateUrl: './radio-button-cell-editor.component.html',
  styleUrls: ['./radio-button-cell-editor.component.scss']
})
export class RadioButtonCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  public cellWidth: string = "";
  public cellHeight: string = "";
  public value: string = "";
  public name: string = "";
  public id: string = "";
  public isSelected: boolean = false;
  public isEnabled: boolean = true;
  public allowRadioButtonDeselection: boolean = false;

  private params: IRadioButtonCellEditorParams;

  @ViewChild('checkableRadioButton') input: ElementRef;

  constructor() {
  }

  public agInit(params: IRadioButtonCellEditorParams): void {
    this.params = params;
    this.allowRadioButtonDeselection = params.allowRadioButtonDeselection;
    this.name = params.name;
    this.id = params.id(this.params);
    this.isSelected = params.isSelected(this.params);
    this.isEnabled = params.isEnabled(this.params);
    this.setInitialState(params);
    this.cellWidth = (params.column.getActualWidth()) + 'px';
    this.cellHeight = (params.node.rowHeight) + 'px';
  }

  public ngAfterViewInit() {
  }

  getValue(): any {
    this.params.onChange(this.params);
    return this.value;
  }

  setInitialState(params: IRadioButtonCellEditorParams) {
    this.value = params.currentValue(params);
  }

  setValue(newValue: string) {
    this.value = newValue;
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  focusOut(): void {
  };

  focusIn(): void {
    if (this.input.nativeElement) {
      let input = this.input.nativeElement as HTMLInputElement;
      this.input.nativeElement.focus();
    }
  };

  public selectionChanged(changeEvent: Event) {
    this.value = this.params.valueOnSelect != undefined && this.params.valueOnSelect != null ? this.params.valueOnSelect(this.params) : true;
    this.params.data[this.params.colDef.field] = this.value;
    this.isSelected = this.params.isSelected(this.params);
    this.isEnabled = this.params.isEnabled(this.params);
  }

  public uncheckSelectedRadioButton(changeEvent: Event) {
    if (this.allowRadioButtonDeselection) {
      this.value = this.params.valueOnDeSelect != undefined && this.params.valueOnDeSelect != null ? this.params.valueOnDeSelect(this.params) : false;
      this.params.data[this.params.colDef.field] = this.value;
      this.isSelected = this.params.isSelected(this.params);
      this.isEnabled = this.params.isEnabled(this.params);
    }
  }
}
