import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { createPopper } from '@popperjs/core';
import { NgxPopperjsOptions, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-popper-tool-tip',
  templateUrl: './popper-tool-tip.component.html',
  styleUrls: ['./popper-tool-tip.component.scss']
})
export class PopperToolTipComponent implements OnInit {
  @ViewChild('tooltipElement', { static: true }) tooltipElement!: ElementRef;
  title: string = "";
  @Input() iconColor: string="BLUE";
  @Input() heightInPx: string = "16px";
  @Input() widthInPx: string = "16px";
  @Input() screenReaderText: string = "";
  @Input() popperPlacementParam: NgxPopperjsPlacements | Function = NgxPopperjsPlacements.AUTO;
  @Input() isHtmlContent:boolean = false;
  popperPlacement: NgxPopperjsPlacements;
  popperTrigger: NgxPopperjsTriggers = this.deviceService.isDesktop() ? NgxPopperjsTriggers.hover : NgxPopperjsTriggers.click;

  constructor(private ngZone: NgZone, private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {

    this.popperPlacement = this.popperPlacementParam instanceof Function ? this.popperPlacementParam() : this.popperPlacementParam;
  }

}
