<section name="user info" class="user-info" *ngIf="verifiedUser">
  <div class="user">{{userInfo.firstName}} {{userInfo.lastName}}, <span *ngIf="currentOrg">{{(currentOrg.organizationName.length > 20)? (currentOrg.organizationName | slice:0:20)+'..':(currentOrg.organizationName)}}</span></div>
  <div class="title" *ngIf="currentOrg"><span>{{currentOrg.isOperator ? 'Operator' : currentOrg.isServiceComp ? 'Service Company' : currentOrg.isRegisteredAgent ? 'Registered Agent' : currentOrg.isGovAgency ? 'Gov. Agency' : ''}}</span> <span *ngIf="currentOrg.operatorNumber">| Num: {{currentOrg.operatorNumber ? currentOrg.operatorNumber : ''}}</span></div>
  <div class="account">
    <div class="tooltip-wrapper">
      <a href="/user/profile">User Account</a>
      <app-popper-tool-tip screenReaderText="Edit account profile">
      </app-popper-tool-tip>   
    </div>
    <a class="tooltip-wrapper" #beforeNavMenu (click)="onLogoutClick()">Logout</a> 
    <div class="tooltip-wrapper" *ngIf="userOrgs && userOrgs.length>1">
      <a routerLink="/switch-organization">Switch Organization</a>
      <app-popper-tool-tip screenReaderText="Switch between organizations">
      </app-popper-tool-tip>
    </div>
  </div>
</section>
<section name="user info" class="user-info" *ngIf="!verifiedUser">
  <div class="account">
    <a #beforeNavMenu href="/authentication/login">Login</a> or
    <a href="/authentication/register">Register</a>
  </div>
</section>
