import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-user-role-cell-editor',
  templateUrl: './user-role-cell-editor.component.html',
  styleUrls: ['./user-role-cell-editor.component.scss']
})
export class UserRoleCellEditorComponent implements ICellRendererAngularComp {
  //@HostListener('window:keydown', ['$event'])
  //keyEvent(event: KeyboardEvent) {
  //  if (event.key == "Tab" && this.supervisorInput && this.supervisorInput.nativeElement
  //    && this.supervisorIsFocused) {
  //    // Your row selection code
  //    this.dataSubmitterInput.nativeElement.focus();
  //    event.stopImmediatePropagation();
  //  }
  //};

  private cellName: string = '';
  public params: any;
  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public roles: string[] = [];
  public isDataSubmitter: boolean;
  public isSupervisor: boolean;
  private supervisorIsFocused: boolean = false;
  public isInactive: boolean = false;

  @ViewChild('supervisorCheckboxInput') supervisorInput: ElementRef;
  @ViewChild('dataSubmitterCheckboxInput') dataSubmitterInput: ElementRef;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    if (this.data.status === "Inactive") {
      this.isInactive = true;
    }
    if (!this.data.roles) return;
    this.roles = this.data.roles.split(',').filter((item: any, pos: any, self: any) => {
      return self.indexOf(item) == pos;
    });
    if (this.roles.find(x => x === "Data Submitter")) {
      this.isDataSubmitter = true;
    }
    if (this.roles.find(x => x === "Supervisor")) {
      this.isSupervisor = true;
    }
  }

  eventCheck(event: any) {
    if (event.target.checked) {
      this.roles.push(event.target.name);
    } else {
      this.roles = this.roles.filter(elem => elem !== event.target.name);
    }
    this.data.rolesUpdated = this.roles.join(",");
  }

  refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  focusOut(): void {
  };

  focusIn(): void {
    if (this.supervisorInput.nativeElement) {
      this.supervisorInput.nativeElement.focus();
      this.supervisorIsFocused = true;
    }
  };

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key == "Tab") {
      //this.params.api.setTabToNextCell((params: TabToNextCellParams<any>) => {
      //  return {} as CellPosition;
      //});
    }
  }

}
