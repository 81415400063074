import { Component, HostBinding, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { IconButtonColor } from './icon-button-color.enum';

@Component({
  selector: 'ui-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input() color: IconButtonColor;
  @Input() iconName: string;
  @Input() heightInPx: string;
  @Input() widthInPx: string;
  @Input() screenReaderText: string;
  @Input() title: string;
  @Output() clicked = new EventEmitter();
  @Input() disabled: boolean;
  set state(dis: boolean) {
    this.disabled = dis;
  }
  private spriteURL = "assets/sprite/sprite.svg#";
  private iconPercentScale = .5;

  constructor() {
    this.color = IconButtonColor.BLACK;
    this.iconName = "icon-trash-can";
    this.heightInPx = "18px";
    this.widthInPx = "18px"
    this.screenReaderText = "Please add screenReaderText for accessibility";
    this.title = "Please add title";
    this.disabled = false;
  }

  ngOnInit(): void {
  }

  @HostBinding('class')
  get classes(): string {
    return `${this.color}`;
  }

  public onClick(): void {
    if (!this.disabled || this.disabled === null) {
      this.clicked.emit("click");
    }
  }
  public getIconSrc(): string {
    return this.spriteURL + this.iconName;
  }
  public getIconClass(): string {
    return this.iconName + this.color;
  }
  public getDisabled(): boolean | null{
    if (this.disabled) {
      return true;
    } else {
      return null;
    }
  }
}

