<h1 class="h3-size txt-trns-upper clr-tertiary-3 font-bold m-0 m-b--50 aln-center">Disclosures</h1>
<form [formGroup]="fg">
  <div>
    <label for="state">State: </label>
    <select formControlName="state" (change)="formUpdated($event); stateChanged();">
      <option value="null" [selected]="true" disabled>Select a State</option>
      <option *ngFor="let st of states" [value]="st.stateId">{{st.stateName}}</option>
    </select>
  </div>
  <div>
    <label for="county">County: </label>
    <select formControlName="countyId" (change)="formUpdated($event)">
      <option value="null" [selected]="true" disabled>Select a County</option>
      <option *ngFor="let c of countyOptions" [value]="c.countyId">{{c.countyName}}</option>
    </select>
  </div>
  <div>
    <label for="apiNo">API Well Number #: </label>
    <input type="text" name="apiNo" formControlName="apiNo" placeholder="Enter API number" />
  </div>
  <div>
    <label for="wellName">Well Name: </label>
    <input type="text" formControlName="wellName" placeholder="Enter Well Name" />
  </div>
  <div class="method-selector-radios-container">
    <label for="fileType">Search Date by:</label>
    <div>
      <label for="jobStartDateType">
        Job Start Date<input type="radio" formControlName="dateType"
                             (change)="formUpdated($event)" name="dateType" id="jobStartDateType" value="jobStartDate">
      </label>
    </div>
    <div>
      <label for="submitDateType">
        Submission Date<input type="radio" formControlName="dateType"
                              (change)="formUpdated($event)" name="dateType" id="submitDateType" value="submitDate">
      </label>
    </div>
  </div>
  <div class="method-selector-radios-container">
    <div><label for="dateRange">Date Range:</label></div>
    <div>
      <input type="date" name="startDateRange" formControlName="startDateRange" (change)="formUpdated($event)"
             class="input-box" />
    </div>
    <div>
      <input type="date" name="endDateRange" formControlName="endDateRange" (change)="formUpdated($event)"
             class="input-box" />
    </div>
  </div>
</form>

<div class="save-cancel-button m-t-1 dis-flex">
  <button uiLibButton class="entry-method-select" [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'MEDIUM' | buttonSizeEnumPipe" [shape]="'ROUNDED' | buttonShapeEnumPipe"
          [style]="'SOLID' | buttonStyleEnumPipe" (click)="startSearch()">
    Search
  </button>
  <button uiLibButton class="entry-method-select" [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'MEDIUM' | buttonSizeEnumPipe" [shape]="'ROUNDED' | buttonShapeEnumPipe"
          [style]="'SOLID' | buttonStyleEnumPipe" (click)="resetSearch()">
    Reset
  </button>
</div>
<div>
</div>
