import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToasterType, ToasterService } from 'ui-library-shared';

@Injectable()
export class LoggingService {

  private _toasterDuration = 5000;
  private _toasterAutoClose = true;

  constructor(private http: HttpClient, private _toasterService: ToasterService) {
  }

  public success(message: string, detailedString: string | null = null, autoClose: boolean = this._toasterAutoClose, duration: number = this._toasterDuration) {
    this.shownotification(message, ToasterType.SUCCESS, autoClose, duration);
    if (detailedString == null) detailedString = message;
    this.logInfo(detailedString);
  }

  public debug(message: string, detailedString: string | null = null, autoClose: boolean = this._toasterAutoClose, duration: number = this._toasterDuration) {
    this.shownotification(message, ToasterType.INFO, autoClose, duration);
    if (detailedString == null) detailedString = message;
    this.logDebug(detailedString);
  }
  public info(message: string, detailedString: string | null = null, autoClose: boolean = this._toasterAutoClose, duration: number = this._toasterDuration) {
    this.shownotification(message, ToasterType.INFO, autoClose, duration);
    if (detailedString == null) detailedString = message;
    this.logInfo(detailedString);
  }
  public warning(message: string, detailedString: string | null = null, autoClose: boolean = this._toasterAutoClose, duration: number = this._toasterDuration) {
    this.shownotification(message, ToasterType.WARNING, autoClose, duration);
    if (detailedString == null) detailedString = message;
    this.logWarning(detailedString);
  }
  public error(message: string, detailedString: string | null = null, autoClose: boolean = this._toasterAutoClose, duration: number = this._toasterDuration) {
    this.shownotification(message, ToasterType.ERROR, autoClose, duration);
    if (detailedString == null) detailedString = message;
    this.logError(detailedString);
  }
  public errorSilent(message: string, detailedString: string | null = null) {
    if (detailedString == null) detailedString = message;
    this.logError(detailedString);
  }

  logDebug(message: string) {
    this.postLog('debug', message);
  }
  logInfo(message: string) {
    this.postLog('info', message);
  }
  logWarning(message: string) {
    console.warn("Log message: " + message);
    this.postLog('warning', message);
  }
  logError(message: string) {
    console.error("Log message: " + message);
    this.postLog('error', message);
  }



  private postLog(level: string, message: string) {
    this.http.post<any>(`/api/Log/${level}`, { value: message }).subscribe(res => { }, err => {
      console.error("Error logging log ", err);
    });
  }

  private shownotification(text: string, type: ToasterType, autoClose: boolean, duration: number): void {
    switch (type) {
      case ToasterType.ERROR:
        this._toasterService.error(text, duration, autoClose);
        break;
      case ToasterType.INFO:
        this._toasterService.info(text, duration, autoClose);
        break;
      case ToasterType.SUCCESS:
        this._toasterService.success(text, duration, autoClose);
        break;
      case ToasterType.WARNING:
        this._toasterService.warning(text, duration, autoClose);
        break;
      default:
        console.log("missed case in main.service.ts > showToast, type: " + type);
    }
  }
}
