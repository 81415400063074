<header><ui-header></ui-header></header>
<nav [hidden]="isDashboardLayout"><ui-breadcrumbs></ui-breadcrumbs></nav>
<main #main>
  <router-outlet></router-outlet>
</main>
<footer><ui-footer></ui-footer></footer>
<ui-modal #userTimeoutModal *ngIf="displayTimeoutModal" [id]="'timeoutModal'" [paddedContent]="true" [isVisible]="displayTimeoutModal" [modalTitle]="'Idle Timeout Warning'" [hasCloseButton]="false" [modalSize]=" 'MEDIUM' | modalSizeEnumPipe">
  <div modalContent>
    <p class="aln-center">
      You have been idle for {{IDLE_INTERVAL_IN_SECONDS/60}} minutes.<br />
      You will automatically be logged out in {{secondsLeftToLogout}} seconds.
    </p>
  </div>
</ui-modal>
<ui-lib-loader #loader [loaderStyle]="loaderStyle" [loaderSize]="loaderSize"></ui-lib-loader>
<ui-toaster [position]="toasterPosition"></ui-toaster>
