import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  public readOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public rowIdex: Subject<number> = new Subject<number>();
  // public readOnlyPurpose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  // public rowIdexPurpose: Subject<number> = new Subject<number>();
  public readOnlyIngredient: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public rowIdexIngredient: Subject<number> = new Subject<number>();
  public selectedTable: Subject<string> = new Subject<string>();

  constructor() { }
}
