export function isNotNullOrEmpty(objectToCheck: string | number) {
  let isUndefined = objectToCheck === undefined;
  let isNull = objectToCheck === null;

  if (!isUndefined && !isNull)
    objectToCheck = objectToCheck.toString().trim();

  let isEmpty = objectToCheck === '';
  return !isUndefined && !isNull && !isEmpty;
}
