import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrgRole } from '../../models/organizations/org-role.model';
import { Organization } from '../../models/organizations/organization.model';
import { FFUser, getUserOrgs } from '../../models/users/ff-user.model';
import { AuthService } from '../../services/auth.service';
import { LoggingService } from '../../services/logging.service';
import { UserService } from '../../services/user.service';
import { MainService } from '../../ui/services/main.service';

@Component({
  selector: 'app-switch-organization',
  templateUrl: './switch-organization.component.html',
  styleUrls: ['./switch-organization.component.scss']
})
export class SwitchOrganizationComponent implements OnInit, OnDestroy {

  public userInfo: FFUser;
  public verifiedUser: FFUser = undefined;
  public userOrgs: Organization[];
  public currentOrg: Organization;
    orgId: string;

  constructor(private authService: AuthService, private userService: UserService,
    private loggingService: LoggingService, private mainService: MainService) { }
  ngOnInit(): void {

    this.authService.getUserInfo().then(result => {
      this.setUserInfo(result);
    });
  }

  ngOnDestroy(): void {

  }

  private setUserInfo(user: FFUser) {
    this.userInfo = user;
    if (this.userInfo.emailConfirmed && this.userInfo.isActivated) {
      this.verifiedUser = this.userInfo;
      this.userOrgs = getUserOrgs(this.userInfo);
      this.currentOrg = this.userInfo.getCurrentOrg();
      if (this.currentOrg) {
        this.orgId = this.currentOrg.organizationId;
      } else {
        this.mainService.showLoading({ loading: false });
      }
    }
    else {
      this.verifiedUser = null;
    }
  }

  public orgChange() {
    this.mainService.showLoading({ loading: true, loadingText: "Setting Organization" });
    this.userService.setMyDefaultOrg(this.orgId).subscribe(res => {
      this.authService.getUserInfo(true).then(res => {
        this.mainService.showLoading({ loading: false });
      });
      
    }, err => {
      this.mainService.showLoading({ loading: false });
      this.loggingService.error("Error setting default organization", "Error setting default organization: " + err);
    });
  }

}
