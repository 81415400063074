<section class="default-container m-b-1 user-management-container">
  
    <div class="m-2" *ngFor="let org of userOrgs">
      <div class="tooltip-wrapper">
      <label>
        <input type="radio" class="m-b-1 m-r-1" [value]="org.organizationId" [(ngModel)]="orgId" (change)="orgChange()" class="checkmark"/>
        {{org.organizationName}}
      </label>
      <app-popper-tool-tip screenReaderText="Select organization to perform work under">
      </app-popper-tool-tip>
    </div>
    </div>
  
  </section>
