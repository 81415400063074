import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { IngredientService } from '../../../../common/services/ingredient.service';
import { CASNumber } from '../../../../common/models/disclosures/cas-number.model';
import { ICASNumberCellEditorParams } from '../../../models/cas-number-cell-editor-params.model';
import { CASNumberWithNgSelectDisplayField } from '../../../../disclosure/components/ingredients/cas-number-dropdown/cas-number-with-ng-select-display-field.model';
import { CASNumberShort } from '../../../../common/models/disclosures/cas-number-short.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Ingredient } from '../../../../common/models/disclosures/ingredient.model';
import { SettableTextCellEditorComponent } from '../settable-text-cell-editor/settable-text-cell-editor.component';
import { ColumnResizedEvent, GridApi, TextCellEditor } from 'ag-grid-community';
import { isNotNullOrEmpty } from '../../../../common/functions/is-not-null-or-empty.function';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { OnLeavingEditorParams } from '../../../models/base-cell-editor/on-leaving-editor-params.model';

@Component({
  selector: 'cas-number-cell-editor',
  templateUrl: './cas-number-cell-editor.component.html',
  styleUrls: ['./cas-number-cell-editor.component.scss']
})
export class CASNumberCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  private params: ICASNumberCellEditorParams;
  public casNumberValue: string;
  public casShortNumberValue: CASNumberWithNgSelectDisplayField;
  public cellWidth: string = "";
  public cellHeight: string = "";
  public isValid: boolean = false;
  public itemExistsAlready: boolean = true;
  public existingCASNumbers: Array<CASNumberShort> = [];
  public existingCASNumbersWithNgSelectDisplayField: Array<CASNumberWithNgSelectDisplayField> = [];
  private inlineEditorColId: string = "inlineEditor";
  private subscription: Subscription = new Subscription();
  public originalValue: string = "";
  private readonly currentValue = new Subject<string | undefined>();
  private ingredientNamePatchInfo: { fieldToPatch: string, valueToPatch: any } = null;

  @ViewChild('casDropdown') dropdown: NgSelectComponent;
  @ViewChild('casInput') casInput: ElementRef;

  constructor(private ingredientService: IngredientService) {
  }

  public agInit(params: ICASNumberCellEditorParams): void {
    this.params = params;

    if (params) {

      if (this.params.dataChangedEvent) {
        this.subscription.add(this.currentValue
          .pipe(
            debounceTime(300),
            distinctUntilChanged(),
          )
          .subscribe((currentValue) => {
            if (this.params.dataChangedEvent) {
              this.params.dataChangedEvent.emit({ value: currentValue, field: this.params.colDef.field, itemId: this.params.node.id });
            }
            this.validateCasNumber();
          }));
      }

      this.existingCASNumbers = params.existingCasNumbers;
      if (params.existingCasNumbers && params.existingCasNumbers.length > 0)
        this.existingCASNumbersWithNgSelectDisplayField = this.buildCASNumbersWithDisplayFields(params.existingCasNumbers);

      if (params.inlineEditorColId !== null && params.inlineEditorColId !== undefined)
        this.inlineEditorColId = params.inlineEditorColId;
    }

    (this.params.api as GridApi).addEventListener("columnResized",
      (event: ColumnResizedEvent) => {
        if ((event.column && event.column.getId() == this.params.colDef.colId) || event.column === null || this.params.colDef.colId === undefined)
          this.cellWidth = (params.column.getActualWidth()) - 23 + 'px';
      }
    );

    this.setInitialState(this.params);
    this.cellWidth = (params.column.getActualWidth()) - 23 + 'px';
    this.cellHeight = (params.node.rowHeight) + 'px';
    this.isValid = this.ingredientService.isFracCasValid(this.casNumberValue) && isNotNullOrEmpty(this.casNumberValue);

  }

  public ngAfterViewInit() {
    this.checkForMatchesInExistingCasNumbers(this.params.value, true);
    this.validateCasNumber();
  }

  public ngOnDestroy() {
    this.focusOut();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  buildCASNumbersWithDisplayFields(casNumbers: Array<CASNumber>): Array<CASNumberWithNgSelectDisplayField> {
    let builtCasNumbers: Array<CASNumberWithNgSelectDisplayField> = [];
    casNumbers.forEach(x => builtCasNumbers.push(new CASNumberWithNgSelectDisplayField(x)));
    return builtCasNumbers;
  }

  setInitialState(params: ICASNumberCellEditorParams) {
    if (params.value != undefined && params.value != null) {
      this.originalValue = JSON.parse(JSON.stringify(params.value));
    }
    this.casNumberValue = params.value;
    this.checkForMatchesInExistingCasNumbers(params.value, true);
    this.handleExpandingClaimantInfo();
  }

  getValue(): any {
    return this.casNumberValue;
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    } 
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  focusOut(): void {
    //On focus out, check if the value has changed, and is Valid, if so patch it
    if (this.originalValue != this.casNumberValue && this.params.onLeavingEditor) {
      let leavingEditorParams = {
        data: this.params.data,
        patchInfo: [{
          fieldToPatch: "/" + this.params.colDef.field,
          valueToPatch: this.casNumberValue
        }]
      } as OnLeavingEditorParams;

      if (this.ingredientNamePatchInfo != null) {
        leavingEditorParams.patchInfo.push(this.ingredientNamePatchInfo);
      }

      this.params.onLeavingEditor(leavingEditorParams);
      this.ingredientNamePatchInfo = null;

      this.params.node.data[this.params.colDef.field] = JSON.parse(JSON.stringify(this.casNumberValue));
      this.originalValue = JSON.parse(JSON.stringify(this.casNumberValue));
    }
  };

  focusIn(): void {
    if (this.casInput && this.casInput.nativeElement) {
      this.casInput.nativeElement.focus();
    }
    else if (this.dropdown) {
      this.dropdown.focus();
    }
  };

  validateCasNumber() {
    this.isValid = this.ingredientService.isFracCasValid(this.casNumberValue) && isNotNullOrEmpty(this.casNumberValue);
    this.handleExpandingClaimantInfo();
  }

  checkForMatchesInExistingCasNumbers(searchString: string, setMatchingValue:boolean = false) {
    if (this.existingCASNumbersWithNgSelectDisplayField && this.existingCASNumbersWithNgSelectDisplayField.length > 0) {
      let matchingCASNumbers = this.existingCASNumbersWithNgSelectDisplayField.filter(x => x.numberAndDescription.includes(searchString));
      let ingredient = this.params.data as Ingredient;
      if (matchingCASNumbers.length > 0) {
        let alsoMatchingIngredientName = matchingCASNumbers.filter(x => x.ingredientName.toLowerCase() == ingredient.ingredientName.toLowerCase());
        if (setMatchingValue) {
          //Check if we have one where the common name matches
          if (alsoMatchingIngredientName.length > 0) {
            this.casShortNumberValue = alsoMatchingIngredientName[0];
          }
          else {

            this.casShortNumberValue = searchString != "" ? matchingCASNumbers[0] : null;
          }
        }
        this.itemExistsAlready = true;
      }
      else {
        this.existingCASNumbersWithNgSelectDisplayField = this.existingCASNumbersWithNgSelectDisplayField.filter(x => !x.isATemporaryEntry);
        this.itemExistsAlready = false;
        let newItem = new CASNumberWithNgSelectDisplayField({ casNumber: searchString, ingredientName: "" } as CASNumberShort);
        this.existingCASNumbersWithNgSelectDisplayField = [...this.existingCASNumbersWithNgSelectDisplayField, newItem];
      }
    }
  }

  stripLeadingZeros(casNumber: string): string {
    let fixedString = "";
    let pastLeadingZeros = false;
    if (isNotNullOrEmpty(casNumber)) {
      for (let x = 0; x < casNumber.length; x++) {
        if (!pastLeadingZeros && casNumber[x] != '0') {
          pastLeadingZeros = true;
          fixedString += casNumber[x];
        }
        else {
          if (pastLeadingZeros) {
            fixedString += casNumber[x];
          }
        }
      }

      return fixedString;
    }

    return casNumber;
  }

  casNumberDropdownSearch(searchParams: { term: string; items: CASNumberWithNgSelectDisplayField[]; }) {

    let leading0StrippedSearchParams = this.stripLeadingZeros(searchParams.term);
    this.dropdown.searchTerm = leading0StrippedSearchParams;
    this.dropdown.searchInput.nativeElement.value = leading0StrippedSearchParams;
    this.casNumberValue = leading0StrippedSearchParams;
    this.validateCasNumber();
    if (isNotNullOrEmpty(leading0StrippedSearchParams)) {
      this.checkForMatchesInExistingCasNumbers(leading0StrippedSearchParams);     
      this.emitDataChangedEvent();
    }
  }

  handleExpandingClaimantInfo() {
    if (this.ingredientService.validCASAlternatives.filter(x => x.toLowerCase() == this.casNumberValue.toLowerCase()).length > 0) {
      this.params.node.setExpanded(true);
    }
    else if (this.params.node.expanded) {
      this.params.node.setExpanded(false);
      (this.params.data as Ingredient).claimantCompany = "";
      (this.params.data as Ingredient).claimantEmail = "";
      (this.params.data as Ingredient).claimantFirstName = "";
      (this.params.data as Ingredient).claimantLastName = "";
      (this.params.data as Ingredient).claimantPhone = "";

      //If a onLeavingEditor was provided, and the value of a field differs from its original, patch it.
        let onLeavingEditorParams = {
          data: this.params.data,
          patchInfo: [{
            fieldToPatch: "/claimantCompany",
            valueToPatch: ""
          }, {
          fieldToPatch: "/claimantEmail",
            valueToPatch: ""
          }, {
          fieldToPatch: "/claimantFirstName",
            valueToPatch: ""
          }, {
          fieldToPatch: "/claimantLastName",
            valueToPatch: ""
          }, {
          fieldToPatch: "/claimantPhone",
            valueToPatch: ""
          },
          ]
        } as OnLeavingEditorParams;

        this.params.onLeavingEditor(onLeavingEditorParams);

      this.params.dataChangedEvent.emit({ value: "", field: this.params.colDef.field, itemId: this.params.node.id });
    }
  }

  casNumberDropdownChanged(casNumber: CASNumberWithNgSelectDisplayField) {
    this.casNumberValue = casNumber != null ? casNumber.casNumber : "";

    let ingredientNameCellEditorInstances = this.params.api.getCellEditorInstances({ columns: ['ingredientName'] });
    let ingredientNameCellEditorInstance = ingredientNameCellEditorInstances[0] as SettableTextCellEditorComponent;
    if (ingredientNameCellEditorInstance) {
      let ingredientName = casNumber != null ? casNumber.ingredientName : "";
      ingredientNameCellEditorInstance.setValue(ingredientName);
      this.ingredientNamePatchInfo = { fieldToPatch: "/ingredientName", valueToPatch: ingredientName };
    }

    let commonNameCellEditorInstances = this.params.api.getCellEditorInstances({ columns: ['commonName'] });
    let commonNameCellEditorInstance = commonNameCellEditorInstances[0] as SettableTextCellEditorComponent;
    if (commonNameCellEditorInstance) {
      commonNameCellEditorInstance.setValue(casNumber != null ? casNumber.commonName : "");
    }

    this.validateCasNumber();
    this.emitDataChangedEvent();
  }

  emitDataChangedEvent(): void {
    let valueToEmit = null;

    //Only emit the new value once it's valid, else emit null
    if (this.params.dataChangedEvent && this.isValid) {
      valueToEmit = this.casNumberValue;
    }

    this.params.dataChangedEvent.emit({ value: valueToEmit, field: this.params.colDef.field, itemId: this.params.node.id });
  }

  public onKeyUp(event: any): void {

    if (event.key == "0") {
    }

    this.currentValue.next(event.target.value);

    if (event.key == "Escape") {
      this.focusOut();
    }
  }
}
