import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { TwoFALoginRequestModel } from '../models/two-fa-login-request.model';
import { TwoFACodeGenerateModel } from '../models/two-fa-code-generate-request.model';
import { FFUserWithRoles } from '../models/users/ff-user-with-roles.model';
import { TwoFACodeGenerateResponseModel } from '../models/two-fa-code-generate-response.model';
import { UserCommunicationDetail } from '../models/users/user-communication-detail';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private warningTimeout: any;
  private logoutTimeout: any;
  private authTimeoutValue: any = null;
  //The number of minutes before the auto-logout to show the Warning message
  private warningTimer: number = 5;
  private warningToast: any;

  public authenticated: boolean = false;
  private infoRetrieved: boolean = false;
  private infoBeingFetched: boolean = false;
  public userInfo: FFUserWithRoles | null = null;

  public setTitle: BehaviorSubject<string> = new BehaviorSubject('Water Wells Database');
  public userInfoEmitter: EventEmitter<FFUserWithRoles> = new EventEmitter(true);

  constructor(
    private http: HttpClient
  ) {
    this.resetServiceState();
  }


  public get(): Observable<any> {
    return this.http.get<any>('/api/auth');
  }
  public getClaims(): Observable<any> {
    return this.http.get<any>('/api/auth/claims');
  }

  public login(login: any): Observable<any> {
    return this.http.post<any>('/api/auth/login', login);
  }

  public logout(logoutId: string, postLogoutRedirectUri: string = "/"): Observable<any> {
    return this.http.post<{ iFrameUrl: string, postLogoutRedirectUri: string }>(`/api/auth/logout?logoutId=${logoutId}`, postLogoutRedirectUri);
  }

  public register(register: any): Observable<any> {
    return this.http.post<any>('/api/auth/register', register);
  }

  public confirmEmail(userId: string, code: string): Observable<any> {
    return this.http.post<any>(`/api/auth/confirmEmail?userId=${userId}&code=${code}`, null);
  }

  public forgotPassword(forgot: any): Observable<any> {
    return this.http.post<any>('/api/auth/forgotPassword', forgot);
  }

  public resetPassword(reset: any): Observable<any> {
    return this.http.post<any>('/api/auth/resetPassword', reset);
  }

  public resendEmailConfirmation(resend: any): Observable<any> {
    return this.http.post<any>('/api/auth/resendEmailConfirmation', resend);
  }

  public inviteUser(registerInfo: any): Observable<any> {
    return this.http.post<any>('/api/auth/invite', registerInfo);
  }

  public reInviteUser(registerInfo: any): Observable<any> {
    return this.http.post<any>('/api/auth/reinvite', registerInfo);
  }

  public submitTwoFaCode(twoFactorCode: TwoFALoginRequestModel): Observable<void> {
    return this.http.post<void>('/api/auth/submitTwoFaCode', twoFactorCode);
  }

  public resendTwoFACode(twoFactorCodeResendRequest: TwoFACodeGenerateModel): Observable<TwoFACodeGenerateResponseModel> {
    return this.http.post<any>('/api/auth/resendTwoFaCode', twoFactorCodeResendRequest);
  }

  public getUserCommunicationModeDetails(userId: string): Observable<UserCommunicationDetail> {
    return this.http.get<UserCommunicationDetail>(`/api/auth/GetUserCommunicationModeDetails?userId=${userId}`);
  }
  
  public getUserInfo(forceRefetch: boolean = false): Promise<FFUserWithRoles> {
    return new Promise((resolve, reject) => {
      if ((this.userInfo || this.infoRetrieved) && !forceRefetch) {
        this.authenticated = (this.userInfo != null && this.userInfo != undefined && this.userInfo.email != undefined && this.userInfo.email != "");
        resolve(this.userInfo);
      } else {
        if (!this.infoBeingFetched) {
          this.infoBeingFetched = true;
          this.http.get<FFUserWithRoles>('/api/Auth/GetUserInfo').pipe(map(res => new FFUserWithRoles(res))).subscribe((response: any) => {
            this.userInfo = response;
            this.infoBeingFetched = false;
            this.infoRetrieved = true;
            if (this.userInfo && this.userInfo.id) {
              this.authenticated = true;
            }
            resolve(this.userInfo);
            this.userInfoEmitter.emit(this.userInfo);
          }, (error: any) => {
            this.infoBeingFetched = false;
            reject(error);
          });
        }
        else {         
          this.checkForUpdatedInfo(resolve);
        }
      }
    });
  }

  private checkForUpdatedInfo(resolve: any) {
    if (this.infoBeingFetched) {
      setTimeout(() => {
        this.checkForUpdatedInfo(resolve);
      }, 1000);
    }
    else resolve(this.userInfo);
  }

  public hasRole(role: string): boolean {
    if (!this.userInfo) return false;
    return this.userInfo.isAdmin;
  }

  public hasPermission(permission: string): boolean {
    if (!this.userInfo) {
      return false;
    }
    for (let i = 0; i < this.userInfo.permissions.length; i++) {
      if (this.userInfo.permissions[i] === permission) { return true; }
    }
    return false;
  }

  public updatePassword(update: any): Observable<any> {
    return this.http.post<any>('/api/auth/updatePassword', update);
  }

  public resetServiceState(): void {
    this.infoRetrieved = false;
    this.authenticated = false;
    this.infoBeingFetched = false;
    this.userInfo = null;
  }

  public impersonateLogin(email: string) {
    return this.http.get<any>(`/api/Impersonation/Login?email=${email}`);
  }

  public impersonateLogout() {
    return this.http.get<any>(`/api/Impersonation/Logout`);
  }

  public unlockUser(email: string) {
    return this.http.get<any>(`/api/auth/unlockUser?email=${email}`);
  }

}
