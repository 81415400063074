import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'edit-delete-cell',
  templateUrl: './edit-delete-cell.component.html',
  styleUrls: ['./edit-delete-cell.component.scss']
})
export class EditDeleteCellComponent implements ICellRendererAngularComp {

  private cellName: string = '';

  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public readOnly: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
    //this.readOnly = gridService.readOnly.getValue();
  }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.cellName = params?.colDef?.cellRenderer?.toString() || '';
    this.originalData = JSON.parse(JSON.stringify(this.data));
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  public toggleReadOnly(): void {
    this.readOnly = !this.readOnly;
    //this.gridService.selectedTable.next(this.cellName);
    //this.gridService.readOnly.next(this.readOnly);
    this.data.disabled = this.readOnly;
    if (!this.readOnly) {
      this.api.redrawRows();
      this.api.startEditingCell({
        rowIndex: this.rowIndex,
        colKey: '0'
      });
    } else {
      this.api.stopEditing(true);
      this.api.redrawRows();
    }
  }

  public deleteRow(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "delete", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public save(): void {
    this.readOnly = true;
    //this.gridService.readOnly.next(this.readOnly);
    this.data.disabled = this.readOnly;
    this.api.redrawRows();
    this.api.stopEditing();
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "editComplete", data: this.data });
    }
  }

}
