<div class="contentContainer">
  <ui-icon-button *ngIf="readOnly && !onlyHandleDelete" [title]="'Edit'" [ngClass]="{'error': !readOnly}" [color]="'BLUE' | iconButtonColorEnumPipe" [iconName]="'icon-circle-edit'" [heightInPx]="'28px'" [widthInPx]="'28px'" [screenReaderText]="'Edit this item?'" (clicked)="startEditing()"></ui-icon-button>
  <ui-icon-button *ngIf="!readOnly && !onlyHandleDelete" [title]="'Cancel'" [ngClass]="{'error': !readOnly}" [color]="'RED' | iconButtonColorEnumPipe" [iconName]="'icon-circle-cancel'" [heightInPx]="'28px'" [widthInPx]="'28px'" [screenReaderText]="'Cancel the Edit of this item?'" (clicked)="stopEditing(true)"></ui-icon-button>
  <ui-icon-button *ngIf="!readOnly && !params.node.rowPinned && !onlyHandleDelete" [disabled]="!canSave" [title]="'Save'" [ngClass]="{'error': !readOnly}" [color]="'BLUE' | iconButtonColorEnumPipe" [iconName]="'icon-circle-save'" [heightInPx]="'28px'" [widthInPx]="'28px'" [screenReaderText]="'Save this item?'" (clicked)="save()"></ui-icon-button>
  <ui-icon-button *ngIf="params.node.rowPinned" [title]="'Add'" [disabled]="(readOnly && !onlyHandleDelete) || !canAdd" [ngClass]="{'error': !readOnly}" [color]="'BLUE' | iconButtonColorEnumPipe" [iconName]="'icon-circle-plus'" [heightInPx]="'28px'" [widthInPx]="'28px'" [screenReaderText]="'Add this item?'" (clicked)="add()"></ui-icon-button>
  <ui-icon-button *ngIf="((canDelete && readOnly) || onlyHandleDelete) && !params.node.rowPinned" [title]="'Delete'" [ngClass]="{'error': !readOnly}" [color]="'RED' | iconButtonColorEnumPipe" [iconName]="'icon-circle-trash-can'" [heightInPx]="'28px'" [widthInPx]="'28px'" [screenReaderText]="'Delete this item?'" (clicked)="deleteRow()"></ui-icon-button>
  <svg-icon-sprite *ngIf="hasError()" class="svg-icon" [src]="'assets/sprite/sprite.svg#icon-stop-sign'" [width]="'28px'" [height]="'28px'" [title]="getErrorOrWarningMessage('validationError')"></svg-icon-sprite>
  <svg-icon-sprite *ngIf="hasWarning()" class="svg-icon" [src]="'assets/sprite/sprite.svg#icon-caution-sign'" [width]="'28px'" [height]="'28px'" [title]="getErrorOrWarningMessage('validationWarning')"></svg-icon-sprite>
  <ui-icon-button  *ngIf="canRenew" [iconName]="'icon-refresh'" [heightInPx]="'28px'" [color]="'GREEN' | iconButtonColorEnumPipe" [widthInPx]="'28px'" [title]="'Renew'" [screenReaderText]="'Renew this item?'" (clicked)="renew()"></ui-icon-button>
</div>
