<nav aria-label="Main Site Navigation" role="navigation">
  <button class="hamburger" (click)="toggleMenuHamburger()"
          [ngClass]="{'show':!hamburgerMenuDisplay, 'hide': hamburgerMenuDisplay}">
    <svg-icon-sprite [src]="'assets/sprite/sprite.svg#icon-hamburger'" [width]="'40px'" [height]="'24px'">
    </svg-icon-sprite>
  </button>
  <button class="hamburger" (click)="toggleMenuHamburger()"
          [ngClass]="{'show':hamburgerMenuDisplay, 'hide': !hamburgerMenuDisplay}">
    <svg-icon-sprite [src]="'assets/sprite/sprite.svg#icon-hamburger-close'" [width]="'24px'" [height]="'24px'">
    </svg-icon-sprite>
  </button>
  <ul class="reset" [ngClass]="{'visible' : hamburgerMenuDisplay}" role="menubar">
    <li role="none">
      <span class="tooltip-wrapper">
        <a id="faw" appNavSubMenuHeaderDirective role="menuitem" (mouseup)="hamburgerMenuDisplay = false"
           aria-haspopup="false" href="https://www.fracfocus.org/wells/advanced" target="_blank">Find a Well</a>
        <app-popper-tool-tip screenReaderText="Search for a Submitted Disclosure">
        </app-popper-tool-tip>
      </span>
    </li>
    <li role="none">
      <span class="tooltip-wrapper">
        <a id="about" appNavSubMenuHeaderDirective role="menuitem" (mouseup)="hamburgerMenuDisplay = false"
           aria-haspopup="false" routerLink="/about">About</a>
        <app-popper-tool-tip screenReaderText="View about the FracFocus chemical disclosure registry">
        </app-popper-tool-tip>
      </span>
    </li>
    <li role="none" *ngIf="userInfo && userInfo.userName && currentOrg">
      <span class="tooltip-wrapper">
        <a id="dashboard" appNavSubMenuHeaderDirective role="menuitem" (mouseup)="hamburgerMenuDisplay = false"
           aria-haspopup="false" routerLink="/dashboard">Dashboard</a>
        <app-popper-tool-tip screenReaderText="Return to Dashboard">
        </app-popper-tool-tip>
      </span>
    </li>
    <li role="none" appNavSubMenuParentDirective *ngIf="userInfo && userInfo.userName && currentOrg">
      <button id="disclosures" role="menuitem" appNavSubMenuHeaderDirective aria-haspopup="true"
              class="has-submenu">
        <span class="sr-only">Show sub-menu for Disclosures</span>Disclosures<span class="separator">&vert;</span><span class="arrow">&#9662;</span>
      </button>

      <ul class="reset" role="menu" aria-haspopup="false" aria-label="Disclosures Options">
        <li role="none" *ngIf="currentOrg && userInfo.hasOrgRole('DataSubmitter')"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/disclosure/edit-disclosure/new">Add/Upload New Disclosure</a></li>
        <li role="none" *ngIf="currentOrg && (userInfo.hasOrgRole('DataSubmitter') || userInfo.hasOrgRole('Supervisor'))"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/disclosure/manage-disclosures">Manage Disclosures</a></li>
        <li role="none" *ngIf="currentOrg"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/disclosure/third-party-purposes">3rd Party Chemicals</a></li>
        <li role="none" *ngIf="userInfo"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/disclosure/view-disclosures">Submitted Disclosures</a></li>
      </ul>
    </li>
    <li role="none" appNavSubMenuParentDirective
        *appCanaccess="{user: userInfo,
        permissions: [CommonRoles.ServiceSupervisor, CommonRoles.OperatorSupervisor, CommonRoles.RegisteredAgtSupervisor, CommonRoles.OperatorSubmitter]}">
      <button id="manageOrganizations" role="menuitem" appNavSubMenuHeaderDirective
              aria-haspopup="true" class="has-submenu">
        <span class="sr-only">Manage Organization</span>Manage Organization<span class="separator">&vert;</span><span class="arrow">&#9662;</span>
      </button>

      <ul class="reset" role="menu" aria-haspopup="false" aria-label="Manage Organization Options">
        <li role="none" *ngIf="currentOrg && (userInfo.hasOrgRole('Supervisor'))"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/profile">Manage Organization Profile</a></li>
        <li role="none" *ngIf="!currentOrg"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/registration">New Organization Registration</a></li>
        <li role="none" *ngIf="currentOrg && (userInfo.hasOrgRole('Supervisor'))"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/manage-users">Manage Organization Users</a></li>
        <li role="none" *appCanaccess="{user: userInfo, permissions: [CommonRoles.OperatorSupervisor]}"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/manage-org-relations">Manage Organization Relations</a></li>
        <li role="none" *ngIf="currentOrg && currentOrg.isOperator && !userInfo.hasOrgRole('Supervisor') && userInfo.hasOrgRole('DataSubmitter')"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/manage-org-relations">View Organization Relations</a></li>
        <li role="none" *ngIf="currentOrg && userInfo.hasOrgRole('Supervisor')"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/organization-defaults">Manage Organization Defaults</a></li>
        <li role="none" *appCanaccess="{user: userInfo, permissions: [CommonRoles.ServiceSupervisor, CommonRoles.RegisteredAgtSupervisor]}"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/organization/operator-approvals">Operator Approvals</a></li>
        <!--<li role="none" *appCanaccess="{user: userInfo, permissions: [CommonRoles.OperatorSubmitter]}"><a appNavSubMenuItemDirective role="menuitem" href="#">View Service Companies</a></li>-->
      </ul>
    </li>

    <li role="none" *appCanaccess="{user: userInfo, permissions: [CommonRoles.ServiceUser, CommonRoles.RegisteredAgtUser], hiddenFromPermissions: [CommonRoles.ServiceSupervisor, CommonRoles.RegisteredAgtSupervisor]}">
      <a (mouseup)="hamburgerMenuDisplay = false" id="operator-approvals" appNavSubMenuHeaderDirective role="menuitem" aria-haspopup="false" href="/organization/operator-approvals">
        Operator Approvals
      </a>
    </li>
    <li role="none" appNavSubMenuParentDirective>
      <button id="userGuides" role="menuitem" appNavSubMenuHeaderDirective aria-haspopup="true"
              class="has-submenu">
        <span class="sr-only">User Guides</span>User Guides<span class="separator">&vert;</span><span class="arrow">&#9662;</span>
      </button>

      <ul class="reset" role="menu" aria-haspopup="false" aria-label="User Guides Options">
        <li *ngIf="userInfo && userInfo.userName" role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" href="/assets/FracFocus_Schema_Requirements.pdf" target="_blank">Schema Guide</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" href="/assets/FracFocus_User_Guide_V1.pdf" target="_blank">User Guide</a></li>
        <li role="none" *ngIf="userInfo && userInfo.isAdmin"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" href="/assets/FracFocus_Admin_Guide_V1.pdf" target="_blank">Admin Guide</a></li>
      </ul>

    </li>
    <li role="none" appNavSubMenuParentDirective *ngIf="userInfo && userInfo.userName && userInfo.isAdmin">
      <button id="administration" role="menuitem" appNavSubMenuHeaderDirective
              aria-haspopup="true" class="has-submenu">
        <span class="sr-only">Administration</span>Administration<span class="separator">&vert;</span><span class="arrow">&#9662;</span>
      </button>
      <ul class="reset" role="menu" aria-haspopup="false" aria-label="Administration Options">
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/system-logs">System Logs</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/disclosure-admin">Manage/Remove Disclosures</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/users">Manage Accounts</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/registrations">Manage Registrations</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/organization-stats">Organization Stats</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/ingredients">Manage Ingredients</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/notifications">Manage Notifications</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/global-defaults">System Configuration Settings</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" routerLink="/administration/reports">Reports</a></li>
        <li role="none"><a (mouseup)="hamburgerMenuDisplay = false" appNavSubMenuItemDirective role="menuitem" href="/hangfire">Hangfire Dashboard</a></li>
      </ul>

    </li>
    <li role="none" appNavSubMenuParentDirective *ngIf="userInfo && userInfo.userName && availableReports && availableReports.length">
      <button id="reports" role="menuitem" appNavSubMenuHeaderDirective
              aria-haspopup="true" class="has-submenu">
        <span class="sr-only">Reports</span>Reports<span class="separator">&vert;</span><span class="arrow">&#9662;</span>
      </button>
      <ul class="reset" role="menu" aria-haspopup="false" aria-label="Reports">
        <li role="none" *ngFor="let r of availableReports"><a appNavSubMenuItemDirective role="menuitem" [routerLink]="['/organization-reports/reports/operator/',r.storedProcedure]">{{r.reportName}}</a></li>
        <li role="none" *ngFor="let r of adminReports"><a appNavSubMenuItemDirective role="menuitem" [routerLink]="['/organization-reports/reports/operator/',r.storedProcedure]">{{r.reportName}}</a></li>
      </ul>

    </li>
    <li role="none">
      <div class="user-info-container">
        <ui-user-info></ui-user-info>
      </div>
    </li>
  </ul>
</nav>
