<div class="header-container">
  <section name="logo" class="logo-container" [ngClass]="isCursorEnable ? 'cursor-pointer' : 'cursor-auto'">
    <a (click)="onLogoClick()">
      <svg-icon-sprite [src]="'assets/sprite/sprite.svg#icon-ff-logo'" [width]="'148px'" [height]="'63px'"></svg-icon-sprite>
    </a>
  </section>
  <div class="user-info-container">
    <ui-user-info></ui-user-info>
  </div>
  <div class="m-l-2">
    <button *ngIf="isImpersonated" (click)="exitImpersonation()" uiLibButton 
      [color]="'PRIMARY' | buttonColorEnumPipe" 
      [size]="'MEDIUM' | buttonSizeEnumPipe" 
      [shape]="'ROUNDED' | buttonShapeEnumPipe" 
      [style]="'OUTLINED' | buttonStyleEnumPipe">Exit Impersonation</button>
  </div>
  <ui-main-nav></ui-main-nav>
</div>
<div class="notifications p-1" *ngIf="basicNotifications && basicNotifications.length"><span class="notificationText" *ngFor="let not of basicNotifications">{{not.text}}</span></div>
