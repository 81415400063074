import { Injectable } from "@angular/core";
import { ColDef, CsvExportParams, ExcelDataType, ExcelExportParams, ExcelStyle, GridApi, GridOptions, ProcessCellForExportParams, RowNode, SuppressKeyboardEventParams, ValueFormatterFunc, ValueFormatterParams } from "ag-grid-enterprise";
import { isNullOrUndefined } from "util";
import { isNotNullOrEmpty } from "../../common/functions/is-not-null-or-empty.function";
import { ThirdPartyPurpose } from "../../common/models/disclosures/3rd-party-purpose";
import { DisclosureValidationStatement } from "../../common/models/disclosures/disclosure-validation-statement.model";
import { Purpose } from "../../common/models/disclosures/purpose.model";
import { RefThirdPartyStatus } from "../../common/models/enums/third-party-chemical-status.enum";
import { DisclosureValidationIdType } from "../models/disclosure-validation-id-type.enum";
import { IngredientService } from '../../common/services/ingredient.service';

@Injectable({
  providedIn: 'root'
})
export class AGGridHelperService {
      
  constructor(private ingredientService: IngredientService) {
  }

  getDefaultExcelStyles(): ExcelStyle[]{
    return [
      {
        id: 'body',
        dataType: 'String',
        interior: {
          color: '#dddddd',
          pattern: 'Solid',
        },
        alignment: { horizontal: 'Left' },
      } as ExcelStyle,
      {
        id: 'numberType',
        numberFormat: {
            format: '0',
        },
      } as ExcelStyle,
      {
        id: 'subContentNumberWithNoDecimalsType',
        dataType: 'Number',
        interior: {
          color: '#dddddd',
          pattern: 'Solid',
        },
        numberFormat: {
          format: '0',
        },
        alignment: { horizontal: 'Left' },
      } as ExcelStyle,
      {
        id: 'currencyFormat',
        numberFormat: {
            format: '#,##0.00 €',
        },
      } as ExcelStyle,
      {
        id: 'negativeInBrackets',
        numberFormat: {
            format: '$[blue] #,##0;$ [red](#,##0)',
        },
      } as ExcelStyle,
      {
        id: 'booleanType',
        dataType: 'Boolean',
      } as ExcelStyle,
      {
        id: 'stringType',
        dataType: 'String',
        alignment: { horizontal: 'Left' },
      } as ExcelStyle,
      {
        id: 'dateType',
        dataType: 'DateTime',
      } as ExcelStyle,
      {
        id: 'header',
        interior: {
          color: '#aaaaaa',
          pattern: 'Solid',
        },
        alignment:{ horizontal:'Left'}
      } as ExcelStyle,
    ]
  }

  getDefaultColumnDef(suppressEnterWhileEditing: boolean = false): ColDef {
    let defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    } as ColDef;

    if (suppressEnterWhileEditing) {
      //This is to prevent pressing the enter key from ending editing to preserve data reset on cancel
      defaultColDef.suppressKeyboardEvent = (event: SuppressKeyboardEventParams) => {
        let shouldSuppress: boolean = false;

        if (event.event.keyCode == 13)
          shouldSuppress = true;

        return shouldSuppress;
      }
    }

    return defaultColDef;
  }

  errorAppliesToField(validationStatements: Array<DisclosureValidationStatement>, fieldName: string, idType: DisclosureValidationIdType, itemId: string): boolean {
    let isValid = true;

    for (let x = 0; x < validationStatements.length; x++) {
      let relevantStatement = validationStatements[x];

      let fieldName1 = "";
      let fieldName2 = "";
      let fieldName3 = "";
      let fieldName4 = "";

      if (relevantStatement.field1 != null && relevantStatement.field1 != undefined) {
        fieldName1 = relevantStatement.field1;
      }

      if (relevantStatement.field2 != null && relevantStatement.field2 != undefined) {
        fieldName2 = fieldName2;
      }

      if (relevantStatement.field3 != null && relevantStatement.field3 != undefined) {
        fieldName3 = fieldName3;
      }

      if (relevantStatement.field4 != null && relevantStatement.field4 != undefined) {
        fieldName4 = fieldName4;
      }

      switch (idType) {
        case DisclosureValidationIdType.Purpose:
          if (relevantStatement.purposeId != null && relevantStatement.purposeId != undefined && relevantStatement.purposeId == itemId
            && (fieldName1 == fieldName || fieldName2 == fieldName || fieldName3 == fieldName || fieldName4 == fieldName)) {
            isValid = false;
          }
          break;
        case DisclosureValidationIdType.Ingredient:
          if (relevantStatement.ingredientsId != null && relevantStatement.ingredientsId != undefined && relevantStatement.ingredientsId == itemId
            && (fieldName1 == fieldName || fieldName2 == fieldName || fieldName3 == fieldName || fieldName4 == fieldName)) {
            isValid = false;
          }
          break;
        case DisclosureValidationIdType.WaterSource:
          if (relevantStatement.waterSourceId != null && relevantStatement.waterSourceId != undefined && relevantStatement.waterSourceId == itemId
            && (fieldName1 == fieldName || fieldName2 == fieldName || fieldName3 == fieldName || fieldName4 == fieldName)) {
            isValid = false;
          }
          break;
      }
    }

    return isValid;
  }

  public exportDataToExcel(fileName: string, sheetName: string, gridOptions: GridOptions, selectedOnly: boolean = false, excelExportParams?: ExcelExportParams, skipPinnedRows: boolean = true): void {
    const params = {
      skipHeader: false,
      allColumns: true,
      fileName: fileName,
      sheetName: sheetName,
      onlySelected: selectedOnly,
      onlySelectedAllPages: selectedOnly,
      excelExportParams: excelExportParams,
      skipPinnedTop: skipPinnedRows,
      skipPinnedBottom: skipPinnedRows,
      getCustomContentBelowRow: excelExportParams?.getCustomContentBelowRow,
      processCellCallback: (params: ProcessCellForExportParams) => this.processCallback(params)
    } as ExcelExportParams;
    gridOptions.api.exportDataAsExcel(params);
  }

  private processCallback(params: any): string {
    const colDef: ColDef = params.column.getColDef();
    let returnValue = params.value;
    colDef.valueFormatter = colDef.valueFormatter as ValueFormatterFunc
    if (colDef.valueFormatter) {
      const valueFormatterParams: ValueFormatterParams = {
        ...params,
        data: params.node.data,
        node: params.node!,
        colDef: params.column.getColDef()
      };
      returnValue = colDef.valueFormatter(valueFormatterParams);
    }
    return returnValue;
  }

  dateFilterComparer(filterLocalDateAtMidnight: string, cellValue: string) {
    var filterDate = new Date(filterLocalDateAtMidnight);
    var cellDate = new Date(cellValue);
    
    var filterDateUTC = new Date(Date.UTC(filterDate.getUTCFullYear(), filterDate.getUTCMonth(), filterDate.getUTCDate()));
    var cellDateUTC = new Date(Date.UTC(cellDate.getUTCFullYear(), cellDate.getUTCMonth(), cellDate.getUTCDate()));
    
    if (cellDateUTC < filterDateUTC) {
        return -1;
    } else if (cellDateUTC > filterDateUTC) {
        return 1;
    } else {
        return 0;
    }
  }
  thirdPartyStatusComparer(status1: string, status2: string) {
    let convertedStatus1 = ConvertStatusToRankedVersions(status1);
    let convertedStatus2 = ConvertStatusToRankedVersions(status2);
    if (convertedStatus1 < convertedStatus2) {
      return -1;
    }
    if (convertedStatus1 > convertedStatus2) {
      return 1;
    }
    return 0;


    function ConvertStatusToRankedVersions(status: string): number {
      switch (status) {
        case RefThirdPartyStatus.Pending:
          return 4;
        case RefThirdPartyStatus.Active:
          return 3;
        case RefThirdPartyStatus.Inactive:
          return 2;
        case RefThirdPartyStatus.Deleted:
          return 1;
      }

      return 0;
    }
  }

  public calculateMinMaxGridHeightAsNum(numRows: number, numPinnedRows: number = 1, min: number, max: number, rowHeight: number = 40.5): number {
    if (numRows < min) {
      numRows = min;
    }
    if (numRows > max) {
      numRows = max;
    }
    //50 : header, 47: paging
    let rowHeights = 50 + 47 + ((numRows + numPinnedRows) * rowHeight);
    return rowHeights;
  }

  //used for outer min/max grids
  public calculateMinMaxGridHeight(numRows: number, numPinnedRows: number = 1, min: number, max: number, rowHeight: number = 40.5): string {
    let rowHeights = this.calculateMinMaxGridHeightAsNum(numRows, numPinnedRows, min, max, rowHeight);
    return rowHeights + "px";
  }

  //used for inner/full height grids
  public calculateGridHeight(numRows: number, numPinnedRows: number = 0, appendPX:boolean = true): string | number {

    if (numRows < 1) {
      numRows = 1;
    }

    let rowHeights = 50 + ((numRows + numPinnedRows) * 41);

    return appendPX ? rowHeights + "px" : rowHeights;
  }

  public calculateDetailPanelHeight(numRows: number, numPinnedRows: number = 0): number {
    if (numRows < 1) {
      numRows = 1;
    }
    return (numRows + numPinnedRows + 2) * 41;
  }

  public calculateHistoryDetailPanelHeight(numRows: number): number {
    if (numRows === 0) {
      //for "No history data found" line
      numRows = 1;
    }
    //default height: History / Close History row
    const defaultHeight = 35;
    //27: single history row with margin bottom
    const rowHeight = 27;
    //padding for detail cell
    const detailCellPadding = 32;

    return defaultHeight + (numRows * rowHeight) + detailCellPadding;
  }

  public calculateDetailRowHeightWithAdditionalInformation(node: RowNode): number {
    let numRows = node.data.ingredients ? node.data.ingredients.length : 0;
    let numRowsWithAdditionalInfo = 0;
    if (numRows > 0) {
      numRowsWithAdditionalInfo = node.data.ingredients.filter((x:any) => this.ingredientService.validCASAlternatives.some(y => y.toLowerCase() == x.casNumber.toLowerCase())).length;
    }
    //calc details panel height
    let detailPanelHeight = this.calculateDetailPanelHeight(numRows);
    detailPanelHeight += 80 * numRowsWithAdditionalInfo;

    return detailPanelHeight;
  }

  public calculateExpandedGridHeight(unexpandedGridHeight: string, combinedExpandedRowHeight:number): string {
    let unexpandedGridHeightNum = parseInt(unexpandedGridHeight.slice(0, unexpandedGridHeight.length - 2));
    let combinedGridHeight = combinedExpandedRowHeight + unexpandedGridHeightNum;
    return combinedGridHeight.toString() + "px";
  }

  public formatAPINumber(apiNumber: string): string {
    if (!isNotNullOrEmpty(apiNumber)) {
      return apiNumber;
    }

    const mask = '00-000-00000-00-00';
    let formattedValue = '';
    let index = 0;
    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0') {
        if (index < apiNumber.length) {
          formattedValue += apiNumber[index];
          index++;
        } else {
          formattedValue += '0';
        }
      } else {
        formattedValue += mask[i];
      }
    }
    return formattedValue;
  }

  public gridInfoHelpText: string = `
    <ul style="list-style: none;">
          <li>Each column in the grid below can be sorted by clicking on the column header.</li> <br/>
          <li>You can filter on any column by first clicking on this icon <img src="../assets/svg/ag-grid-icons/menu.svg" width="29" height="29"> then <img src="../assets/svg/ag-grid-icons/filter.svg" width="29" height="29">  you can then choose and enter filtering rules to search for text, values or dates. <br/> You can add multiple filters on each column for detailed searches in the grid.</li><br/>
          <li>You can add additional columns to the grid by clicking the <img src="../assets/svg/ag-grid-icons/columns.svg" width="29" height="29"> icon and clicking on additional columns.  In addition, you can remove columns that are checked.</li> <br/>
          <li>Grid data can be exported by clicking on the "Export Data" button.</li> <br/>
      </ul>
  `;
}
