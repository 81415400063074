import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { LoggingService } from './logging.service';
import { Status } from "../models/lookup/status.model";
import { PatchOperation } from '../models/patch-operation.model';
import { WaterSource } from '../models/disclosures/water-source.model';
import { Disclosure } from '../models/disclosures/disclosure.model';
import { DisclosureValidationStatement } from '../models/disclosures/disclosure-validation-statement.model';
import { DownloadFilter } from '../models/disclosures/download-filter.model';
import { DashboardDisclosure } from '../models/disclosures/dashboard-disclosure.model';
import { OperatorApprovalDisclosure } from '../models/disclosures/operator-approval-disclosure.model';
import { DisclosureSearch } from '../models/disclosures/disclosure-search.model';
import { DisclosureCountByPublicStatus } from '../models/disclosures/disclosure-count-by-public-status.model';
import { ViewDisclosureSearch } from '../models/disclosures/view-disclosure-search';

@Injectable()
export class DisclosureService {
  private states: any[] = [];
  private counties: any[] = [];
  dStatus: Array<Status> = [];
  constructor(private http: HttpClient, private loggingService: LoggingService) { }

  public getStatuss(): Promise<Array<Status>> {
    return new Promise((resolve, reject) => {
      if (this.dStatus && this.dStatus.length) {
        resolve(this.dStatus);
      } else {
        this.http.get<Status>('/api/Dropdown/AllStatus').subscribe((response: any) => {
          this.dStatus = response;
          resolve(this.dStatus);
        }, (error: any) => {
          this.loggingService.error("Error getting status list", "Error getting status list: " + error);
          reject(error);
        });
      }
    });
  }

  public getMyCompanyOrgDisclosures(orgId: string): Observable<DashboardDisclosure[]> {
    return this.http.get<DashboardDisclosure[]>(`/api/Disclosure/MyOrganizationsDisclosures/${orgId}`);
  }

  public getSubmittedDisclosures(): Observable<any[]> {
    return this.http.get<any[]>('/api/disclosure/SubmittedDisclosures');
  }
  public SubmittedDisclosuresSearch(search: ViewDisclosureSearch): Observable<any[]> {
    return this.http.post<any[]>('/api/disclosure/SubmittedDisclosures', search);
  }

  public searchMyOrganizationsDisclosures(orgId: string, disclosureSearch: DisclosureSearch): Observable<Array<Disclosure>> {
    return this.http.post<Array<Disclosure>>(`/api/Disclosure/SearchMyOrganizationsDisclosures/${orgId}`, disclosureSearch);
  }

  public getDisclosureCountsByOrganizationId(orgId: string): Observable<Array<DisclosureCountByPublicStatus>> {
    return this.http.get<Array<DisclosureCountByPublicStatus>>(`/api/Disclosure/GetDisclosureCountsByOrganizationId/${orgId}`);
  }

  public GetMyDisclosuresForOperatorApprovalsAsync(orgId: string): Observable<OperatorApprovalDisclosure[]> {
    return this.http.get<OperatorApprovalDisclosure[]>(`/api/Disclosure/MyOrganizationsOperatorApprovalDisclosures/${orgId}`);
  }

  public GetMyCompanyDisclosure(orgId: string, disclosureId: string): Observable<any> {
    return this.http.get<any[]>(`/api/Disclosure/GetMyCompanyDisclosure/${orgId}/${disclosureId}`);
  }

  public ValidateMyCompanyDisclosure(orgId: string, disclosureId: string): Observable<DisclosureValidationStatement[]> {
    return this.http.get<DisclosureValidationStatement[]>(`/api/Disclosure/ValidateMyCompanyDisclosure/${orgId}/${disclosureId}`);
  }

  public patchDisclosure(orgId: string, disclosureId: string, disclosurePatches: PatchOperation[]): Observable<Disclosure> {
    return this.http.patch<Disclosure>(`/api/Disclosure/MyDisclosureUpdate/${orgId}/${disclosureId}`, disclosurePatches);
  }

  public postDisclosure(orgId: string, operatorId: string, disclosure: Disclosure): Observable<Disclosure> {
    return this.http.post<Disclosure>(`/api/Disclosure/AddMyDisclosure/${orgId}/${operatorId}`, disclosure);
  }

  public addMyWaterSource(orgId:string, disclosureId:string, ws:any): Observable<any> {
    return this.http.post(`/api/Disclosure/AddMyDisclosureWaterSource/${orgId}/${disclosureId}`, ws);
  }

  public patchMyWaterSource(orgId: string, disclosureId: string, waterSourceId: string, ws: PatchOperation[]): Observable<WaterSource> {
    return this.http.patch<WaterSource>(`/api/Disclosure/MyWaterSourceUpdate/${orgId}/${disclosureId}/${waterSourceId}`, ws);
  }

  public deleteDisclosure(disclosureId: string ): Observable<any> {
    return this.http.delete(`/api/Disclosure/${disclosureId}`);
  }

  public rollBackMyDisclosure(orgId: string, disclosureId: string): Observable<any> {
    return this.http.get<any[]>(`/api/Disclosure/RollbackMyDisclosure/${orgId}/${disclosureId}`);
  }

  public updateMyDisclosureOperator(orgId: string, disclosureId: string, newOperatorOrgId: string): Observable<Disclosure>{
    return this.http.get<Disclosure>(`/api/Disclosure/UpdateMyDisclosureOperator/${orgId}/${disclosureId}/${newOperatorOrgId}`);

  }

  public SetMyDisclosureToAmending(orgId: string, disclosureId: string): Observable<any> {
    return this.http.get<any[]>(`/api/Disclosure/SetMyDisclosureToAmending/${orgId}/${disclosureId}`);
  }
  public SetMyDisclosureToSubmitted(orgId: string, disclosureId: string): Observable<any> {
    return this.http.get<any[]>(`/api/Disclosure/SetMyDisclosureToSubmitted/${orgId}/${disclosureId}`);
  }
  public TransferMyDisclosureToOperator(orgId: string, disclosureId: string, direct: boolean = false): Observable<any> {
    return this.http.get<any[]>(`/api/Disclosure/TransferMyDisclosureToOperator/${orgId}/${disclosureId}?direct=${direct}`);
  }
  public TransferMyDisclosuresToOperator(data: any, direct: boolean = false): Observable<any> {
    return this.http.post<any[]>(`/api/Disclosure/TransferMyDisclosuresToOperator/?direct=${direct}`, data);
  }

  public validateDisclosureApiNumber(jobStartDate: Date, jobEndDate: Date, apiNumber: string): Observable<Array<DisclosureValidationStatement>> {
    return this.http.get<any[]>(`/api/Disclosure/ValidateDisclosureApiNumber/${jobStartDate}/${jobEndDate}/${apiNumber}`);
  }

  public filteredXMLDownloadCount(orgId: string, filter: DownloadFilter): Observable<any> {
    return this.http.post(`/api/Download/DownloadFilteredXMLCount/${orgId}`, filter);
  }
  public filteredXMLDownload(orgId: string, filter: DownloadFilter) {
    return this.http.post(`/api/Download/DownloadFilteredXML/${orgId}`, filter, { observe: 'body', responseType: 'text' });
  }
  public filteredCSVDownload(orgId: string, filter: DownloadFilter) {
    return this.http.post(`/api/Download/DownloadFilteredCSV/${orgId}`, filter, { observe: 'body', responseType: 'text' });
  }
  public filteredCSVDownload2(orgId: string, filter: DownloadFilter) {
    return this.http.post(`/api/Download/DownloadFilteredWaterCSV/${orgId}`, filter, { observe: 'body', responseType: 'text' });
  } 
}
