<div class="footer-container">
  <section name="footer links" class="links">
    <ul class="reset">
      <li><a class="cursor-pointer" (click)="openExternalLink('https://www.fracfocus.org')">www.fracfocus.org</a></li>
      <li class="tooltip-wrapper">
        <a href="https://www.fracfocus.org/wells/advanced" target="_blank">Find a Well</a>
        <app-popper-tool-tip [popperPlacementParam]="popperPlacement" iconColor="WHITE" screenReaderText="Search for a Submitted Disclosure"></app-popper-tool-tip>
      </li>
      <li><a href="https://fracfocus.org/operators/training" target="_blank" class="under-530">Training</a></li>
      <li><a href="https://fracfocus.org/operators/operator-faq" target="_blank" class="under-530">Operator FAQ</a></li>
    </ul>
  </section>
  <section name="footer gcaptcha" class="gcaptcha">
    <ul class="reset">
      <li class="site">This site is protected by reCAPTCHA and the Google </li>
      <li class="site"><a class="cursor-pointer" (click)="openExternalLink('https://policies.google.com/privacy')">Privacy Policy</a> and </li>
      <li class="site"><a class="cursor-pointer" (click)="openExternalLink('https://policies.google.com/terms')">Terms of Service</a> apply.</li>
    </ul>
  </section>
  <section name="footer terms of use" class="terms-of-use">
    <ul class="reset">
      <li class="copyright">&copy; Copyright GWPC, 2023</li>
      <li>
        <a href="/assets/pdf/WEB-SITE-TERMS-AND-CONDITIONS-OF-USE.pdf" target="_blank">Terms of Use</a>
      </li>
    </ul>
  </section>
</div>

<ui-modal id="openpage-modal" (closed)="closeOpenPageModal()" [paddedContent]="true" [hasCloseButton]="true"
  [isVisible]="isOpenPageModalShowing" [modalSize]=" 'MEDIUM' | modalSizeEnumPipe" modalTitle="Open an external link?">
  <div modalContent class="flex-vrt">
    <p class="aln-center">
      The system is about to open an external page, do you wish to continue?
    </p>
    <div class="dis-flex open-page">
      <button uiLibButton [color]="'PRIMARY' | buttonColorEnumPipe" [size]="'MEDIUM' | buttonSizeEnumPipe"
        [shape]="'ROUNDED' | buttonShapeEnumPipe" [style]="'SOLID' | buttonStyleEnumPipe"
        (click)="handleOk()">OK</button>
      <button uiLibButton [color]="'PRIMARY' | buttonColorEnumPipe" [size]="'MEDIUM' | buttonSizeEnumPipe"
        [shape]="'ROUNDED' | buttonShapeEnumPipe" [style]="'SOLID' | buttonStyleEnumPipe"
        (click)="handleCancel()">CANCEL</button>
    </div>
  </div>
</ui-modal>
