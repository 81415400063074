import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { DropdownService } from '../../services/dropdown.service';
import { OpenDeleteCellComponent } from '../../../ag-grid/components/renderers/open-delete-cell/open-delete-cell.component';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Disclosure } from '../../models/disclosures/disclosure.model';
import { MainService } from '../../ui/services/main.service';
import { DisclosureService } from '../../services/disclosure.service';
import { LoggingService } from '../../services/logging.service';
import { OpenRollbackCellComponent } from '../../../ag-grid/components/renderers/open-rollback-cell/open-rollback-cell.component';
import { AmendCellComponent } from '../../../ag-grid/components/renderers/amend-cell/amend-cell.component';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { finalize, first, Subscription } from 'rxjs';
import { FileService } from '../../services/file.service';
import { DownloadXmlCellComponent } from '../../../ag-grid/components/renderers/download-xml-cell/download-xml-cell.component';
import { AGGridHelperService } from '../../../ag-grid/services/ag-grid-helper-service';
import { FFUserWithRoles } from '../../models/users/ff-user-with-roles.model';
import { DateCellRendererComponent } from '../../../ag-grid/components/renderers/date-cell-renderer/date-cell-renderer.component';
import { DisclosureSearch } from '../../models/disclosures/disclosure-search.model';
import { DisclosureCountByPublicStatus } from '../../models/disclosures/disclosure-count-by-public-status.model';
import { ascendingStringComparer, descendingDateComparer } from '../../services/utilities.service';

const FileSaver = require('file-saver');
@Component({
  selector: 'disclosure-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './disclosure-list.component.html',
  styleUrls: ['./disclosure-list.component.scss']
})
export class DisclosureListComponent implements OnInit, OnDestroy {
  public wellApiNum: string = "";
  public wellName: string = "";
  public states: any[];
  public counties: any[];
  public countyOptions:any[] = [];
  public deletedByOperator: any[] = [];
  public totalPendingOG: number = 0;
  public totalSubmittedOG: number = 0;
  public totalAmendingOG: number = 0;
  public totalDeletedOG: number = 0;
  public totalDeletedByOperatorOG: number = 0;
  public totalPendingNonOG: number = 0;
  public totalSubmittedNonOG: number = 0;
  public totalAmendingNonOG: number = 0;
  public totalDeletedNonOG: number = 0;
  public totalDeletedByOperatorNonOG: number = 0;
  public totalAll: number = 0;
  public disclosuresFiltered: Disclosure[] = [];
  public modalVisible = false;
  public modalAmendVisible = false;
  public modalRollbkVisible = false
  private isReadOnly = false;
  public isServiceComp = false;
  private currentOrganizationId: string;
  public selectedDisclosure: Disclosure = null;
  public userInfo: FFUserWithRoles;
  public gridOptions: GridOptions;
  public gridHeight: string = "";
  public gridHelpText: string = "";
  public disclosures: Disclosure[] = [];
  public subscription: Subscription;
  public totalsFetched: boolean = false;
  public allowNonOilAndGasWellTypes: boolean = false;
  public currentView: string = "PendingOG";
  private disclosureCounts: Array<DisclosureCountByPublicStatus> = [];

  @Input() public parentComponentName: string = "";

  public filterForm: UntypedFormGroup = new UntypedFormGroup({
    status: new UntypedFormControl("PendingOG")
  });

  public readonly columnDefs: ColDef[] = [
    /* Default */
    //{
    //  field: 'disclosureId',
    //  headerName: 'Disclosure Id',
    //  filter: "agTextColumnFilter",
    //  cellClass: 'stringType',
    //  editable: false,
    //  width: 110,
    //  suppressColumnsToolPanel: true,
    //  hide: false
    //},
    { 
      field: 'apiNumber', 
      headerName: 'API Number',
      filter: "agTextColumnFilter",
      cellClass: 'stringType',
      editable: false,
      width: 110,
      valueGetter: this.apiValueGetter.bind(this),
      suppressColumnsToolPanel: true,
      lockVisible: true
    },
    {
      colId: 'operatorName',
      field: 'operatorName', 
      headerName: 'Operator Name',
      filter: "agTextColumnFilter",
      editable: false,
      hide: false,
      width: 120,
      cellRenderer: (data: any) => {
        if (data?.data) {
          const disclosure = data.data as Disclosure
          return disclosure.operatorName
        }

        return ''
      }
    },
    {
      headerName: 'Well Name and Number',
      field: 'wellName',
      filter: "agTextColumnFilter",
      resizable: true,
      width: 110
    },
    {
      field: 'jobStartDate',
      headerName: 'Job Start Date',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      headerTooltip:'Start date filter of the range of jobs performed',
      width: 100,
      editable: false,
      cellRenderer: DateCellRendererComponent
    },
    {
      field: 'jobEndDate',
      headerName: 'Job End Date',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      headerTooltip:'End date filter of the range of jobs performed',
      editable: false,
      width: 120,
      cellRenderer: DateCellRendererComponent
    },
    {
      field: 'lastModifiedDate',
      headerName: 'Last Edit',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      editable: false,
      width: 100,
      cellRenderer: DateCellRendererComponent
    },
    /* Optional */
    {
      field: 'longitude',
      headerName: 'Longitude',
      filter: "agNumberColumnFilter",
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.longitude
        }

        return ''
      },
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      filter: "agNumberColumnFilter",
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.latitude
        }
        
        return ''
      },
    },
    {
      field: 'projection',
      headerName: 'Datum',
      filter: "agSetColumnFilter",
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.projection
        }

        return ''
      },
    },
    {
      field: 'tvd',
      headerName: 'TVD',
      filter:'agNumberColumnFilter',
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.tvd
        }

        return ''
      },
    },
    {
      field: 'isFederalWell',
      headerName: 'Federal Well',
      filter: 'agSetColumnFilter',
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.isFederalWell
        }

        return ''
      },
    },
    {
      field: 'isIndianWell',
      headerName: 'Indian Well',
      filter: 'agSetColumnFilter',
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.isIndianWell
        }

        return ''
      },
    },
    {
      field: 'lastModifiedBy',
      headerName: 'Last Editor',
      filter:'agTextColumnFilter',
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.lastModifiedBy
        }

        return ''
      },
    },
    {
      field: 'totalBaseWaterVolume',
      headerName: 'Total Water',
      filter:'agNumberColumnFilter',
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.totalBaseWaterVolume
        }

        return ''
      },
    },
    {
      field: 'entryMethod',
      headerName: 'Entry Method',
      filter: 'agSetColumnFilter',
      editable: false,
      hide: true,
      width: 100,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.entryMethod
        }

        return ''
      },
    },
    {
      field: 'createdDate',
      headerName: 'Create Date',
      editable: false,
      hide: true,
      width: 100,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      cellRenderer: DateCellRendererComponent
    },
    {
      field: 'serviceCompanyName',
      colId: 'serviceCompanyName',
      headerName: 'Service Company Name',
      filter:'agTextColumnFilter',
      editable: false,
      hide: true,
      width: 120,
      cellRenderer: (data: any) => {
        if(data?.data){
          const disclosure = data.data as Disclosure
          return disclosure.serviceCompanyName
        }
        
        return '' 
      }
    },   
  ];
  
  public filteredColDefs: ColDef[] = []

  public editMode = false;

  public buildGridOptions() {

    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
      },
      excelStyles:this.agGridHelperService.getDefaultExcelStyles(),
      pagination: true,
      editType: 'fullRow',
      accentedSort: true,
      rowHeight: 40,
      suppressContextMenu: true,
      suppressClickEdit: true,
      suppressMenuHide: true,
      rowClassRules: {
        'disabled': (params: any) => {
          return this.editMode === true && params.data.disabled === true
        }
      }
    } as GridOptions;
  }

  public frameworkComponents: any = {
    OpenDeleteCellComponent: OpenDeleteCellComponent,
    OpenRollbackCellComponent: OpenRollbackCellComponent,
    OpenCellComponent: AmendCellComponent,
    DownloadXmlCellComponent: DownloadXmlCellComponent
  };

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public gridApi: any;
  possibleOrgsToAdd: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dropdownService: DropdownService,
    private mainService: MainService,
    private disclosureService: DisclosureService,
    private loggingService: LoggingService,
    private router: Router,
    private authService: AuthService,
    private fileService: FileService,
    private agGridHelperService: AGGridHelperService
  ) {
  }

  ngOnInit(): void {
    this.mainService.showLoading({ loading: true, loadingText: "Loading disclosures..." });
  this.subscription = new Subscription();
    this.gridHelpText = this.agGridHelperService.gridInfoHelpText;
    this.buildGridOptions();
    this.authService.getUserInfo()
    .then(user => {
      this.userInfo = user;
      if (user.currentOrganizationId !== null && user.currentOrganizationId !== undefined) {
      }
      this.currentOrganizationId = user.currentOrganizationId;
      this.isReadOnly = !user.hasOrgRole('DataSubmitter') && !user.isAdmin;
      let currentOrg = user.getCurrentOrg();
      this.isServiceComp = (currentOrg) ? currentOrg.isServiceComp : false;
      this.getDisclosuresByPublicStatus("PendingOG");
      this.setCollectionsAndTotals();    
      this.filteredColDefs = this.getColumnDefs("PendingOG");
      if (currentOrg.organizationDefaults.find(x => x.defaultCode == "AllowNonOilGas").booleanValue) {
        this.allowNonOilAndGasWellTypes = true;
      }
    });
    
    this.dropdownService.getCounties().then(ctys => this.counties = ctys);
    this.dropdownService.getStates().then(states => this.states = states);

  }

  public onGridReady(params: GridReadyEvent) {

    this.gridApi = params.api;
    this.gridApi.onActionTrigger = (event: any) => {
      this.triggerAction(event);
    };
    
    this.gridApi.setColumnDefs(this.filteredColDefs);
    this.gridApi.sizeColumnsToFit();

    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    }
  }

  private triggerAction(event: any) {
    switch(event.action){
      case "delete":
        this.modalVisible = true;
        this.selectedDisclosure = event.data as Disclosure;
        break;

      case "startAmend":
        this.modalAmendVisible = true;
        this.selectedDisclosure = event.data as Disclosure;
        break;

      case "rollback":
        this.modalRollbkVisible = true;
        this.selectedDisclosure = event.data as Disclosure;
        break;

      case "viewPdf":
        this.viewPdf(event.data);
        break;

      case "viewXml":
        this.viewXml(event.data);
        break;
    }
  }

  statusFormatter(params: any) {
    return params.value;
  }

  public formUpdated($event: any) {
    let value = $event.target.value;
    this.getDisclosuresByPublicStatus(value);    
  }

  private getDisclosuresByPublicStatus(publicStatus: string): void {
    this.currentView = publicStatus;
    if (this.userInfo) {
      this.mainService.showLoading({ loading: true, loadingText: "Loading disclosures..." });
      const primaryOrg = this.userInfo.getCurrentOrg();
      let disclosureSearch: DisclosureSearch = new DisclosureSearch();

      switch (publicStatus) {
        case "PendingOG":
        case "SubmittedOG":
        case "AmendingOG":
        case "DeletedOG":
        case "DeletedByOperatorOG": {
          disclosureSearch.publicStatus = publicStatus.substring(0, publicStatus.length - 2)
          disclosureSearch.oilAndGas = true;
          break;
        }
        case "PendingNonOG":
        case "SubmittedNonOG":
        case "AmendingNonOG":
        case "DeletedNonOG":
        case "DeletedByOperatorNonOG": {
          disclosureSearch.publicStatus = publicStatus.substring(0, publicStatus.length - 5)
          disclosureSearch.oilAndGas = false;
          break;
        }
        case "ALL":
          //IF they can use non oil and gas types, set the bool to null to bring back everything, else set it to true.
          disclosureSearch.publicStatus = publicStatus;
          disclosureSearch.oilAndGas = this.allowNonOilAndGasWellTypes ? null : true;
          break;

        default: {
          disclosureSearch.publicStatus = publicStatus;
          break;
        }
      }

      let srv$ = this.disclosureService.searchMyOrganizationsDisclosures(primaryOrg.organizationId, disclosureSearch).pipe(finalize(() => {
        //If we haven't finished fetching totals, when that finishes it'll turn off the loader
        if (this.totalsFetched)
          this.mainService.showLoading({ loading: false });
        this.subscription.add(srv$);
      })).subscribe({
        next: (matchingDisclosures) => {

          this.disclosuresFiltered = matchingDisclosures;

          switch (publicStatus) {
            case "AmendingOG":
            case "AmendingNonOG":
            case "PendingOG":
            case "PendingNonOG": {
              this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return descendingDateComparer(a.lastModifiedDate, b.lastModifiedDate); });
              break;
            }
            case "SubmittedNonOG": {
              //This is the condition for if it's the Routed To Operator (Is a service comp)
              if (this.isServiceComp) {
                this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return descendingDateComparer(a.lastModifiedDate, b.lastModifiedDate); });
              }
              else {
                this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return ascendingStringComparer(a.permitId, b.permitId); });
              }
              break;
            }
            case "DeletedNonOG": {
                this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return ascendingStringComparer(a.permitId, b.permitId); });
              break;
            }
            case "ALL":
            case "DeletedOG": {
              this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return ascendingStringComparer(a.apiNumber, b.apiNumber); });
              break;
            }
            case "SubmittedOG": {//This is the condition for if it's the Routed To Operator (Is a service comp)
              if (this.isServiceComp) {
                this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return descendingDateComparer(a.lastModifiedDate, b.lastModifiedDate); });
              }
              else {
                this.disclosuresFiltered = this.disclosuresFiltered.sort((a, b) => { return ascendingStringComparer(a.apiNumber, b.apiNumber); });
              }
              break;
            }
          }

          this.filteredColDefs = this.getColumnDefs(publicStatus);
          if (this.parentComponentName === "manage") {
            //grid height calc for Manage Disclosures
            this.gridHeight = this.agGridHelperService.calculateMinMaxGridHeight(this.disclosuresFiltered.length, 0, 11, 100);
          } else {
            //grid height calc for Dashboard
            this.gridHeight = this.agGridHelperService.calculateMinMaxGridHeight(this.disclosuresFiltered.length, 0, 3, 10);
          }
          this.changeDetectorRef.detectChanges(); //Called because grid was not resizing properly with modified filteredColDefs
          this.gridApi.setColumnDefs(this.filteredColDefs);
          this.gridApi.sizeColumnsToFit();
        },
        error: error => {
          console.log('Error listening for auth service user info. ' + error);
        }
      });
    }
  }

  private addNonOGColumns(defs: ColDef[], firstColumnIsPermitId: boolean = true): ColDef[] {
    let wellTypeColDef =
    {
      headerName: 'Well Type',
      field: 'wellTypeDescription',
      filter: "agSetColumnFilter",
      resizable: true,
      hide: true,
      minWidth: 120
      } as ColDef;

    let permitIdColDef =
      {
        headerName: 'Permit Id',
        field: 'permitId',
        filter: "agTextColumnFilter",
        hide: !firstColumnIsPermitId,
        resizable: true,
        minWidth: 120
      } as ColDef;

    let countyIdColDef =
      {
        headerName: 'County Id',
        field: 'countyId',
        filter: "agTextColumnFilter",
        hide: true,
        resizable: true,
        minWidth: 120
      } as ColDef;

    let countyNameColDef =
      {
        headerName: 'County Name',
        field: 'countyName',
        filter: "agTextColumnFilter",
        hide: true,
        resizable: true,
        minWidth: 120
      } as ColDef;

    let stateColDef =
      {
        headerName: 'State',
        field: 'state',
        filter: "agTextColumnFilter",
        hide: true,
        resizable: true,
        minWidth: 120
      } as ColDef;

    if (firstColumnIsPermitId)
      defs.unshift(permitIdColDef);
    else
      defs.push(permitIdColDef);

    defs.push(wellTypeColDef);
    defs.push(countyIdColDef);
    defs.push(countyNameColDef);
    defs.push(stateColDef);

    return defs;
  }

  private getColumnDefs(view: string): ColDef[]{
    const primaryOrg = this.userInfo.getCurrentOrg();
    let isServiceComp = false;
    let isOperator = false;
    let isRegisteredAgent = false;
    if (primaryOrg) {
      isServiceComp = primaryOrg.isServiceComp;
      isOperator = primaryOrg.isOperator;
      isRegisteredAgent = primaryOrg.isRegisteredAgent;
    }

    let isNonOGView = false;
    if (view.includes("NonOG") || (this.allowNonOilAndGasWellTypes && view == "ALL")) {
      isNonOGView = true;
      if (view.includes("NonOG")) {
        view = view.substring(0, view.length - 5);
      }
    }
    else if (view.includes("OG")) {
      view = view.substring(0, view.length - 2);
    }

    let defs: ColDef[] = [...this.columnDefs];

    let amendReasonCol = {
      field: 'amendedReason',
      colId: 'amendReason',
      headerName: 'Amend Reason',
      filter:'agTextColumnFilter',
      editable: false,
      hide: true,
      width: 120,
      cellRenderer: (data: any) => {
        if (data?.data) {
          const disclosure = data.data as Disclosure
          return disclosure.amendedReason
        }

        return ''
      }
    } as ColDef;
    
    let publicStatus = {
      field: 'publicStatus',
      colId: 'publicStatus',
      headerName: 'Status',
      filter:'agSetColumnFilter',
      editable: false,
      hide: true,
      width: 120,
    } as ColDef;

    let ffVersionCol = {
      field: 'ffVersion',
      colId: 'ffVersion',
      headerName: 'FFVersion',
      filter:'agNumberColumnFilter',
      editable: false,
      hide: true,
      width: 120,
      cellRenderer: (data: any) => {
        if (data?.data) {
          const disclosure = data.data as Disclosure
          return disclosure.ffVersion
        }

        return ''
      }
    } as ColDef;

    let submitDate = {
      field: 'submitTimeStamp',
      colId: 'submitTimeStamp',
      headerName: 'Submit Date',
      editable: false,
      hide: false,
      width: 120,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      cellRenderer: DateCellRendererComponent,
    }

    let statusCol = {
      width: 90,
      field: 'publicStatus',
      headerName: "Status",
      filter: 'agSetColumnFilter',
      valueFormatter: this.statusFormatter,
    } as ColDef;

    let routedByCol = {
      field: 'routedBy',
      colId: 'routedBy',
      headerName: 'Routed By',
      filter:'agTextColumnFilter',
      editable: false,
      hide: false,
      width: 120,
      cellRenderer: (data: any) => {
        if (data?.data) {
          const disclosure: Disclosure = data.data;
          return disclosure.routedBy;
        }

        return '';
      }
    } as ColDef;

    let uploadedDirectly = {
      field: 'uploadedDirectly',
      colId: 'uploadedDirectly',
      headerName: 'Direct Upload',
      filter: 'agSetColumnFilter',
      editable: false,
      hide: false,
      width: 120,
      cellRenderer: (data: any) => {
        if (data?.data) {
          const disclosure: Disclosure = data.data;
          return disclosure.uploadedDirectly ? 'Yes' : 'No';
        }

        return '';
      }
    } as ColDef;

    let origSubmitDate = {
      field: 'originalSubmitTimeStamp',
      colId: 'originalSubmitTimeStamp',
      headerName: 'Original Submit Date',
      editable: false,
      hide: true,
      width: 100,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      cellRenderer: DateCellRendererComponent,
    } as ColDef;

    let submittedByCol = {
      field: 'submittedBy',
      colId: 'submitUser',
      headerName: 'Submitted By',
      filter:'agTextColumnFilter',
      editable: false,
      hide: false,
      width: 120,
      cellRenderer: (data: any) => {
        if (data?.data && data.data.submittedBy) {
          const disclosure: Disclosure = data.data;
          return disclosure.submittedBy;
        }

        return ''
      }
    } as ColDef;

    switch(view){
      case "Pending":
        if(!this.isReadOnly){
          defs.push({
            colId: 'actions',
            cellRenderer: OpenDeleteCellComponent,
            suppressColumnsToolPanel: true,
            suppressMovable: true,
            width: 90,
            hide: false
          })
        }
        if(!this.isReadOnly && isServiceComp){
          defs.push(
            {
              colId: 'actions',
              cellRenderer: DownloadXmlCellComponent,
              suppressColumnsToolPanel: true,
              suppressMovable: true,
              width: 50,
              hide: false
            }
          );
        }
        break;

      case "Submitted":
        if(isServiceComp){
          defs.push(
            statusCol,
            routedByCol,
            uploadedDirectly,
          );

          if(!this.isReadOnly){
            defs.push(
              {
                colId: 'actions',
                cellRenderer: DownloadXmlCellComponent,
                suppressColumnsToolPanel: true,
                suppressMovable: true,
                width: 50,
                hide: false
              }
            );
          }
        }
        else{
          defs.push(
            submitDate,
            submittedByCol,
            origSubmitDate,
            amendReasonCol,
            publicStatus,
            ffVersionCol,
          );
       
          if(!this.isReadOnly){
            defs.push({
              colId: 'actions',
              cellRenderer: AmendCellComponent,
              suppressColumnsToolPanel: true,
              suppressMovable: true,
              width: 100,
              hide: false
            })
          }
        }

        break;
      
      case "Amending":
        defs.push(
          submitDate,
          submittedByCol,
          origSubmitDate,
          {
            field: 'lastModifiedBy',
            colId: 'lastModifiedBy',
            headerName: 'Amended By',
            filter:'agTextColumnFilter',
            editable: false,
            hide: false,
            width: 120,
            cellRenderer: (data: any) => {
              if(data?.data){
                const disclosure: Disclosure = data.data
                return disclosure.lastModifiedBy
              }
      
              return ''
            }
          },
          amendReasonCol,
          publicStatus,
          ffVersionCol,
        );

        if(!this.isReadOnly){
          defs.push({
            colId: 'actions',
            cellRenderer: OpenRollbackCellComponent,
            suppressColumnsToolPanel: true,
            suppressMovable: true,
            width: 90,
            hide: false
          });
        }

        break;
      
      case "ALL":
        if(isRegisteredAgent){
          defs.push(
            {
              field: 'lastModifiedBy',
              colId: 'lastModifiedBy',
              headerName: 'Last Modified By',
              filter:'agTextColumnFilter',
              editable: false,
              hide: false,
              width: 120,
              cellRenderer: (data: any) => {
                if(data?.data){
                  const disclosure: Disclosure = data.data
                  return disclosure.lastModifiedBy
                }
        
                return ''
              }
            },
            statusCol,
          );
        }
        if(isServiceComp){
          defs.push(
            statusCol,
            routedByCol,
          );
        }
        else{
          defs.push(
            submitDate,
            submittedByCol,
            origSubmitDate,
            amendReasonCol,
            publicStatus,
            ffVersionCol,
          );
        }
        break
      
      case "Deleted":
      case "DeletedByOperator":
        if(isServiceComp){
          defs.push(
            routedByCol,
            uploadedDirectly,
            {
              field: 'deleteDate',
              headerName: 'Delete Date',
              filter: 'agDateColumnFilter',
              filterParams: {
                buttons: ['reset'],
                dateFormat: 'mm/dd/yyyy',
                comparator: this.agGridHelperService.dateFilterComparer
              },
              editable: false,
              width: 120,
              cellRenderer: DateCellRendererComponent,
            },
          )
        }

        break;
    }
    
    if(isOperator){
      defs = defs.filter(d => d.colId !== 'operatorName');
    }

    if(isServiceComp){
      defs = defs.filter(d => d.colId !== 'serviceCompanyName');
    }

    if (isNonOGView) {
      defs = this.addNonOGColumns(defs, true);
    }

    //Check if there is an action column, if so pull it and add it to the end.
    let defsActionColumns = defs.filter(x => x.colId == 'actions');
    if (defsActionColumns != null && defsActionColumns.length > 0) {
      defs = defs.filter(x => x.colId != 'actions');
      defs.push(defsActionColumns[0]);
    }

    return defs;
  }

  setCollectionsAndTotals(): void {  
    if (this.userInfo) {
      const primaryOrg = this.userInfo.getCurrentOrg();
      let srv$ = this.disclosureService.getDisclosureCountsByOrganizationId(primaryOrg.organizationId).pipe(finalize(() => {
        this.totalsFetched = true;
        this.mainService.showLoading({ loading: false });
        this.subscription.add(srv$);
      })).subscribe({
        next: (disclosureCounts) => {
          this.extractViewTotals(disclosureCounts);
        },
        error: error => {
          console.log('Error listening for auth service user info. ' + error);
        }
      });
    }
  }

  extractViewTotals(disclosureCounts: Array<DisclosureCountByPublicStatus>): void {
    this.disclosureCounts = disclosureCounts;
    this.totalPendingOG = disclosureCounts.filter(x => x.publicStatus == "PendingOG")[0].disclosureCount;
    this.totalSubmittedOG = disclosureCounts.filter(x => x.publicStatus == "SubmittedOG")[0].disclosureCount;
    this.totalAmendingOG = disclosureCounts.filter(x => x.publicStatus == "AmendingOG")[0].disclosureCount;
    this.totalDeletedOG = disclosureCounts.filter(x => x.publicStatus == "DeletedOG")[0].disclosureCount;
    this.totalDeletedByOperatorOG = disclosureCounts.filter(x => x.publicStatus == "DeletedByOperatorOG")[0].disclosureCount;
    this.totalPendingNonOG = disclosureCounts.filter(x => x.publicStatus == "PendingNonOG")[0].disclosureCount;
    this.totalSubmittedNonOG = disclosureCounts.filter(x => x.publicStatus == "SubmittedNonOG")[0].disclosureCount;
    this.totalAmendingNonOG = disclosureCounts.filter(x => x.publicStatus == "AmendingNonOG")[0].disclosureCount;
    this.totalDeletedNonOG = disclosureCounts.filter(x => x.publicStatus == "DeletedNonOG")[0].disclosureCount;
    this.totalDeletedByOperatorNonOG = disclosureCounts.filter(x => x.publicStatus == "DeletedByOperatorNonOG")[0].disclosureCount;
    this.totalAll = disclosureCounts.filter(x => x.publicStatus == "ALL")[0].disclosureCount;
    this.changeDetectorRef.detectChanges();
  }

  cancelDelete() {
    this.modalVisible = false;
  }
  confirmDelete() {
    this.mainService.showLoading({ loading: true, loadingText: "Deleting the disclosure..." });
    this.modalVisible = false;
    let srv$ = this.disclosureService.deleteDisclosure(this.selectedDisclosure.disclosureId).pipe(finalize(() => {
      this.mainService.showLoading({ loading: false });
      this.subscription.add(srv$);
    })).subscribe({
      next: () => {
        this.loggingService.success("Disclosure was deleted");
        this.removeAlteredDisclosureFromCurrentListAndRefetchCounts(this.selectedDisclosure.disclosureId);
      },
      error: (e) => {
        this.loggingService.error("Error deleting disclosure");
      }
    });
  }

  modalClosed(e: any) {
    this.cancelDelete();
  }

  cancelAmend() {
    this.modalAmendVisible = false;
  }

  confirmAmend() {
    this.mainService.showLoading({ loading: true, loadingText: "Amending the disclosure..." });
    this.modalAmendVisible = false;

    const { currentOrganizationId } = this.userInfo;

    let srv$ = this.disclosureService.SetMyDisclosureToAmending(currentOrganizationId, this.selectedDisclosure.disclosureId).pipe(finalize(() => {
      //If we haven't finished fetching totals, when that finishes it'll turn off the loader
      this.mainService.showLoading({ loading: false });
      this.subscription.add(srv$);
    })).subscribe({
      next: res => {
        this.router.navigate(['/disclosure/edit-disclosure/', res.disclosureId]);
      },
      error: err => {
        this.loggingService.error("Error setting disclosure to amending");
      }
    });
  }

  modalAmendClosed(e: any) {
    this.cancelAmend();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  confirmRollback(){
    this.modalRollbkVisible = false
    
    this.authService.getUserInfo()
    .then(user => { 
     
      this.disclosureService.rollBackMyDisclosure(user.currentOrganizationId, this.selectedDisclosure.disclosureId)
      .pipe( first() )
      .subscribe( 
        {
          next: () => {
            this.loggingService.success('Disclosure Rollback successful');
            this.removeAlteredDisclosureFromCurrentListAndRefetchCounts(this.selectedDisclosure.disclosureId);
          },
          error: () => this.loggingService.error('Error rolling back disclosure')
        }
      )
    })
    .finally( () => this.mainService.showLoading({ loading: false }) )
  }

  cancelRollback(){
    this.modalRollbkVisible = false
  }

  apiValueGetter(params: ValueGetterParams<Disclosure>) {
    let formattedValue = this.agGridHelperService.formatAPINumber(params.data.apiNumber);
    return formattedValue;
  }

  viewXml(disclosure: Disclosure): void{
    this.fileService.generateDisclosureXml(this.currentOrganizationId, disclosure.disclosureId)
    .pipe( first() )
    .subscribe({
      next: xml => {
        FileSaver.saveAs(xml, (disclosure.wellName ? disclosure.wellName : "Disclosure") + '.xml');
      },
      error: e => {
        this.loggingService.error("Error getting disclosure Xml", "Error getting disclosure Xml " + e);
      }
    });
  }

  viewPdf(disclosure: Disclosure): void {
    const matched = this.disclosuresFiltered.find(x => x.disclosureId === disclosure.disclosureId);
    if (matched) {
      window.open(`/api/file/GetDisclosurePdfByName/${disclosure.disclosureId}/${encodeURIComponent(matched.pdfName)}`, "_blank");
    }
  }

  public exportToCSV()
  {
    this.agGridHelperService.exportDataToExcel("Disclosures", "sheet1", this.gridOptions, false);
  }

  public removeAlteredDisclosureFromCurrentListAndRefetchCounts(disclosureId: string): void {
    //Once altered, remove from filtered list, refetch counts
    this.disclosuresFiltered = this.disclosuresFiltered.filter(x => x.disclosureId !== disclosureId);
    this.setCollectionsAndTotals();
  }
}
