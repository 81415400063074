import { Injectable } from '@angular/core';
import { Organization } from '../models/organizations/organization.model';
import { OrgType, PermittedRoles } from '../models/perimitted-roles.model';
import { FFUserWithRoles } from '../models/users/ff-user-with-roles.model';
import { OrgRoleType } from '../models/users/org-role-type.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor() { }

  public canUserAccess(user: FFUserWithRoles, requiredRoles: PermittedRoles[], blockedRoles: PermittedRoles[] = []): boolean{
    
    //Find the permission with the users primary organization  
    let matchingRoles = this.getMatchingRoles(user, requiredRoles);

    let matchingBlockedRoles = this.getMatchingRoles(user, blockedRoles);

    //Check if they have any of the required roles
    const isPermitted = matchingRoles.some(role => user.hasOrgRole(role.toString())) && !matchingBlockedRoles.some(role => user.hasOrgRole(role.toString()));
    return isPermitted;
  }

  private getMatchingRoles(user: FFUserWithRoles, roles: PermittedRoles[]): Array<OrgRoleType> {
    return roles.filter(p => this.userHasOrgType(p.orgType, user.getCurrentOrg()))
      .map(r => r.roles)
      .flat();
  }

  private userHasOrgType(orgType: OrgType, primaryOrg: Organization): boolean{

    if (primaryOrg) {
      switch (orgType) {
        case 'RegisteredAgent':
          return primaryOrg.isRegisteredAgent;
        case 'Service':
          return primaryOrg.isServiceComp;
        case 'Operator':
          return primaryOrg.isOperator;
      }
    }
    else {
      return false;
    }
  }
}
