export class DisclosureSearch {
  public organizationId?: string;
  public stateAbbr?: string;
  public countyId?: string;
  public APINumber?: string;
  public wellName?: string;
  public dateToBeSearched?: string;
  public publicStatus?: string;
  public startDate?: Date;
  public endDate?: Date;
  public userId?: string;
  public oilAndGas?: boolean;
}
