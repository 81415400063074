import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../common/services/auth.service';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuardRole implements CanActivate {
  constructor(private authorize: AuthService, private router: Router) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let roles = _next.data["roles"] as Array<string>;
    return new Promise<boolean>((resolve, reject) => {
      this.authorize.getUserInfo().then(user => {
        let hasRole = false;
        // Checking if userMappings has the requested roles
        if (user && user.roles) {
          hasRole = user.roles.some(role => roles.includes(role));
        }

        if (this.authorize.authenticated && hasRole) {
          resolve(true);
        } else {
          if (this.authorize.authenticated) {
            // navigate to dashboard
            this.router.navigateByUrl('/dashboard');
          } else {
            this.router.navigate(ApplicationPaths.LoginPathComponents, {
              queryParams: {
                [QueryParameterNames.ReturnUrl]: state.url
              }
            });
            reject(false);
          }
        }
      });
    })
      .catch(error => {
        this.router.navigate(ApplicationPaths.LoginPathComponents, {
          queryParams: {
            [QueryParameterNames.ReturnUrl]: state.url
          }
        });
        return error;
      });
  }
}
