import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ColumnResizedEvent, GridApi } from 'ag-grid-community';
import {  Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { INumericCellEditorParams } from '../../../models/numeric-cell-editor-params.model';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { isNotNullOrEmpty } from '../../../../common/functions/is-not-null-or-empty.function';
import { OnLeavingEditorParams } from '../../../models/base-cell-editor/on-leaving-editor-params.model';

@Component({
  selector: 'numeric-cell-editor',
  templateUrl: './numeric-cell-editor.component.html',
  styleUrls: ['./numeric-cell-editor.component.scss']
})
export class NumericCellEditorComponent implements ICellEditorAngularComp, OnDestroy {
  private params: any;
  public value: number;
  public cellWidth: string = "";
  public cellHeight: string = "";
  public isRequired: boolean = true;
  public isValid: boolean = true;
  public min: number = null;
  public max: number = null;
  private subscription: Subscription = new Subscription();
  public originalValue: number;
  private readonly currentValue = new Subject<number | undefined>();

  @ViewChild('numericInput') input: ElementRef;

  public agInit(params: INumericCellEditorParams): void {
    this.params = params;
    this.min = params.minValue;
    this.max = params.maxValue;

    this.setInitialState(this.params);

    if (this.params.dataChangedEvent) {
      this.subscription.add(this.currentValue
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe((currentValue) => {
          this.params.dataChangedEvent.emit({ value: currentValue, field: this.params.colDef.field, itemId: this.params.node.id });
          this.checkValidity(currentValue);
        }));
    }

    if (params.isRequired != null && params.isRequired != undefined) {
      this.isRequired = params.isRequired;
      this.enforceRangeAndBroadcast({ target: { value: params.value } });
      this.checkValidity(params.value);
    }
    else
      this.isRequired = false;

    (this.params.api as GridApi).addEventListener("columnResized",
      (event: ColumnResizedEvent) => {
        if ((event.column && event.column.getId() == this.params.colDef.colId) || event.column === null || this.params.colDef.colId === undefined)
          this.cellWidth = (params.column.getActualWidth()) - 2 + 'px';
      }
    );

    if (params.isValid != null && params.isValid != undefined) {
      if (params.isValid instanceof Function) {
        if (params.initialDisclosureValidationStatements && params.initialDisclosureValidationStatements.length > 0)
          this.isValid = params.isValid(params.initialDisclosureValidationStatements, this.params.fieldName, this.params.idType, this.params.node.id);
      }
      else
        this.isValid = params.isValid;
    }
    else if (!this.isRequired) {
      this.isValid = true;
    }

    this.cellWidth = (params.column.getActualWidth()) - 23 + 'px';
    this.cellHeight = (params.node.rowHeight) - 2 + 'px';
  }

  private checkValidity(currentValue: number): void {
    this.isValid = (this.isRequired && !isNotNullOrEmpty(currentValue)) ? false : true;
  }

  public ngOnDestroy() {
    this.focusOut();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  setInitialState(params: INumericCellEditorParams) {
    this.value = params.value;
    if (params.value != undefined && params.value != null) {
      this.originalValue = JSON.parse(JSON.stringify(params.value));
    }
  }

  public setValidityStatus(isValid: boolean) {
    this.isValid = isValid;
  }

  getValue(): any {
    return this.value;
  }

  focusOut(): void {
    //On focus out, check if the value has changed, if so patch it
    if (this.originalValue != this.value && this.params.onLeavingEditor && !this.params.node.isRowPinned()) {
      this.params.onLeavingEditor({
        data: this.params.data,
        patchInfo: [{
          fieldToPatch: "/" + this.params.colDef.field,
          valueToPatch: this.value
        }],
      } as OnLeavingEditorParams);

      this.params.node.data[this.params.colDef.field] = JSON.parse(JSON.stringify(this.value));
      this.originalValue = JSON.parse(JSON.stringify(this.value));
    }
  };

  focusIn(): void {
    if (this.input.nativeElement) {
      let input = this.input.nativeElement as HTMLInputElement;
      //If the input is disabled, just move on to next cell
      if (input.disabled) {
        input.disabled = false;
        input.focus();
        input.disabled = true;
      }
      else {
        this.input.nativeElement.focus();
      }
    }
  };

  public onChange(event: any): void {
    this.enforceRangeAndBroadcast(event);
  }

  public onKeyDown(event: any): void {
    if (event.key == "Escape") {
      this.focusOut();
    }
  }

  public onKeyUp(event: any): void {
    this.enforceRangeAndBroadcast(event);
  }

  private enforceRangeAndBroadcast(event: any): void {
    if (!Number.isNaN(event.target.valueAsNumber)) {
      let numericValue = isNotNullOrEmpty(event.target.value) && event.target.value != NaN ? Number(event.target.value) : event.target.value;
      if (numericValue < this.min)
        numericValue = this.min;
      else if (numericValue > this.max)
        numericValue = this.max;

      this.value = numericValue;
      this.currentValue.next(numericValue);
    }
  }
}
