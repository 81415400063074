import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './common/components/dashboard/dashboard.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { HomeComponent } from './home/home.component';
import { SystemRoles } from './common/models/system-roles-enum';
import { AboutComponent } from './about/about.component';
import { SwitchOrganizationComponent } from './common/components/switch-organization/switch-organization.component';
import { BreadCrumb } from './common/ui/models/bread-crumb.model';
import { AuthorizeGuard } from './api-authorization/guards/authorize.guard';
import { AuthorizeGuardRole } from './api-authorization/guards/authorize-by-role.guard';

const routes: Routes = [

  {
    path: 'administration',
    canActivate: [AuthorizeGuardRole],
    data: {
      roles: [SystemRoles.Administrator],
      breadcrumb: { label: 'Administration', clickable: false } as BreadCrumb
    },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'organization',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
  },
  {
    path: 'user',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: 'disclosure',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./disclosure/disclosure.module').then(m => m.DisclosureModule),
  },
  {
    path: 'organization-reports',
    canActivate: [AuthorizeGuard],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'authentication',
    data: {
      action: "authentication",
    },
    loadChildren: () => import('./api-authorization/api-authorization.module').then(m => m.ApiAuthorizationModule),
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizeGuard], data: { action: "dashboard" } },
  { path: 'switch-organization', component: SwitchOrganizationComponent, canActivate: [AuthorizeGuard], data: { action: "switchOrganization" } },
  { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthorizeGuardRole], data: { roles: [SystemRoles.Administrator], breadcrumb: 'Fetch Data Test' } },
  { path: 'about', component: AboutComponent },
  { path: '', component: HomeComponent },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];




@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
