import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IRadioButtonCellEditorParams } from '../../../models/radio-button-cell-editor-params.model';
import { IRadioButtonCellRendererParams } from '../../../models/radio-button-cell-renderer-params.model';

@Component({
  selector: 'radio-button-cell-renderer',
  templateUrl: './radio-button-cell-renderer.component.html',
  styleUrls: ['./radio-button-cell-renderer.component.scss']
})
export class RadioButtonCellRendererComponent implements ICellRendererAngularComp {
  public cellWidth: string = "";
  public cellHeight: string = "";
  public value: string = "";
  public name: string = "";
  public id: string = "";
  public isSelected: boolean = false;
  private params: IRadioButtonCellRendererParams;
  constructor() {
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  public agInit(params: IRadioButtonCellRendererParams): void {
    this.params = params;
    this.name = params.name;
    this.id = params.id(this.params);
    this.isSelected = params.isSelected(this.params);
  }

  public ngAfterViewInit() {
  }
}
