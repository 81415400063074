import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { filter, finalize, Subscription } from 'rxjs';
import { LoggingService } from '../../../services/logging.service';
import { BreadCrumb } from '../../models/bread-crumb.model';
import { MainNavService } from '../../services/main-nav.service';

@Component({
  selector: 'ui-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public breadCrumbs: BreadCrumb[] = [];
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  public mostRecentlyFoundCrumb: BreadCrumb = null;

  @ViewChild('afterNavMenu') afterNavAnchor!: ElementRef;
  constructor(
    private _mainNavService: MainNavService,
    private router: Router,
    private _loggingService: LoggingService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const mainNavSubscription$ = this._mainNavService.setFocusOutsideNav.pipe(finalize(() => {
      this.subscription.add(mainNavSubscription$);
    })).subscribe((before: boolean) => {
      if (!before) {
        this.afterNavAnchor.nativeElement.focus();
      }
    }, error => {
      console.log("Failed to set focus outside of nav.");
    });

    const routerNavigationSubscription$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        //Initialize the bread crumbs
        this.breadCrumbs = [{ label: "Home", clickable: true, url: "" } as BreadCrumb]
        //build everything past home off of the route
        this.createBreadcrumbs(this.activatedRoute.root);
        this.subscription.add(routerNavigationSubscription$);
    }, error => {
        console.log("Something went wrong in breadcrumb generation.");
        this.subscription.add(routerNavigationSubscription$);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeConfig = child.snapshot.routeConfig;

      //If there is an empty path, ignore it
      if (routeConfig.path) {
        const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
        if (routeURL !== '') {
          url += `/${routeURL}`;
        }

        //We don't want to use the url for label generation as that can get messy when there are parameters in it.
        //so strip out parameters if there are any.
        let cleanedRouteUrl = this.buildRouteWithoutParameters(routeConfig.path);

        let breadCrumbSnapshot = child.snapshot.data[BreadcrumbsComponent.ROUTE_DATA_BREADCRUMB];
        let breadCrumbShouldBeClickable = routeConfig.component ? true : false;

        if (breadCrumbSnapshot != null && breadCrumbSnapshot != undefined) {
          //Since missing data items anywhere along the route can lead to duplicates, make them unique objects
          let breadCrumbOrLabel = JSON.parse(JSON.stringify(breadCrumbSnapshot));

          let breadcrumbIsIdenticalToLast = this.breadCrumbsAreIdentical(this.mostRecentlyFoundCrumb, breadCrumbOrLabel);
          if (breadcrumbIsIdenticalToLast) {
            this.pushBreadCrumb({ label: this.formatFinalUrlSegmentAsLabel(cleanedRouteUrl), url: url, clickable: breadCrumbShouldBeClickable } as BreadCrumb);
          }
          else {
            if (typeof (breadCrumbOrLabel) == "string" && breadCrumbOrLabel != "") {
              this.pushBreadCrumb({ label: breadCrumbOrLabel, url: url, clickable: breadCrumbShouldBeClickable } as BreadCrumb);
            }
            //If it's an object we assume it is a type that at least overlaps with the BreadCrumb class
            if (typeof (breadCrumbOrLabel) == "object") {
              let castBreadCrumb = (breadCrumbOrLabel as BreadCrumb);

              //Unless the breadcrumb's clickable flag was explicitly set to false, or one wasn't provided and it just fell back to the most recent one,
              //set it based on if a component was tied to the route. If a component is associated, it's true.
              if (castBreadCrumb.clickable === null || castBreadCrumb.clickable === undefined) {
                castBreadCrumb.clickable = breadCrumbShouldBeClickable;
              }
              if (castBreadCrumb.label === null || castBreadCrumb.label === undefined) {
                castBreadCrumb.label = this.formatFinalUrlSegmentAsLabel(cleanedRouteUrl);
              }
              if (castBreadCrumb.url === null || castBreadCrumb.url === undefined) {
                castBreadCrumb.url = url;
              }

              this.pushBreadCrumb(castBreadCrumb);
            }
          }
        }
        else {
          //If no breadcrumb info was specified, try to create one
          this.pushBreadCrumb({ clickable: breadCrumbShouldBeClickable, url: url, label: this.formatFinalUrlSegmentAsLabel(cleanedRouteUrl) } as BreadCrumb);
        }

        this.mostRecentlyFoundCrumb = breadCrumbSnapshot;
      }

      return this.createBreadcrumbs(child, url, this.breadCrumbs);
    }

    return breadcrumbs;
  }

  private breadCrumbsAreIdentical(firstBreadCrumb: BreadCrumb | string, secondBreadCrumb: BreadCrumb | string): boolean {
    if (typeof (firstBreadCrumb) == "object" && typeof (secondBreadCrumb) == "object") {
      return firstBreadCrumb && secondBreadCrumb && firstBreadCrumb.clickable === secondBreadCrumb.clickable && firstBreadCrumb.label === secondBreadCrumb.label
        && firstBreadCrumb.url === secondBreadCrumb.url;
    }

    return firstBreadCrumb === secondBreadCrumb;

    
  }

  private pushBreadCrumb(breadCrumb: BreadCrumb): void {
    if (this.breadCrumbs && breadCrumb) {
      this.breadCrumbs.push(breadCrumb);
    }
  }


  //To handle cases where an explicit breadcrumb wasn't provided, we try to format the label off of the current final segment
  //Capitalize the first letter, and replace all instances of '-' with empty spaces, capitalizing the following character.
  private formatFinalUrlSegmentAsLabel(url: string, segmentDelimitingString: string = "", segmentDelimiterReplacement: string = ""): string {
    let segmentStartIndex = url.indexOf(segmentDelimitingString);
    let segmentFirstLetter = url.substring(segmentStartIndex + segmentDelimitingString.length, segmentStartIndex + (segmentDelimitingString.length + 1));
    let segmentAsLabel = url.replace(segmentDelimitingString + segmentFirstLetter, segmentDelimiterReplacement + segmentFirstLetter.toUpperCase());
    while (segmentAsLabel.includes("-")) {
      segmentAsLabel = this.formatFinalUrlSegmentAsLabel(segmentAsLabel, "-", " ");
    }
    return segmentAsLabel;
  }

 
  private buildRouteWithoutParameters(routePath: string): string {
    let routeWithoutParametersString = routePath;
    if (routeWithoutParametersString.includes("/:")) {
      //find first occurrence of a parameter
      let indexOfParam = routePath.indexOf("/:");
      let indexOfParamEnd = routePath.indexOf("/", indexOfParam + 2);
      //strip out the param
      routeWithoutParametersString = routePath.substring(0, indexOfParam);
      //If there's a / further in the list, add the portion following it back on
      if (indexOfParamEnd !== -1) {
        routeWithoutParametersString = routeWithoutParametersString + routePath.substring(indexOfParamEnd, routePath.length);
        routeWithoutParametersString = this.buildRouteWithoutParameters(routeWithoutParametersString);
      }
    }
    return routeWithoutParametersString;
  }

  onBreadCrumbClick(event: BreadCrumb) {
    this.router.navigate([event.url]);
  }

}
