import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { FFUser, getUserOrgs } from '../../../models/users/ff-user.model';
import { Organization } from '../../../models/organizations/organization.model';
import { AuthService } from '../../../services/auth.service';
import { MainNavService } from '../../services/main-nav.service';
import { Router } from '@angular/router';
import { FFUserWithRoles } from '../../../models/users/ff-user-with-roles.model';
import { MainService } from '../../services/main.service';
import { LoaderData } from '../../models/loader-data.model';

@Component({
  selector: 'ui-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy {
  public userInfo: FFUser;
  public verifiedUser: FFUser = undefined;
  public userOrgs: Organization[];
  public currentOrg: Organization;
  private subscription = new Subscription();
  @ViewChild('beforeNavMenu') beforeNavAnchor!: ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private _mainService: MainService,
    private _mainNavService: MainNavService
  ) {
    const mainNav$ = this._mainNavService.setFocusOutsideNav.pipe(finalize(() => {
      this.subscription.add(mainNav$);
    })).subscribe((before: boolean) => {
      if (before) {
        this.beforeNavAnchor.nativeElement.focus();
      }
    });
  }

  ngOnInit(): void {
    //hook up to auth service emitter
    const srv$ = this.authService.userInfoEmitter.pipe(finalize(() => {
      this.subscription.add(srv$);
    })).subscribe(result => {
      this.setUserInfo(result);
    }, error => {
      console.log('Error listening for auth service user info. ' + error);
    });

    this.authService.getUserInfo();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setUserInfo(user: FFUserWithRoles) {
     this.userInfo = user;
    if (this.userInfo.emailConfirmed && this.userInfo.isActivated) {
      this.verifiedUser = this.userInfo;
      this.userOrgs = getUserOrgs(this.userInfo);
      this.currentOrg = this.userInfo.getCurrentOrg();
    }
    else {
      this.verifiedUser = null;
    }
  }

  public onLogoutClick(): void {
    this._mainService.showLoading({ loading: true, loadingText: "Logging you out." } as LoaderData);
    const logoutSub$ = this.authService.logout(this.userInfo.id, "/").pipe(finalize(() => {
      this.subscription.add(logoutSub$);
      this._mainService.showLoading({ loading: false });
    })).subscribe((result: { iFrameUrl: string, postLogoutRedirectUri: string }) => {
      this.authService.resetServiceState();
      this.authService.userInfoEmitter.emit(new FFUserWithRoles);
      this.router.navigate([result.postLogoutRedirectUri]);
    }, error => {
      console.log('Error trying to log out. ' + error);
    });
  }
}
