<div class="export-button-container">
  <div class="tooltip-wrapper">
    <button uiLibButton
            [color]="'PRIMARY' | buttonColorEnumPipe"
            [size]="'MEDIUM' | buttonSizeEnumPipe"
            [shape]="'ROUNDED' | buttonShapeEnumPipe"
            [style]="'SOLID' | buttonStyleEnumPipe"
            (click)="exportToCSV()">
      Export Data
    </button>
    <app-popper-tool-tip screenReaderText="Export contents of the data grid">
    </app-popper-tool-tip>
  </div>
</div>
<div class="tooltip-wrapper">
  <label>Grid Info:</label>
  <app-popper-tool-tip [isHtmlContent]="true" [screenReaderText]="gridHelpText">
  </app-popper-tool-tip>
</div>
<div [ngStyle]="{'height': gridHeight}" *ngIf="notifications">
  <ag-grid-angular style="width: 100%; height: 100%"
                   class="ag-theme-alpine"
                   [gridOptions]="gridOptions"
                   [components]="frameworkComponents"
                   [columnDefs]="columnDefs"
                   [rowData]="notifications"
                   [rowSelection]="'multiple'"
                   [animateRows]="true"
                   (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
