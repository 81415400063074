import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../common/services/auth.service';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(private authorize: AuthService, private router: Router) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise<boolean>((resolve, reject) => {
      //Special case coming from the login to not rely on cached user info
      let forceRefetch = window.location.href.includes(ApplicationPaths.Login);
      this.authorize.getUserInfo(forceRefetch).then(user => {
        if (this.authorize.authenticated) {
          resolve(true);
        } else {
          this.router.navigate(ApplicationPaths.LoginPathComponents, {
            queryParams: {
              [QueryParameterNames.ReturnUrl]: state.url
            }
          });
          reject(false);
        }
      });
    })
      .catch(error => {
        this.router.navigate(ApplicationPaths.LoginPathComponents, {
          queryParams: {
            [QueryParameterNames.ReturnUrl]: state.url
          }
        });
        return error;
      });
  }
}
