import { getFlattenedUsersFromMap } from "../../services/utilities.service";
import { UserMapping } from "../users/user-mapping.model";
import { OrgRole } from "./org-role.model";
import { OrganizationRelation } from "./organization-relation.model";

export class Organization {
  //[Required]
  //MaxLength(16)
  public organizationId: string | undefined = "";
  //MaxLength(50)
  public govAgencyNumber: string | undefined = "";
  //MaxLength(50)
  public operatorNumber: string | undefined = "";
  //MaxLength(150)
  public organizationName: string | undefined = "";
  //MaxLength(200)
  public address1: string | undefined = "";
  //MaxLength(200)
  public address2: string | undefined = "";
  //MaxLength(150)
  public city: string | undefined = "";
  //MaxLength(2)
  public state: string | undefined = "";
  //MaxLength(5)
  public zip: string | undefined = "";
  //MaxLength(12)
  public phone: string | undefined = "";
  public isGovAgency: boolean | undefined = false;
  public isOperator: boolean | undefined = false;
  public isRegisteredAgent: boolean | undefined = false;
  public isServiceComp: boolean | undefined = false;
  //MaxLength(100)
  public createdBy: string | undefined = "";
  //MaxLength(8)
  public createdDate: string | undefined;
  //MaxLength(100)
  public lastModifiedBy: string | undefined = "";
  //MaxLength(8)
  public lastModifiedDate: string | undefined;
  public userMappings: UserMapping[] | undefined;
  public organizationRelations: OrganizationRelation[] | undefined;
  public organizationDefaults: any[];
  public nameChangeReason: string | undefined = "";

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
  public get LastModifiedDateAsDate() {
    if (!this.lastModifiedDate) return null;
    var d = new Date(this.lastModifiedDate);
    return d;
  }

  public getFlattenedUsersFromMap() {
    return getFlattenedUsersFromMap(this.userMappings);
  }
  public getFlattenedRelations(orgRelationTypes:any[]) {
    if (!this.organizationRelations) return null;
    let orgs:any[] = [];
    for (let i = 0; i < this.organizationRelations.length; i++) {
      let o = this.organizationRelations[i];
      if (orgs.find(x => x.organizationId == o.organizationId)) {
        console.log('reltypeid', o);
        let no = orgs.find(x => x.organizationId == o.organizationId);
        let t = orgRelationTypes.find(x => x.orgRelationTypeId == no.orgRelationTypeId);
        if (t) {
          let tname = t.value.replace('Operator - ', '');
          no[tname] = true;
        }
      } else {
        let no = JSON.parse(JSON.stringify((o as any).organization));
        no.orgRelationTypeId = o.orgRelationTypeId;
        no.createdDate = o.createdDate;
        let t = orgRelationTypes.find(x => x.orgRelationTypeId == no.orgRelationTypeId);
        if (t) {
          let tname = t.value.replace('Operator - ', '');
          no[tname] = true;
          no.statusId = o.statusId;
          no.removedDate = o.removedDate;
        }
        console.log('reltypeid', o.orgRelationTypeId);
        orgs.push(no);
      }

    }
    return orgs;
  }
}
