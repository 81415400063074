import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { dateStringToUtcDate } from '../../../../common/functions/date-string-to-utc-date.function';
import { IDateCellEditorParams } from '../../../models/date-cell-editor.-params.model';

@Component({
  selector: 'date-cell-editor',
  templateUrl: './date-cell-editor.component.html',
  styleUrls: ['./date-cell-editor.component.scss']
})
export class DateCellEditorComponent implements ICellEditorAngularComp {

  private cellName: string = '';

  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public readOnly: boolean;

  public dateBind = "";
  public dateViewBind = "";

  public hasError = false;
  private params: IDateCellEditorParams;

  @ViewChild('dateInput') input: ElementRef;

  constructor() { }

  agInit(params: IDateCellEditorParams): void {
    this.params = params;
    this.data = params.data;
    //console.log('aginit data', this.data);
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    try {
      this.cellName = params?.colDef?.field?.toString() || '';
    } catch (ee) {
      alert("error getting cell name")
    }
    if (!this.data) return;
    this.originalData = JSON.parse(JSON.stringify(this.data));
    this.handleDateData(this.data[this.cellName]);
  }

  refresh(params: IDateCellEditorParams): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  handleDateData(date: any) {
    //console.log('handledatedata', date);
    if (!date) {
      console.log('no date in date data');
      return;
    }
    var d = new Date(date);
    //console.log('date', d);
    this.dateBind = d.getFullYear() + "-" + this.pad((d.getMonth() + 1), 2) + "-" + this.pad(d.getDate(), 2);
    this.dateViewBind = this.genDVB(d);

  }

  public dateUpdated($event: any) {
    if (!this.dateBind) {
      this.data[this.cellName] = null;
      return;
    }
    var d = new Date(this.dateBind);
    console.log('date', d);
    this.dateViewBind = this.genDVB(d);
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  public getValue() {
    let dateString = dateStringToUtcDate(this.dateBind).toISOString();
    return dateString;
  }

  private genDVB(d: Date) {
    return `${(d.getMonth() + 1)}/${d.getDate()}/${d.getFullYear()}`;
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  focusOut(): void {
  };

  focusIn(): void {
    if (this.input.nativeElement) {
      this.input.nativeElement.focus();
    }
  };
}
