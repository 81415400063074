import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-unlock-user-cell',
  templateUrl: './unlock-user-cell.component.html',
  styleUrls: ['./unlock-user-cell.component.scss']
})
export class UnlockUserCellComponent implements ICellRendererAngularComp {
  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public status: string;
  public isLocked: boolean = false;
  public isInactive: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.status = this.data.status;
    this.isLocked = this.data.status === "Locked";
    this.isInactive = this.data.status === "Inactive";
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  public unlock(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "unlock", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public reactivate(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "reactivate", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

}
