import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { Disclosure } from '../../../../common/models/disclosures/disclosure.model';

@Component({
  selector: 'app-download-xml-cell',
  templateUrl: './download-xml-cell.component.html',
  styleUrls: ['./download-xml-cell.component.scss']
})
export class DownloadXmlCellComponent implements OnInit, ICellRendererAngularComp {

  private disclosure: Disclosure;
  private gridApi: any;
  public rowIndex: number = -1;

  constructor() { }
  
  ngOnInit(): void { }

  agInit(params: ICellRendererParams<Disclosure, any>): void {
    this.disclosure = params.data;
    this.gridApi = params.api;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  viewXML(): void{
    if (this.gridApi.onActionTrigger) {
      this.gridApi.onActionTrigger({ row: this.rowIndex, action: "viewXml", data: this.disclosure });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }
}
