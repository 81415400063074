import { OrgRole } from "../organizations/org-role.model";
import { FFUser } from "./ff-user.model";
import { OrgRoleType } from "./org-role-type.enum";
import { UserMappingWithRelatedOrgs } from "./user-mapping-with-related-orgs.model";

export class FFUserWithRoles extends FFUser {
  public roles: Array<string>;
  public orgPermissions: Array<UserMappingWithRelatedOrgs>;
  constructor(obj?: any) {
    super(obj);

    if (this.orgPermissions && this.orgPermissions.length > 0) {
      for (let x = 0; x < this.orgPermissions.length; x++) {
        this.orgPermissions[x] = new UserMappingWithRelatedOrgs(this.orgPermissions[x]);
      }
    }
  }

  public get isAdmin(): boolean {
    if (!this.roles || this.roles.length < 1) return false;
    return (this.roles.find(x => x == "Admin")!=null);
  }

  public getOrgRoles(orgId: string): Array<OrgRoleType> {
    if (!this.orgPermissions || this.orgPermissions.length < 1 || !this.orgPermissions.some(x => x.organizationId.toLowerCase() == orgId.toLowerCase())) {
      return [OrgRoleType.None];
    }

    let matchingRoles = this.orgPermissions.filter(x => x.organizationId.toLowerCase() == orgId.toLowerCase()).map(y => y.OrgRole);
    return matchingRoles;
  }
  public get primaryOrgRoles() {
    let fullArr = this.orgPermissions.filter(x => x.organizationId == this.currentOrganizationId).map(y => y.orgRoleName);
    return fullArr.filter((n, i) => fullArr.indexOf(n) === i);;
  }
  public hasOrgRole(role: string) {
    let fullArr = this.orgPermissions.filter(x => x.organizationId == this.currentOrganizationId).map(y => y.orgRoleName);
    return fullArr.some(x => x === role);
  }
}
