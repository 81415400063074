import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-approve-deny-delete-cell',
  templateUrl: './approve-deny-delete-cell.component.html',
  styleUrls: ['./approve-deny-delete-cell.component.scss']
})
export class ApproveDenyDeleteCellComponent implements ICellRendererAngularComp {
  private cellName: string = '';
  public params: any;
  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public isActivated: boolean = false;
  public isDenied: boolean = false;
  public isEmailConfirmed: boolean = false;
  public emailSent: boolean = false;
  public isPendingApproval: boolean = false;
  public isDeactivated: boolean = false;
  
  constructor() { }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.cellName = params?.colDef?.cellRenderer?.toString() || '';
    this.originalData = JSON.parse(JSON.stringify(this.data));
    this.isActivated = this.data.isActivated;
    this.isDenied = this.data.isDenied;
    this.isEmailConfirmed = this.data.emailConfirmed;
    this.isPendingApproval = this.isEmailConfirmed 
      && !this.isActivated 
      && !this.isDenied 
      && this.data.activatedDate == null
      && this.data.deactivatedDate == null;
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  public approve(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "approve", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public activate(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "activate", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public deactivate(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "deactivate", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public deny(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "deny", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public delete(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "delete", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public resendEmail(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "resendEmail", data: this.data });
      this.emailSent = true;
    } else {
      console.error("OnActionTrigger not defined");
    }
  }
}
