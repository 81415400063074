import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Disclosure } from '../../../../common/models/disclosures/disclosure.model';

@Component({
  selector: 'app-amend-cell',
  templateUrl: './amend-cell.component.html',
  styleUrls: ['./amend-cell.component.scss']
})
export class AmendCellComponent implements OnInit, ICellRendererAngularComp {

  public api: any
  public data: any
  public rowIndex: number = -1
  public readOnly: boolean = true
  public disclosure: Disclosure;

  constructor(private router: Router) {}  

  ngOnInit(): void { }

  agInit(params: ICellRendererParams<any, any>): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.disclosure = params.data as Disclosure;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  open(): void {
    if (this.disclosure.publicStatus === 'Submitted') {
      if (this.api.onActionTrigger) {
        this.api.onActionTrigger({ row: this.rowIndex, action: "startAmend", data: this.data });
      } else {
        console.error("OnActionTrigger not defined");
      }
      return;
    }
    this.router.navigate(['/disclosure/edit-disclosure/', this.data.disclosureId]);
  }

  viewPdf(): void {
    window.open("/api/file/GetDisclosurePdfByName/" + this.data.disclosureId + "/" + encodeURIComponent(this.data.pdfName), "_blank");
  }

}
