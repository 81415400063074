import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { GridService } from '../../../services/grid.service';

@Component({
  selector: 'edit-history-cell',
  templateUrl: './edit-history-cell.component.html',
  styleUrls: ['./edit-history-cell.component.scss']
})
export class EditHistoryCellComponent implements ICellRendererAngularComp {

  private cellName: string = '';
  public params: any;
  public data: any;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public readOnly: boolean = true;
  public isPending: boolean = false;
  public isDisabled: boolean = false;
  public hideDelete: boolean = false;
  public modalEdit: boolean = false;
  public showModalEditButton: boolean = false;
  public editTitle: string = "Edit";
  public historyTitle: string = "History";
  public deleteTitle: string = "Delete";
  public cancelTitle: string = "Cancel";
  public saveTitle: string = "Save";

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    protected gridService: GridService
  ) {
    this.readOnly = gridService.readOnly.getValue();
  }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.cellName = params?.colDef?.cellRenderer?.toString() || '';
    this.originalData = JSON.parse(JSON.stringify(this.data));
    this.isPending = (this.data.status && this.data.status === "Pending");
    if (this.data.cannotEdit) {
      this.isDisabled = true;
    }
    if (params.context && params.context.userAdmin) {
      this.hideDelete = true;
    }
    if (params.context && params.context.modalEdit) {
      this.modalEdit = true;
      this.readOnly = false;
    }
    this.showModalEditButton = this.modalEdit && (this.data.status === "Active" || this.data.status === "Pending Approval");
    if (params.context && params.context.parent) {
      const parent = params.context.parent; 
      switch (parent) {
        case "manage-org-users":
          this.editTitle = "Edit user's account";
          this.historyTitle = "View user's account history with this organization";
          this.deleteTitle = "Delete user's pending account";
          this.cancelTitle = "Cancel user's account edit without saving changes";
          this.saveTitle = "Save user's account edits";
          break;
        case "manage-org-relations":
          this.editTitle = "Edit relationship";
          this.historyTitle = "View expanded history";
          this.cancelTitle = "Cancel edit without saving changes";
          this.saveTitle = "Save edits";
          break;
        
        default:
          break;
      }
    }
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }

  public toggleReadOnly(): void {
    this.readOnly = !this.readOnly;
    console.log('start of togglereadonly', this.readOnly);
    const gridData = this.getFilteredAndSorted();
    if (gridData.length > 0) {
      this.rowIndex = gridData.indexOf(this.data);
    }
    this.gridService.selectedTable.next(this.cellName);
    this.gridService.readOnly.next(this.readOnly);
    this.gridService.rowIdex.next(this.rowIndex);
    //this.data.disabled = this.readOnly = !this.readOnly;
    this.data.disabled = this.readOnly;
    //this.gridService.selectedTable.next(this.cellName);
    //this.gridService.readOnly.next(this.readOnly);

    console.log('before actions togglereadonly', this.readOnly);
    if (!this.readOnly) {
      //console.log('startediting togglereadonly', this.readOnly);
      this.api.startEditingCell({
        rowIndex: this.rowIndex,
        colKey: '0'
      });
    } else {
      //console.log('stopediting togglereadonly', this.readOnly);
      this.api.stopEditing(true);
      this.api.redrawRows();
    }

    /*
    if (this.api.onActionTrigger) {
      console.log('at togglereadonly', this.readOnly);
      this.api.onActionTrigger({ row: this.rowIndex, action: "toggleReadOnly", data: this.data, cellName: this.cellName, ro: this.readOnly });
      this.api.redrawRows();
    }
    */
    //this.changeDetectorRef.detectChanges();
    console.log('end of togglereadonly', this.readOnly);
  }

  public deleteRow(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "delete", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public showHistory(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "showHistory", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public triggerEdit(): void {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "triggerEdit", data: this.data });
    } else {
      console.error("OnActionTrigger not defined");
    }
  }

  public save(): void {
    this.data.disabled = this.readOnly = true;
    this.gridService.readOnly.next(this.readOnly);
    this.api.redrawRows();
    this.api.stopEditing();

    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "editComplete", data: this.data, originalData: this.originalData });
    }
  }

  getFilteredAndSorted(): any[] {
    let data: any[] = [];
    this.api.forEachNodeAfterFilterAndSort(function(node: { data: any; }) {
      data.push(node.data);
    });
    return data;
  }

}
