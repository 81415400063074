import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Status } from '../../models/lookup/status.model';
import { Organization } from '../../models/organizations/organization.model';
import { FFUserWithRoles } from '../../models/users/ff-user-with-roles.model';
import { AuthService } from '../../services/auth.service';
import { DisclosureService } from '../../services/disclosure.service';
import { DropdownService } from '../../services/dropdown.service';
import { LoggingService } from '../../services/logging.service';
import { getDistinct } from '../../services/utilities.service';
import { LoaderData } from '../../ui/models/loader-data.model';
import { MainService } from '../../ui/services/main.service';
import { UserMappingWithRelatedOrgs } from '../../models/users/user-mapping-with-related-orgs.model';
import { first } from 'rxjs';
import { isNotNullOrEmpty } from '../../functions/is-not-null-or-empty.function';
import { dateStringToUtcDate } from '../../functions/date-string-to-utc-date.function';
import { DownloadFilter } from '../../models/disclosures/download-filter.model';

const FileSaver = require('file-saver');
@Component({
  selector: 'filter-download',
  templateUrl: './filter-download.component.html',
  styleUrls: ['./filter-download.component.scss']
})
export class FilterDownloadComponent implements OnInit {
  userInfo: FFUserWithRoles;
  currentOrg: Organization;
  public statuss: Status[];
  public states: any[];
  public orgId: string;
  public operatorNames: string[];
  public selectedOperatorId: string | null = null;
  public isSubmissionDatesValid: boolean = true;
  public downloadCount: any = 'Calculating';
  public IsValidInputDate: boolean = true;
  private operators: UserMappingWithRelatedOrgs[];
  
  public fg: FormGroup = new FormGroup({
    jobDateStart: new FormControl<string>(null),
    jobDateEnd: new FormControl<string>(null),
    state: new FormControl<string>(null, [Validators.maxLength(2)]),
    operator: new FormControl<string>(null),
    downloadType: new FormControl<'XML' | 'CSV'>("XML", Validators.required),
  })


  constructor(
    private dropdownService: DropdownService,
    private mainService: MainService,
    private disclosureService: DisclosureService,
    private loggingService: LoggingService,
    private authService: AuthService, public changeDetector: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.dropdownService.getStatus().then(res => {
      this.statuss = res;
      /// not sure how to address pending vs amending here...
    }, err => {
      this.loggingService.error("Error retrieving status'.", "Error retrieving status'.");
    })
    this.dropdownService.getStates().then(res => {
      this.states = res;
      if (this.currentOrg) this.formUpdated(null);
    }, err => {
      this.loggingService.error("Error retrieving states.", "Error retrieving states.");
    })
    this.authService.getUserInfo().then(res => {
      this.userInfo = res;
      this.currentOrg = this.userInfo.getCurrentOrg();
      this.orgId = this.userInfo.currentOrganizationId; 
    
      let relevantPermissions = this.userInfo.orgPermissions.filter(x => x.organizationId == this.userInfo.currentOrganizationId
        && x.orgRoleName == "DataSubmitter");
      let relevantOperators = relevantPermissions.filter(x => x.operatorName != null);

      this.operatorNames = getDistinct(relevantOperators, "operatorName");
      this.operators = relevantOperators;
      if (this.states) this.formUpdated(null);
    })
  }

  public selectedOperatorNameSet(): void{
    const { operator } = this.fg.controls;
    const op = this.operators.find(x => x.operatorName == operator.value);

    if (op) {
      this.selectedOperatorId = op.operatorOrgId;
    }
    this.formUpdated(null);
  }

  public clearOperator(): void{
    this.selectedOperatorId = '';
    const { operator } = this.fg.controls;
    operator.patchValue(null);
    this.formUpdated(null);
  }

  public clearState(): void{
    const { state } = this.fg.controls;
    state.patchValue(null);
    this.formUpdated(null);
  }

  public formUpdated($event: any) {
    console.log('form updated');
    let val = this.fg.getRawValue();
    if ($event) val.propertyName = $event.target.name;
    const { jobDateStart, jobDateEnd, state } = this.fg.controls;
    const filter: any = {
      OrganizationId: this.currentOrg.organizationId,
      StateAbbr: state.value ?? null,
      jobDateStart: jobDateStart.value ?? null,
      jobDateEnd: jobDateEnd.value ?? null,
      OperatorOrganizationId: (this.selectedOperatorId) ? this.selectedOperatorId : null,
      StatusId: null,
    };
    this.downloadCount = 'Calculating';
    if (!isNotNullOrEmpty(jobDateStart.value) || !isNotNullOrEmpty(jobDateEnd.value)) {
      this.IsValidInputDate = true;
    } else {
      let startDate = new Date(jobDateStart.value);
      let endDate = new Date(jobDateEnd.value);
      if (($event != null) && (startDate.getTime() <= 0 || endDate.getTime() <= 0)) {
        this.IsValidInputDate = false;
        this.downloadCount = "";
        return;
      }
      else if (startDate.getTime() > endDate.getTime()) {
        this.IsValidInputDate = false;
        this.downloadCount = "";
        return;
      }
      else {
        this.IsValidInputDate = true;
      }
    }
  
    this.disclosureService.filteredXMLDownloadCount(this.currentOrg.organizationId, filter).subscribe((resCount: any) => {
      //this.mainService.showLoading({ loading: false });
      this.downloadCount = resCount;
      this.changeDetector.detectChanges();
    }, err => {
      this.downloadCount = 'Error';
      this.changeDetector.detectChanges();
      this.loggingService.error("Error generating XML download count", "Error generating XML download count.");
    });
  }

  public isNaN(x: any) {
    return isNaN(x);
  }

  public download(): void{
    if (!this.fg.valid) {
      this.loggingService.error("Select all the required fields");
      return;
    }

    const { state } = this.fg.controls;

    let jobDateStart: string = null;
    let jobDateEnd: string = null;
    if (this.fg.get("jobDateStart") && this.fg.get("jobDateStart").value) {
      jobDateStart = this.fg.get("jobDateStart").value;
    }
    if (this.fg.get("jobDateEnd") && this.fg.get("jobDateEnd").value) {
      jobDateEnd = this.fg.get("jobDateEnd").value;
    }

    const filter: DownloadFilter = {
      organizationId: this.currentOrg.organizationId,
      stateAbbr: state.value ?? null,
      jobDateStart: jobDateStart,
      jobDateEnd: jobDateEnd,
      operatorOrganizationId: (this.selectedOperatorId) ? this.selectedOperatorId : null,
      statusId: null,
    };
    
    if (this.currentOrg.isOperator) {
      filter.operatorOrganizationId = this.currentOrg.organizationId;
    }

    if (this.fg.controls.downloadType.value == "XML") {
      this.mainService.showLoading({ loading: true, loadingText: "Downloading Data" } as LoaderData);

        this.getXMLDownload(filter);
    }
    else {
      this.mainService.showLoading({ loading: true, loadingText: "Downloading Data" } as LoaderData);
      this.disclosureService.filteredCSVDownload(this.currentOrg.organizationId, filter)
      .pipe( first() )
      .subscribe({
        next: res => {
          var fn = "disclosures.csv";
          var data = new Blob([res as string], { type: 'text/csv' });
          FileSaver.saveAs(data, fn);
          this.mainService.showLoading({ loading: false });
        }, 
        error: err => {
        this.mainService.showLoading({ loading: false });
        this.loggingService.error("Error generating CSV download", "Error generating CSV download.");
        }
      });

      this.disclosureService.filteredCSVDownload2(this.currentOrg.organizationId, filter)
        .pipe(first())
        .subscribe({
          next: res => {
            var fn = "disclosurewater.csv";
            var data = new Blob([res as string], { type: 'text/csv' });
            FileSaver.saveAs(data, fn);
            this.mainService.showLoading({ loading: false });
          },
          error: err => {
            this.mainService.showLoading({ loading: false });
            this.loggingService.error("Error generating CSV water download", "Error generating CSV water download.");
          }
        });
    }
  }
  private getXMLDownload(filter:any) {
    this.disclosureService.filteredXMLDownload(this.currentOrg.organizationId, filter)
      .pipe(first())
      .subscribe({
        next: res => {
          var fn = "disclosures.xml";
          var data = new Blob([res as string], { type: 'text/xml' });
          FileSaver.saveAs(data, fn);
          this.mainService.showLoading({ loading: false });
        },
        error: () => {
          this.mainService.showLoading({ loading: false });
          this.loggingService.error("Error generating XML download", "Error generating XML download.");
        }
      });
  }
  /* Getters for DOM */
  public get operator(): string | null{
    return this.fg.controls.operator.value
  }
  public get state(): string | null{
    return this.fg.controls.state.value
  }
}
