import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { INgSelectEditorParams } from '../../../models/ng-select-editor-params.model';
import { ColumnResizedEvent, GridApi } from 'ag-grid-community';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OnLeavingEditorParams } from '../../../models/base-cell-editor/on-leaving-editor-params.model';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { isNotNullOrEmpty } from '../../../../common/functions/is-not-null-or-empty.function';


@Component({
  selector: 'ng-select-cell',
  templateUrl: './ng-select-editor.component.html',
  styleUrls: ['./ng-select-editor.component.scss']
})
export class NgSelectEditorComponent implements ICellEditorAngularComp, OnDestroy {

  itemChangedHandler: ((result: (any | Array<any>), params: INgSelectEditorParams) => void);

  public isMultiSelect: boolean = false;
  public isEnabled: boolean = true;
  public clearable: boolean = true;
  public bindLabel: string = "";
  public bindValue: string = "";
  public placeholder: string = "";
  public options: any;
  public cellWidth: string = "";
  public cellHeight: string = "";
  public useBindValue: boolean = false;
  public value: any;
  public isValid: boolean = false;
  public isRequired: boolean = true;
  private originalValue: any;

  private params: INgSelectEditorParams;
  private subscription: Subscription = new Subscription();
  private readonly currentValue = new Subject<string | undefined>();

  @ViewChild('bindValueNgSelect') bindValueInput: NgSelectComponent;
  @ViewChild('nonBindValueNgSelect') nonBindValueInput: NgSelectComponent;

  public agInit(params: INgSelectEditorParams): void {
    this.params = params;

    (this.params.api as GridApi).addEventListener("columnResized",
      (event: ColumnResizedEvent) => {
        if ((event.column && event.column.getId() == this.params.colDef.colId) || event.column === null || this.params.colDef.colId === undefined) {
          this.cellWidth = (params.column.getActualWidth()) - 2 + 'px';
          this.cellHeight = (params.node.rowHeight) + 'px';
        }
      }
    );

    if (params.isValid != null && params.isValid != undefined) {
      if (params.isValid instanceof Function) {
        if (params.initialDisclosureValidationStatements && params.initialDisclosureValidationStatements.length > 0)
          this.isValid = params.isValid(params.initialDisclosureValidationStatements, this.params.fieldName, this.params.idType, this.params.node.id);
      }
      else
        this.isValid = params.isValid;
    }
    else
      this.isValid = true;

    if (this.params.dataChangedEvent) {
      this.subscription.add(this.currentValue
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe((currentValue) => {
          this.params.dataChangedEvent.emit({ value: currentValue, field: this.params.colDef.field, itemId: this.params.node.id });
          this.checkValidity(currentValue);
        }));
    }

    if (params.isRequired != null && params.isRequired != undefined) {
      this.isRequired = params.isRequired;
      this.checkValidity(params.value);
    }
    else
      this.isRequired = false;

    this.isMultiSelect = params.isMultiSelect;
    if (params.isEnabled)
      this.isEnabled = params.isEnabled(params);
    else
      this.isEnabled = true;
    if (params.clearable != null && params.clearable != undefined)
      this.clearable = params.clearable;
    this.bindLabel = params.bindLabel;
    this.bindValue = params.bindValue;
    this.placeholder = params.placeholder;
    this.itemChangedHandler = params.itemChangedEventHandler;
    this.useBindValue = params.bindValue != null && params.bindValue != undefined && params.bindValue != "";
    this.options = params.options;
    this.setInitialState(this.params);
    this.cellWidth = params.column.getActualWidth() - 2 + 'px';
    this.cellHeight = params.node.rowHeight + 'px';

  }

  private checkValidity(currentValue: any): void {
    this.isValid = (this.isRequired && (currentValue === "" || currentValue === null || currentValue === undefined)) ? false : true;
  }

  setInitialState(params: INgSelectEditorParams) {
    this.value = params.value;
    this.originalValue = JSON.parse(JSON.stringify(params.value));
    this.checkValidity(params.value);
    if (this.params.dataChangedEvent) {
      this.params.dataChangedEvent.emit({ value: params.value, field: this.params.colDef.field, itemId: this.params.node.id });
    }
  }

  getValue(): any {
    return this.value;
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  public ngOnDestroy() {
    this.focusOut();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  focusOut(): void {
    //On focus out, check if the value has changed, if so patch it
    if (this.originalValue != this.value && this.params.onLeavingEditor && !this.params.node.isRowPinned()) {
      this.params.onLeavingEditor({
        data: this.params.data,
        patchInfo: [{
          fieldToPatch: "/" + this.params.colDef.field,
          valueToPatch: this.value
        }]
      } as OnLeavingEditorParams);

      this.params.node.data[this.params.colDef.field] = JSON.parse(JSON.stringify(this.value));
      this.originalValue = JSON.parse(JSON.stringify(this.value));
    }
  };

  focusIn(): void {
    let input: NgSelectComponent = null;
    if (this.useBindValue && this.bindValueInput) {
      input = this.bindValueInput;
    }
    else if (!this.useBindValue && this.nonBindValueInput) {
      input = this.nonBindValueInput;
    }
    input.focus();
  };

  onNgModelChanged(event: any | Array<any>) {
    this.currentValue.next(event);
    this.checkValidity(event);
    if (this.itemChangedHandler != null && this.itemChangedHandler != undefined) {
      this.itemChangedHandler(event, this.params);
    }
  }
}
