<div class="dashboard-container">
  <nav>
    <ul class="reset">
      <li *ngIf="userInfo && primaryOrg && userInfo.hasOrgRole('DataSubmitter')">
        <div class="tooltip-wrapper">
          <a routerLink="/disclosure/edit-disclosure/new">
            <div>
              <svg-icon-sprite class="normal" [src]="'assets/sprite/sprite.svg#icon-upload-disclosures'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
              <svg-icon-sprite class="hover" [src]="'assets/sprite/sprite.svg#icon-dashboard-btn-hover'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
            </div>
            <span>Add/Upload<br>New Disclosures</span>
          </a>
          <app-popper-tool-tip iconColor="WHITE" screenReaderText="Create or upload a new disclosure">
          </app-popper-tool-tip>
        </div>
      </li>
      <li>
        <div class="tooltip-wrapper">
          <a routerLink="/disclosure/manage-disclosures">
            <div>
              <svg-icon-sprite class="normal" [src]="'assets/sprite/sprite.svg#icon-disclosure-lists'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
              <svg-icon-sprite class="hover" [src]="'assets/sprite/sprite.svg#icon-dashboard-btn-hover'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
            </div>
            <span>Manage Disclosures</span>
          </a>
          <app-popper-tool-tip iconColor="WHITE" screenReaderText="Manage existing disclosures (Disclosure Lists)">
          </app-popper-tool-tip>
        </div>
      </li>
      <li *ngIf="userInfo && primaryOrg">
        <div class="tooltip-wrapper">
          <a routerLink="/disclosure/third-party-purposes">
            <div>
              <svg-icon-sprite class="normal" [src]="'assets/sprite/sprite.svg#icon-3rd-party'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
              <svg-icon-sprite class="hover" [src]="'assets/sprite/sprite.svg#icon-dashboard-btn-hover'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
            </div>
            <span>3rd Party<br>Chemicals</span>
          </a>
          <app-popper-tool-tip iconColor="WHITE" screenReaderText="Manage an organization's 3rd party chemicals">
          </app-popper-tool-tip>
        </div>
      </li>
      <li *ngIf="userInfo && primaryOrg && userInfo.hasOrgRole('Supervisor')">
        <div class="tooltip-wrapper">
          <a routerLink="/organization/manage-users">
            <div>
              <svg-icon-sprite class="normal" [src]="'assets/sprite/sprite.svg#icon-manage-users'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
              <svg-icon-sprite class="hover" [src]="'assets/sprite/sprite.svg#icon-dashboard-btn-hover'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
            </div>
            <span>Manage Users<br>& Companies</span>
          </a>
          <app-popper-tool-tip iconColor="WHITE" screenReaderText="Manage Organization's users and related companies">
          </app-popper-tool-tip>
        </div>
      </li>
      <li>
        <div class="tooltip-wrapper">
          <a routerLink="/user/profile">
            <div>
              <svg-icon-sprite class="normal" [src]="'assets/sprite/sprite.svg#icon-edit-account'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
              <svg-icon-sprite class="hover" [src]="'assets/sprite/sprite.svg#icon-dashboard-btn-hover'" [width]="'39px'" [height]="'39px'">
              </svg-icon-sprite>
            </div>
            <span>Edit<br>Account</span>
          </a>
          <app-popper-tool-tip iconColor="WHITE" screenReaderText="View & Edit User Account profile">
          </app-popper-tool-tip>
        </div>
      </li>
    </ul>
  </nav>
  <section class="accordion-container">
    <ui-lib-accordion [borderColor]=" 'TERTIARY3' | borderColorPipe"
                      [header]="'Notifications'"
                      [headerTextColor]=" 'TERTIARY3' | textColorPipe"
                      [headerAlignment]=" 'CENTER' | aligmentPipe"
                      [headerTextTransform]=" 'UPPER' | textTransformPipe"
                      [headerBackground]="'TRANSPARENT' | backgroundColorPipe"
                      [headerBorder]="'TRANSPARENT' | borderColorPipe"
                      [hasLowerButton]="true"
                      [contentVisible]="true"
                      [lowerButtonBorderColor]="'TERTIARY3' | borderColorPipe "
                      [lowerButtonBackgroundColor]="'WHITE' | backgroundColorPipe " style="height:auto">
      <slot>
        <dashboard-notifications [notifications]="notifications"></dashboard-notifications>
      </slot>
    </ui-lib-accordion>
    <div>
      <disclosure-list [parentComponentName]="'dashboard'"></disclosure-list>
    </div>
  </section>
</div>
