import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxPopperjsPlacements} from 'ngx-popperjs';

@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FooterComponent implements OnInit {
  public isOpenPageModalShowing: boolean = false
  public linkToOpen: string;
  public popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.TOP;
  constructor() { }

  ngOnInit(): void {
  }

  public closeOpenPageModal() { this.isOpenPageModalShowing = false, this.linkToOpen = '' }

  public openExternalLink(link: string) {
    this.linkToOpen = link;
    this.isOpenPageModalShowing = true;
  }

  public handleOk() {
    window.open(this.linkToOpen, "_blank");
    this.isOpenPageModalShowing = false;
  }

  public handleCancel() {
    this.isOpenPageModalShowing = false;
    this.linkToOpen = '';
  }
}
