<section class="accordion-container">
  <ui-lib-accordion [borderColor]=" 'TERTIARY3' | borderColorPipe"
  [header]="'Data Download'"
  [headerTextColor]=" 'TERTIARY3' | textColorPipe"
  [headerAlignment]=" 'CENTER' | aligmentPipe"
  [headerTextTransform]=" 'UPPER' | textTransformPipe"
  [headerBackground]="'TRANSPARENT' | backgroundColorPipe"
  [headerBorder]="'TRANSPARENT' | borderColorPipe"
  [hasLowerButton]="true"
  [lowerButtonBorderColor]="'TERTIARY3' | borderColorPipe "
  [lowerButtonBackgroundColor]="'WHITE' | backgroundColorPipe ">
  <slot>
    <filter-download></filter-download>
  </slot>
</ui-lib-accordion>
</section>
<!-- Added margin-top 20 to make equal space between data download and disclosure section as between notification and data download  -->
<section class="accordion-container" style="margin-top: 20px;">
  <div class="default-container">
    <h1 class="h3-size txt-trns-upper clr-tertiary-3 font-bold m-0 m-b-1 aln-center">Disclosures</h1>
    <form [formGroup]="filterForm" class="filter-form">
      <div class="view-export-container">
        <div class="tooltip-wrapper m-r-1">
          <div class="view-container">
            <label for="email">View:</label>
            <select formControlName="status" (change)="formUpdated($event)">
              <option value="PendingOG">Pending Oil And Gas ({{totalPendingOG}})</option>
              <option value="SubmittedOG"> {{isServiceComp ? 'Routed to Operator' : 'Submitted'}} Oil And Gas ({{totalSubmittedOG}})</option>
              <option *ngIf="!isServiceComp" value="AmendingOG">Amending Oil And Gas ({{totalAmendingOG}})</option>
              <option value="DeletedOG">Deleted Oil And Gas ({{totalDeletedOG}})</option>
              <option *ngIf="isServiceComp" value="DeletedByOperatorOG">Deleted by Operator Oil And Gas ({{totalDeletedByOperatorOG}})</option>
              <option *ngIf="allowNonOilAndGasWellTypes" value="PendingNonOG">Pending Non Oil And Gas ({{totalPendingNonOG}})</option>
              <option *ngIf="allowNonOilAndGasWellTypes" value="SubmittedNonOG"> {{isServiceComp ? 'Routed to Operator' : 'Submitted'}} Non Oil And Gas ({{totalSubmittedNonOG}})</option>
              <option *ngIf="allowNonOilAndGasWellTypes && !isServiceComp" value="AmendingNonOG">Amending Non Oil And Gas ({{totalAmendingNonOG}})</option>
              <option *ngIf="allowNonOilAndGasWellTypes" value="DeletedNonOG">Deleted Non Oil And Gas ({{totalDeletedNonOG}})</option>
              <option *ngIf="allowNonOilAndGasWellTypes && isServiceComp" value="DeletedByOperatorNonOG">Deleted by Operator Non Oil And Gas ({{totalDeletedByOperatorNonOG}})</option>
              <option value="ALL" selected>All ({{totalAll}})</option>
            </select>
          </div>
        <app-popper-tool-tip screenReaderText="Filter disclosures based on status">
          </app-popper-tool-tip>
      </div>
      <div class="tooltip-wrapper export-data-button">
        <button *ngIf="disclosures" uiLibButton
                [color]="'PRIMARY' | buttonColorEnumPipe"
                [size]="'MEDIUM' | buttonSizeEnumPipe"
                [shape]="'ROUNDED' | buttonShapeEnumPipe"
                [style]="'SOLID' | buttonStyleEnumPipe" (click)="exportToCSV()">
          Export Data
        </button>
        <app-popper-tool-tip screenReaderText="Export contents of the data grid">
        </app-popper-tool-tip>
      </div>  
      </div>
            
  <div class="tooltip-wrapper">
    <label>Grid Info:</label>
    <app-popper-tool-tip [isHtmlContent]="true" [screenReaderText]="gridHelpText">
    </app-popper-tool-tip>
  </div>

  </form>
  </div> 
  <div [ngStyle]="{'height': gridHeight}">
    <ag-grid-angular style="width: 100%; height: 100%"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions"
                     [components]="frameworkComponents"
                     [columnDefs]="filteredColDefs"
                     [rowData]="disclosuresFiltered"
                     [rowSelection]="'multiple'"
                     [animateRows]="true"
                     (gridReady)="onGridReady($event)"></ag-grid-angular>
  </div>
</section>
<ui-modal 
    [id]="'modalConfirmDelete'" 
    (closed)="modalClosed($event)" 
    [paddedContent]="true" 
    [isVisible]="modalVisible" 
    [modalTitle]="'Delete Disclosure'" 
    [hasCloseButton]="true" 
    [modalSize]=" 'MEDIUM' | modalSizeEnumPipe">
    
    <div modalContent>
        <div class="aln-center m-b-2">Are you sure you want to delete this disclosure?</div>
        <div class="aln-center m-b-2">The disclosure will be held in a delete pending state for 6 months.</div>
        <div class="aln-center m-t-1 m-b-1">
            <button uiLibButton
                [color]="'PRIMARY' | buttonColorEnumPipe"
                [size]="'MEDIUM' | buttonSizeEnumPipe"
                [shape]="'ROUNDED' | buttonShapeEnumPipe"
                class="m-r-1" 
                (click)="confirmDelete()">Delete</button>
            <button uiLibButton
                [color]="'PRIMARY' | buttonColorEnumPipe"
                [size]="'MEDIUM' | buttonSizeEnumPipe"
                [shape]="'ROUNDED' | buttonShapeEnumPipe" 
                [style]="'OUTLINED' | buttonStyleEnumPipe"
                (click)="cancelDelete()">Cancel</button>
        </div>
    </div>
</ui-modal>
<ui-modal [id]="'modalConfirmRollback'"
          (closed)="cancelRollback()"
          [paddedContent]="true"
          [isVisible]="modalRollbkVisible"
          [modalTitle]="'Amend Disclosure?'"
          [hasCloseButton]="true"
          [modalSize]=" 'MEDIUM' | modalSizeEnumPipe">
  <div modalContent>
    <div class="aln-center m-b-2">Are you sure you want to rollback this amendment?</div>
    <div class="aln-center m-t-1 m-b-1">
      <button uiLibButton
              [color]="'PRIMARY' | buttonColorEnumPipe"
              [size]="'MEDIUM' | buttonSizeEnumPipe"
              [shape]="'ROUNDED' | buttonShapeEnumPipe"
              class="m-r-1"
              (click)="confirmRollback()">
        Yes, Rollback
      </button>
      <button uiLibButton
              [color]="'PRIMARY' | buttonColorEnumPipe"
              [size]="'MEDIUM' | buttonSizeEnumPipe"
              [shape]="'ROUNDED' | buttonShapeEnumPipe"
              [style]="'OUTLINED' | buttonStyleEnumPipe"
              (click)="cancelRollback()">
        Cancel
      </button>
    </div>
  </div>
</ui-modal>
<ui-modal [id]="'modalAmendId'"
          (closed)="modalAmendClosed($event)"
          [paddedContent]="true"
          [isVisible]="modalAmendVisible"
          [modalTitle]="'Amend Disclosure?'"
          [hasCloseButton]="true"
          [modalSize]=" 'MEDIUM' | modalSizeEnumPipe">
  <div modalContent>
    <div class="aln-center m-b-2">Are you sure you want to amend this disclosure?</div>
    <div class="aln-center m-t-1 m-b-1">
      <button uiLibButton
              [color]="'PRIMARY' | buttonColorEnumPipe"
              [size]="'MEDIUM' | buttonSizeEnumPipe"
              [shape]="'ROUNDED' | buttonShapeEnumPipe"
              class="m-r-1"
              (click)="confirmAmend()">
        Yes, Amend
      </button>
      <button uiLibButton
              [color]="'PRIMARY' | buttonColorEnumPipe"
              [size]="'MEDIUM' | buttonSizeEnumPipe"
              [shape]="'ROUNDED' | buttonShapeEnumPipe"
              [style]="'OUTLINED' | buttonStyleEnumPipe"
              (click)="cancelAmend()">
        Cancel
      </button>
    </div>
  </div>
</ui-modal>
