import { Organization } from "../organizations/organization.model";
import { OrgRoleType } from "./org-role-type.enum";
import { UserMapping } from "./user-mapping.model";

export class FFUser {
    //[Required]
    //MaxLength(900)
    public id: string | undefined;
    //MaxLength(512)
    public userName: string | undefined;
    //MaxLength(512)
    public normalizedUserName: string | undefined;
    //MaxLength(512)
    public email: string | undefined;
    //MaxLength(512)
    public normalizedEmail: string | undefined;
    //[Required]
    public emailConfirmed: boolean | undefined;
    public passwordHash: string | undefined;
    public securityStamp: string | undefined;
    public concurrencyStamp: string | undefined;
    public phoneNumber: string | undefined;
    //[Required]
    public phoneNumberConfirmed: boolean | undefined;
    //[Required]
    public twoFactorEnabled: boolean | undefined;
    //MaxLength(10)
    public lockoutEnd: string | undefined;
    //[Required]
    public lockoutEnabled: boolean | undefined;
    //[Required]
    public accessFailedCount: number | undefined;
    //MaxLength(50)
    public firstName: string | undefined;
    //MaxLength(50)
    public lastName: string | undefined;
    //MaxLength(150)
    public primaryOrganizationId: string | undefined;
    //MaxLength(150)
    public currentOrganizationId: string | undefined;
    //MaxLength(200)
    public addressLine1: string | undefined;
    //MaxLength(200)
    public addressLine2: string | undefined;
    //MaxLength(150)
    public city: string | undefined;
    //MaxLength(2)
    public state: string | undefined;
    //MaxLength(5)
    public zip: string | undefined;
    public isActivated: boolean | undefined;
    public isCoRep: boolean | undefined;
    public isDenied: boolean | undefined;
    public deniedByAdmin: boolean | undefined;
    public pDFID: number | undefined;
    //MaxLength(16)
    public validationQuestionId: string | undefined;
    //MaxLength(150)
    public validationAnswer: string | undefined;
    //MaxLength(100)
    public createdBy: string | undefined;
    //MaxLength(8)
    public createdDate: string | undefined;
    //MaxLength(100)
    public lastModifiedBy: string | undefined;
    //MaxLength(8)
    public lastModifiedDate: string | undefined;
    public userMappings: UserMapping[] | undefined = [];
    public permissions: [] = [];
    public organization: string | undefined;
    public organizationType: string | undefined;
    public useEmailForTwoFA: boolean | undefined;
    public useMobileNumberForTwoFA: boolean | undefined;
    public mobilePhoneNumberForTwoFA: string | undefined;
    public isImpersonated: boolean | undefined;
    public officeRegion: string | undefined;
    public lastSuccessfulTwoFALogin: string | undefined;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }

  getPrimaryOrg(): Organization {
    return getUserOrgs(this).find(x => x.organizationId == this.primaryOrganizationId);
  }

  getCurrentOrg(): Organization {
    return getUserOrgs(this).find(x => x.organizationId == this.currentOrganizationId);
  }
}

export function getUserOrgs(user: FFUser) {
  if (!user || !user.userMappings) return null;
  var orgs: Organization[] = [];

  user.userMappings
  .filter(m => (m && m.organization) && m.isActive)
  .forEach(({ organization }) => {
    const orgInList = !!orgs.find(x => x.organizationId == organization.organizationId)
    
    if(orgInList){ return }

    orgs.push(organization);
  })

  return orgs;
}
