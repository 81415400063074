
import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, Observable, Subscription, tap } from 'rxjs';
import { CaptchaSettings } from '../../models/captcha-settings.model';
import { CreateAssessmentRequestModel } from '../../models/create-assessment-request.model';
import { LoggingService } from '../../services/logging.service';
import { ReCaptchaService } from '../../services/recaptcha.service';

@Component({ template: ''})
export class RecaptchaBaseComponent implements OnInit, OnDestroy {
  protected recaptchaToken: string = "";
  protected recaptchaTokenAction: string = "";
  protected subscription: Subscription = new Subscription();
  captchaFailed: boolean = false;
  captchaSucceeded: boolean = false;
  captchaSettings: CaptchaSettings;

  constructor(protected loggingService: LoggingService, protected recaptchaService: ReCaptchaService) {
  }

  ngOnInit(): void {
    let getRecaptchaSettings$ = this.recaptchaService.getCaptchaSettings().pipe(finalize(() => {
      this.subscription.add(getRecaptchaSettings$);
    })).subscribe(res => {
      this.captchaSettings = res;
    }, err => {
      this.loggingService.error("Error fetching Recaptcha settings.", "Error fetching Recaptcha settings: " + err);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected setRecaptchaTokenAction(actionName: string) {
    this.recaptchaTokenAction = actionName;
    this.fetchRecaptchaToken();
  }

  protected fetchRecaptchaToken(): void {
    var recaptchaService$ = this.recaptchaService.requestToken(this.recaptchaTokenAction).pipe(finalize(() => {
      this.subscription.add(recaptchaService$);
      //Tokens expire every two minutes, so upon fetching the first one, get ready to fetch another in two minutes.
      setTimeout(() => {
        this.fetchRecaptchaToken();
      }, 120000);
    })).subscribe(token => {
      this.recaptchaToken = token;
    }, error => {
      console.log('Failed to fetch a recaptcha token.', error);
    });
  }

  protected createRecaptchaAssessment(action: string = this.recaptchaTokenAction, token: string = this.recaptchaToken): Observable<number> {
    let createAssessmentRequest = { action: action, token: token } as CreateAssessmentRequestModel;
    return this.recaptchaService.createAssessment(createAssessmentRequest).pipe(
      tap((result: number) => {
        this.fetchRecaptchaToken();
        return result;
      }));
  }

  protected recaptchaCheckboxResolved(event: any) {
    if (event) {
      this.captchaSucceeded = true;
    }
  }

  protected recaptchaCheckboxErrored(event: any) {
    event;
    this.captchaSucceeded = false;
    this.loggingService.logError("An error occurred with the recaptcha checkbox for the " + this.recaptchaTokenAction + " action.");
  }
}
