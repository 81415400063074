import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { DateCellRendererComponent } from '../../../ag-grid/components/renderers/date-cell-renderer/date-cell-renderer.component';
import { AGGridHelperService } from '../../../ag-grid/services/ag-grid-helper-service';
import { DashboardNotification } from '../../models/dashboard-notification';
import { DropdownService } from '../../services/dropdown.service';
import { LoggingService } from '../../services/logging.service';
import { MainService } from '../../ui/services/main.service';

@Component({
  selector: 'dashboard-notifications',
  templateUrl: './dashboard-notifications.component.html',
  styleUrls: ['./dashboard-notifications.component.scss']
})
export class DashboardNotificationsComponent implements OnInit {
  public modalVisible = false;
  private isReadOnly = false;

  @Input() public notifications: DashboardNotification[] = null;
  public filterForm: UntypedFormGroup = new UntypedFormGroup({
    status: new UntypedFormControl("Editing")
  });

  public readonly columnDefs: ColDef[] = [
    {
      field: 'notificationMessage',
      editable: false,
      wrapText: true,
      width: 510,
      suppressColumnsToolPanel: true,
      lockVisible: true
    },
    {
      field: 'notificationType',
      resizable: true,
      initialHide:true,
      width: 210
    },
    {
      field: 'url',
      hide: true,

    },
    {
      field: 'dateOfNotification',
      headerName: 'Date',
      width: 120,
      editable: false,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        dateFormat: 'mm/dd/yyyy',
        comparator: this.agGridHelperService.dateFilterComparer
      },
      cellRenderer: DateCellRendererComponent,
    }
  ];


  public editMode = false;
  public gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
    },
    pagination: true,
    editType: 'fullRow',
    accentedSort: true,
    rowHeight: 40,
    suppressContextMenu: true,
    suppressClickEdit: true,
    suppressMenuHide: true,
    rowClassRules: {
      'disabled': (params: any) => {
        return this.editMode === true && params.data.disabled === true
      }
    }
  };

  public gridHeight: string = "";
  public gridHelpText: string = "";

  public frameworkComponents: any = {
  };

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public gridApi: any;
  possibleOrgsToAdd: any;
  public selectedNotification: DashboardNotification = null;

  constructor(
    private dropdownService: DropdownService,
    private mainService: MainService,
    private loggingService: LoggingService,
    private agGridHelperService: AGGridHelperService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.gridHelpText = this.agGridHelperService.gridInfoHelpText;
  }

  public onGridReady(params: GridReadyEvent) {

    //calc min/max grid height 
    this.gridHeight = this.agGridHelperService.calculateMinMaxGridHeight(this.notifications.length, 1, 3, 5);

    this.gridApi = params.api;
    this.gridApi.onActionTrigger = (event: any) => {
      this.triggerAction(event);
    };

    this.gridApi.sizeColumnsToFit();

    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    }
  }

  private triggerAction(event: any) {
    switch (event.action) {
      case "dismiss":
        //this.modalVisible = true;
        this.selectedNotification = event.data as DashboardNotification;
        break;
    }
  }
  public exportToCSV()
  {
    this.agGridHelperService.exportDataToExcel("Notifications", "sheet1", this.gridOptions, false);
  }
}
