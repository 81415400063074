import { ContentChild, ContentChildren, Directive, ElementRef, HostListener, OnDestroy, QueryList} from '@angular/core';
import { Subscription } from 'rxjs';
import { MainNavService } from '../../services/main-nav.service';
import { NavSubMenuHeaderDirectiveDirective } from './nav-sub-menu-header.directive';
import { NavSubMenuItemDirectiveDirective } from './nav-sub-menu-item.directive';

@Directive({
	selector: '[appNavSubMenuParentDirective]'
})
export class NavSubMenuParentDirectiveDirective implements OnDestroy{
	//sub menu items
	private currentActiveSubMenuItem: NavSubMenuItemDirectiveDirective = new NavSubMenuItemDirectiveDirective(this._mainNavService, undefined);
	@ContentChildren(NavSubMenuItemDirectiveDirective, { descendants: true }) subMenuItems: QueryList<NavSubMenuItemDirectiveDirective> = new QueryList();

	//sub menu header button
	@ContentChild(NavSubMenuHeaderDirectiveDirective) subMenuButton!: NavSubMenuHeaderDirectiveDirective;
	private currentSelectSubMenuHeader: NavSubMenuHeaderDirectiveDirective = new NavSubMenuHeaderDirectiveDirective(this._mainNavService, undefined);

	//subscriptions
	 private mainNavMenuParentSubscription = new Subscription();


	//mouse over
	@HostListener('mouseenter', ['$event']) 
	hasOver(event: any) {
		this._mainNavService.broadcastIsSubMenuParentOver(this.subMenuButton);
	}
	//mouse out
	@HostListener('mouseleave', ['$event'])
	hasOut(event: any) {
		this._mainNavService.broadcastIsSubMenuParentOut(this.subMenuButton);
	}	

	constructor(
		private _mainNavService: MainNavService,
		public elRef?: ElementRef) {

    const selectedSubMenuHeader = this._mainNavService.selectedSubMenuHeader.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
			this.currentSelectSubMenuHeader = value;
    });
    this.mainNavMenuParentSubscription.add(selectedSubMenuHeader);

    const setSelectedSubMenuHeaderSubscription = this._mainNavService.setSelectedSubMenuHeader.subscribe((value: string) => {
			//only call if this matches the currently selected sub header
			if (this.currentSelectSubMenuHeader == this.subMenuButton) {
				switch (value) {
					case "firstSubItem":
						this.setFirstSubNavItemFocus();
						break;
					case "nextSubItem":
						this.setNextSubNavMenuItemFocus();
						break;
					case "previousSubItem":
						this.setPreviousSubNavMenuItemFocus();
						break;
					case "lastSubItem":
						this.setLastSubNavItemFocus();
					break;
					default:
						console.log("Missed case in NavSubMenuParentDirectiveDirective subscription: " + value);
				}
			}
    });
    this.mainNavMenuParentSubscription.add(setSelectedSubMenuHeaderSubscription);

    const selectedSubMenuHeaderByEscSubscription = this._mainNavService.selectedSubMenuHeaderByEsc.subscribe((value: boolean) => {
			if (this.currentSelectSubMenuHeader == this.subMenuButton) {
				//send top level highlight, without sub menu expand
				this._mainNavService.broadcastSelectedHeaderNoExpand(this.currentSelectSubMenuHeader);
			}
    });

    this.mainNavMenuParentSubscription.add(selectedSubMenuHeaderByEscSubscription);

	}

	ngOnDestroy():void{
    if (this.mainNavMenuParentSubscription){
      this.mainNavMenuParentSubscription.unsubscribe();
		}
	}



	/* SUB menu items */
	/**
	 * Highlight last sub menu item
	 */
	 private setLastSubNavItemFocus(): void {
		if (this.subMenuItems.last.elRef) {
			this.setNavSubmenuItemFocus(this.subMenuItems.last);
		}
	}

	/**
	 * Highlight first sub menu item
	 */
	private setFirstSubNavItemFocus(): void {
		if (this.subMenuItems.first.elRef) {
			this.setNavSubmenuItemFocus(this.subMenuItems.first);
		}
	}
	/**
	 * Highlight next sub menu item
	 */
	private setNextSubNavMenuItemFocus(): void {
		let index = this.getNavSubMenuIndex();
		const next = this.subMenuItems.get(index + 1);
		//set next focus
		if (next && next.elRef) {
			this.setNavSubmenuItemFocus(next);
		} else {
			//set FIRST focus
			this.setFirstSubNavItemFocus();
		}
	}
	/**
	 * Highlight previous sub menu item
	 */
	private setPreviousSubNavMenuItemFocus(): void {
		let index = this.getNavSubMenuIndex();
		const prev = this.subMenuItems.get(index - 1);
		//set prev focus
		if (prev && prev.elRef) {
			this.setNavSubmenuItemFocus(prev);
		} else {
			//set LAST focus
			if (this.subMenuItems.last.elRef) {
				this.setNavSubmenuItemFocus(this.subMenuItems.last);
			}
		}
	}
	/**
	 * Returns index of currently active sub menu item
	 */
	private getNavSubMenuIndex(): any {
		let rtnIndex = -1;
		this.subMenuItems.toArray().forEach((navMenuItem, index) => {
			if (navMenuItem === this.currentActiveSubMenuItem) {
				rtnIndex = index;
			}
		});
		return rtnIndex;
	}
	/**
	 * Focus and set currently selected sub menu item
	 */
	private setNavSubmenuItemFocus(navMenuItem: NavSubMenuItemDirectiveDirective): void {
		if (navMenuItem && navMenuItem.elRef) {
			this.currentActiveSubMenuItem = navMenuItem;
			//@ts-ignore (had to set timeout to get the focus to work, seems to be a known issue)
			setTimeout(() => navMenuItem.elRef.nativeElement.focus({ focusVisible: true }));
		}
	}
}


