<div class="feedback-container">
  <div *ngIf="downloadCount!='Error' && downloadCount!='0' && !isNaN(downloadCount) && downloadCount<=250 && IsValidInputDate" style="background-color:antiquewhite">Number of disclosures to be downloaded: {{downloadCount}}</div>
  <div *ngIf="downloadCount!='Error' && downloadCount!='0' && !isNaN(downloadCount) && downloadCount>250 && IsValidInputDate" style="background-color: darksalmon">Number of disclosures available: {{downloadCount}}. Only the latest 250 will be downloaded.</div>
  <div *ngIf="downloadCount=='0'" style="background-color:darkorange">No disclosures available for download with current filter</div>
  <div *ngIf="downloadCount=='Calculating'" style="background-color:yellow">Calculating disclosure download count.</div>
  <div *ngIf="downloadCount=='Error'" style="background-color:red">Error retrieving disclosure download count.</div>
  <div *ngIf="!IsValidInputDate" style="background-color:red">Invalid date range provided, please provide a valid range and try again.</div>
</div>
<form [formGroup]="fg">
  <div class="section">
    <div class="tooltip-wrapper">
      <label for="jobDateStart">
        Job Start Date:
      </label>
      <input type="date" name="jobDateStart" formControlName="jobDateStart"
             (blur)="formUpdated($event)" class="input-box" min="1000-01-01" max="9999-12-31" />
      <app-popper-tool-tip screenReaderText="Start date filter of the range of jobs performed"></app-popper-tool-tip>
    </div>
  </div>
  <div class="section">
    <div class="tooltip-wrapper">
      <label for="jobDateEnd">
        Job End Date:
      </label>
      <input type="date" name="jobDateEnd" formControlName="jobDateEnd"
             (blur)="formUpdated($event)" class="input-box" min="1000-01-01" max="9999-12-31" />
      <app-popper-tool-tip screenReaderText="End date filter of the range of jobs performed"></app-popper-tool-tip>
    </div>
  </div>
  <div class="section-clearable">
    <div class="tooltip-wrapper">
      <label for="state">State:</label>
      <button uiLibButton
              *ngIf="state"
              class="no-wrap m-r--25"
              [color]="'PRIMARY' | buttonColorEnumPipe"
              [size]="'SMALL' | buttonSizeEnumPipe"
              [shape]="'ROUNDED' | buttonShapeEnumPipe"
              [style]="'SOLID' | buttonStyleEnumPipe"
              (click)="clearState()">
        Clear State
      </button>
      <select formControlName="state" name="state" [disabled]="true" class="select-dropdown" (change)="formUpdated($event)">
        <option value="" [selected]="true" disabled>Select a State</option>
        <option *ngFor="let st of states" [value]="st.stateId">{{st.stateName}}</option>
      </select>
      <app-popper-tool-tip screenReaderText="State filter where the wells are located"></app-popper-tool-tip>
    </div>
  </div>
  <div class="section-clearable" *ngIf="(currentOrg && (currentOrg.isRegisteredAgent || currentOrg.isServiceComp)) && operatorNames && operatorNames.length > 0">
    <div class="tooltip-wrapper">
      <label for="operator">Operator:</label>
      <button uiLibButton
              *ngIf="operator"
              class="no-wrap m-r--25"
              [color]="'PRIMARY' | buttonColorEnumPipe"
              [size]="'SMALL' | buttonSizeEnumPipe"
              [shape]="'ROUNDED' | buttonShapeEnumPipe"
              [style]="'SOLID' | buttonStyleEnumPipe"
              (click)="clearOperator()">
        Clear Operator
      </button>
      <select formControlName="operator" name="operator" class="select-dropdown"
              (change)="selectedOperatorNameSet()">
        <option *ngFor="let o of operatorNames" [value]="o">{{o}}</option>
      </select>
      <app-popper-tool-tip screenReaderText="Operator filter of the company that operates the well"></app-popper-tool-tip>
    </div>
  </div>
  <div class="tooltip-wrapper">
    <label for="fileType">Select File Format:</label>
    <div class="method-selector-radios-container">
      <div>
        <label for="dataXML">
          XML
          <input type="radio" formControlName="downloadType"
                 (change)="formUpdated($event)" name="downloadType" id="dataXML" value="XML" />
        </label>
      </div>
      <div>
        <label for="dataCSV">
          CSV<input type="radio" formControlName="downloadType"
                    (change)="formUpdated($event)" name="downloadType" id="dataCSV" value="CSV">
        </label>
      </div>
    </div>
    <app-popper-tool-tip screenReaderText="Select file format of the data to be downloaded"></app-popper-tool-tip>
  </div>
</form>
<div class="download-button m-t-1">
<div class="tooltip-wrapper">
  <button uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'MEDIUM' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe"
          [style]="'SOLID' | buttonStyleEnumPipe"
          (click)="download()"
          [disabled]="!isSubmissionDatesValid">
    Download
  </button>
  <app-popper-tool-tip screenReaderText="Download disclosure data that meet the specified filter criteria"></app-popper-tool-tip>  
</div>
  <p *ngIf="!isSubmissionDatesValid">Submission Start Date must be less than Submission End Date.</p>
</div>
