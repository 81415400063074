import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { LoggingService } from './logging.service';
import { FracJob } from '../models/disclosures/frac-job.model';

@Injectable()
export class FileService {

  constructor(private http: HttpClient, _logginService: LoggingService) {
    //replaced bootstrap toastr service here, although it's not being used yet
    //to call use something like this: this._logginService.info("INFO: auto hides after 2 seconds", null);
  }

  uploadNewXmlDisclosure(orgid: string, operatorId: string, nogSupported: boolean, file: File,
    location: string = `/api/file/UploadDisclosureXML/${orgid}/${operatorId}/${nogSupported}`): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', location, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  uploadXmlForExistingDisclosure(orgId: string, disclosureId: string, behaviour: string, nogSupported: boolean, file: File,
    location: string = `/api/file/UploadExistingDisclosureXML/${orgId}/${disclosureId}/${behaviour}/${nogSupported}`): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', location, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
  public admingenerateDisclosurePdf(orgId: string, disclosureId: string) {
    return this.http.get(`/api/file/adminGenerateDisclosurePdf/${orgId}/${disclosureId}`, { observe: 'response', responseType: 'blob' });
  }
  public generateDisclosurePdf(orgId: string, disclosureId: string) {
    return this.http.get(`/api/file/GenerateDisclosurePdf/${orgId}/${disclosureId}`, {observe:'response', responseType:'blob'});
  }

  public getDisclosurePdf(disclosureId: string) {
    return this.http.get(`/api/file/GetDisclosurePdf/${disclosureId}`, {observe:'response', responseType:'blob'});
  }

  public generateDisclosureXml(orgId: string, disclosureId: string): Observable<Blob>{
    return this.http.get(`/api/file/GenerateDisclosureXml/${orgId}/${disclosureId}`, { observe: 'body', responseType: 'text' })
    .pipe(
      map(res => new Blob([res as string], { type: 'text' }))
    );
  }

}
