<div class="history-list" [ngStyle]="{'height': historyContainerHeight}">
    <div class="history-header-container m-b-1">
        <div><b>History</b></div>
        <div><a (click)="closeHistory()" title="{{linkTitle}}">Close History</a></div>
    </div>
    <div *ngFor="let item of history">
        <div class="m-b--50">{{item.changeDetails}} on {{formatDate(item.changeDate)}} by {{item.changeBy}}</div>
    </div>
    <div *ngIf="history.length === 0" class="m-b--50">
        No history data found
    </div>
</div>
