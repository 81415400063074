import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, finalize, Subscription } from 'rxjs';
import { Organization } from '../../models/organizations/organization.model';
import { FFUserWithRoles } from '../../models/users/ff-user-with-roles.model';
import { AuthService } from '../../services/auth.service';
import { DisclosureService } from '../../services/disclosure.service';
import { UserService } from '../../services/user.service';
import { MainService } from '../../ui/services/main.service';
import { getUserOrgs } from '../../models/users/ff-user.model';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public userInfo: FFUserWithRoles;
  primaryOrg: Organization;
  public notifications: any[];
  public userOrgs: Organization[];

  constructor(
    private _mainService: MainService,
    private disclosureService: DisclosureService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.authService.getUserInfo().then(res => {
      this.userInfo = res;
      this.primaryOrg = this.userInfo.getCurrentOrg();
      if (this.primaryOrg) {
        this.userService.getNotificationsForOrg(this.primaryOrg.organizationId).subscribe(res => {
          this.notifications = res;
        }, err => {

        })
      } else {
        this.userOrgs = getUserOrgs(this.userInfo);
        if (this.userOrgs && this.userOrgs.length > 0) {
          this._mainService.showLoading({ loading: false });
          this.router.navigate(["/switch-organization"]);
        }
      }
    })

    //We need to know whenever they navigate away from the dashboard to shift the layout
    let routerNavigationSubscription$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this._mainService.mainDashboardLayout(false);
        this.subscription.add(routerNavigationSubscription$);
      }, error => {
        console.log("Failed to mainDashboardLayout value to false.");
        this.subscription.add(routerNavigationSubscription$);
      });
  }

  ngAfterViewInit(): void {
    this._mainService.mainDashboardLayout(true);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  
}
