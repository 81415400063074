import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { CellPosition, ColumnResizedEvent, TabToNextCellParams } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { isNotNullOrEmpty } from '../../../../common/functions/is-not-null-or-empty.function';
import { OnLeavingEditorParams } from '../../../models/base-cell-editor/on-leaving-editor-params.model';
import { ISettableTextCellEditorParams } from '../../../models/settable-text-cell-editor-params.model';

@Component({
  selector: 'settable-text-cell-editor',
  templateUrl: './settable-text-cell-editor.component.html',
  styleUrls: ['./settable-text-cell-editor.component.scss']
})
export class SettableTextCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  public cellWidth: string = "";
  public cellHeight: string = "";
  public isValid: boolean = true;
  public isEnabled: boolean = true;
  public isRequired: boolean = true;
  public originalValue: string = "";
  public value: string = "";
  private params: ISettableTextCellEditorParams;
  private subscription: Subscription = new Subscription();
  private readonly specialCharRegex = /[^\w\d\s'&"\-,.=+_!@#$%*():;\/\\]/gi;
  private readonly currentValue = new Subject<string | undefined>();

  @ViewChild('textInput') input: ElementRef;

  constructor() {
  }

  public agInit(params: ISettableTextCellEditorParams): void {
    this.params = params;
    this.setInitialState(params);

    if (this.params.dataChangedEvent) {
      this.subscription.add(this.currentValue
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe((currentValue) => {
          this.params.dataChangedEvent.emit({ value: currentValue, field: this.params.colDef.field, itemId: this.params.node.id });
          this.checkValidity(currentValue);
        }));
    }

    if (params.isRequired != null && params.isRequired != undefined) {
      this.isRequired = params.isRequired;
      this.checkValidity(params.value);
    }
    else
      this.isRequired = false;

    if (params.isEnabled != null && params.isEnabled != undefined) {
      if (params.isEnabled instanceof Function)
        this.isEnabled = params.isEnabled(params);
      else
        this.isEnabled = params.isEnabled;
    }
    else
      this.isEnabled = true;
    

    if (params.isValid != null && params.isValid != undefined) {
      if (params.isValid instanceof Function) {
        if (params.initialDisclosureValidationStatements && params.initialDisclosureValidationStatements.length > 0)
          this.isValid = params.isValid(params.initialDisclosureValidationStatements, this.params.fieldName, this.params.idType, this.params.node.id);
      }
      else
        this.isValid = params.isValid;
    }
    else
      this.isValid = true;

    (this.params.api as GridApi).addEventListener("columnResized",
      (event: ColumnResizedEvent) => {
        if ((event.column && event.column.getId() == this.params.colDef.colId) || event.column === null || this.params.colDef.colId === undefined)
          this.cellWidth = (params.column.getActualWidth()) - 23 + 'px';
      }
    );

    this.cellWidth = (params.column.getActualWidth()) - 23 + 'px';
    this.cellHeight = (params.node.rowHeight) - 2 + 'px'; 
  }

  private checkValidity(currentValue: string): void {
    this.isValid = (this.isRequired && !isNotNullOrEmpty(currentValue)) ? false : true;
  }

  public ngAfterViewInit() {
  }

  public ngOnDestroy() {
    this.focusOut();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getValue(): any {
    if (this.value != null && this.value != undefined)
      return this.value.trim();
  }

  setInitialState(params: ISettableTextCellEditorParams) {
    if (params.value != undefined && params.value != null) {
      this.originalValue = JSON.parse(JSON.stringify(params.value));
    }
    this.value = params.value;
    this.currentValue.next(this.value);
  }

  public setValidityStatus(isValid: boolean): void {
    this.isValid = isValid;
  }

  afterGuiAttached(): void {
    let focusedCell = this.params.api.getFocusedCell();
    if (!focusedCell) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.colId, this.params.node.rowPinned);
      focusedCell = this.params.api.getFocusedCell();
    }
    if (focusedCell.column.getColId() == this.params.colDef.colId) {
      this.focusIn();
    }
  }

  focusOut(): void {
    //On focus out, check if the value has changed, if so patch it
    if (this.originalValue != this.value && this.params.onLeavingEditor && !this.params.node.isRowPinned()) {
      this.params.onLeavingEditor({
        data: this.params.data,
        patchInfo: [{
          fieldToPatch: "/" + this.params.colDef.field,
          valueToPatch: this.value
        }]
      } as OnLeavingEditorParams);

      this.params.node.data[this.params.colDef.field] = JSON.parse(JSON.stringify(this.value));
      this.originalValue = JSON.parse(JSON.stringify(this.value));
    }
  };

  focusIn(): void {
    if (this.input.nativeElement) {
      let input = this.input.nativeElement as HTMLInputElement;
      //If the input is disabled, just move on to next cell
      if (input.disabled) {
        input.disabled = false;
        input.focus();
        input.disabled = true; 
      }
      else {
        this.input.nativeElement.focus();
      }
    }
    
  };

  setValue(newValue: string): void {
    this.value = newValue;
    this.params.node.data[this.params.colDef.field] = newValue;
    this.checkValidity(newValue);
    if (this.params.dataChangedEvent) {
      this.params.dataChangedEvent.emit({ value: newValue, field: this.params.colDef.field, itemId: this.params.node.id });
    }
  }

  public onKeyDown(event: any): void {
    this.sanitizeString(event.target.value);

    if (event.key == "Escape") {
      this.focusOut();
    }
  }

  public onKeyUp(event: any): void {
    this.sanitizeString(event.target.value);

    this.currentValue.next(event.target.value);
  }

  sanitizeString(value: string) {
    let invalidCharactersPresent = this.specialCharRegex.test(value);
    if (invalidCharactersPresent) {
      value = value.replace(this.specialCharRegex, "");
      this.value = this.value.replace(this.specialCharRegex, "");
    }
  }
}
