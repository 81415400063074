import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderData } from '../models/loader-data.model';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  /******************************************************/
  //TODO Make Observables?
  isDashboard: Subject<boolean> = new Subject<boolean>();
  mainHeight: Subject<number> = new Subject<number>();
  isLoading: Subject<any> = new Subject<any>();
  /******************************************************/
  public initialMainHeight: number = 0;



  constructor() { }

  public mainDashboardLayout(value: boolean) {
    this.isDashboard.next(value);
  }

  public showLoading(loadingData: LoaderData) {
    this.isLoading.next(loadingData);
  }

  public availableMainHeight(value: number) {
    this.initialMainHeight = value;
    this.mainHeight.next(value);
  }

}
