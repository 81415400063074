import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';


@Component({
  selector: 'always-active-checkbox-cell',
  templateUrl: './always-active-checkbox-cell.component.html',
  styleUrls: ['./always-active-checkbox-cell.component.scss']
})
export class AlwaysActiveCheckboxCellComponent implements ICellRendererAngularComp {

  private cellName: string = '';

  public data: any;
  public val: boolean = false;
  public originalData: any;
  public api: any;
  public rowIndex: number = -1;
  public columnName: any;
  public isDisabled:boolean = false

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  public agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.val = params.value;
    this.api = params.api;
    this.rowIndex = params.rowIndex;
    this.cellName = params?.colDef?.cellRenderer?.toString() || '';
    this.columnName = (params.column as any).colId;
    if (this.columnName === 'role-Supervisor') {
      //If disabled value is not null, set it. Otherwise keep default value
      const disabled = params.data.isCheckboxDisabled || params.data.cannotEdit;
      this.isDisabled = !!disabled ? disabled : this.isDisabled;
    }
    if (this.columnName === 'role-DataSubmitter') {
      this.isDisabled = params.data.cannotEdit;
    }
  }

  public refresh(params: any): boolean {
    this.rowIndex = params.rowIndex;
    return false;
  }


  public save(): void {
    if (this.api.onActionTrigger) {
      //this.api.onActionTrigger({ row: this.rowIndex, action: "editComplete", data: this.data });
    }
  }
  public valChanged(event:any) {
    if (this.api.onActionTrigger) {
      this.api.onActionTrigger({ row: this.rowIndex, action: "roleUpdate", data: this.data, column: this.columnName, value: this.val });
    }
  }
}
