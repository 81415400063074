import { Component, OnInit } from '@angular/core';
import { DropdownService } from '../common/services/dropdown.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public isOpenPageModalShowing: boolean = false
  public linkToOpen: string = '';
  globalDefaults: any[] | null;
  public supportEmail: any;

  constructor(private dropdownService: DropdownService) { }

  ngOnInit(): void {
    this.dropdownService.getGlobalDefaults().then(gd => {
      this.supportEmail = gd.find(x => x.globalDefaultId == "SupportEmail")?.textValue
    });
  }

  public closeOpenPageModal() { this.isOpenPageModalShowing = false, this.linkToOpen = '' }

  public openExternalLink(link: string) {
    this.linkToOpen = link;
    this.isOpenPageModalShowing = true;
  }

  public handleOk() {
    window.open(this.linkToOpen, "_blank");
    this.isOpenPageModalShowing = false;
  }

  public handleCancel() {
    this.isOpenPageModalShowing = false;
    this.linkToOpen = '';
  }

}
