<div class="default-container max-width">
  <h1 class="h3-size txt-trns-upper clr-tertiary-3 font-bold m-b-1-5 aln-center">Welcome</h1>
  <div class="home-container">
    <div>
      <p class="m-t-0">
        This Chemical Disclosure Registry website (FracFocus) facilitates the data collection of chemicals used during the fracturing of oil and gas wells.  For access to the data and educational materials on FracFocus please visit the public website at 
        <span class="h4-size clr-tertiary-3 font-bold"><a class="cursor-pointer" href="http://www.fracfocus.org" target="_blank">FracFocus.Org.</a></span> These websites, hosted by the 
          <span class="h4-size clr-tertiary-3 font-bold"><a class="cursor-pointer" href="http://www.gwpc.org" target="_blank">Ground Water Protection Council</a></span> (GWPC), provide a central location for public and industry to communicate and relay information on the chemicals used during the process of hydraulic fracturing of oil and gas wells. The FracFocus website provides education tools to the public on the topic of hydraulic fracturing in relation to the data collected here.
      </p>
      <p>
        GWPC is uniquely suited to host these websites due to their impartial nature and ties to the regulatory community. This website provides a means for industry to supply hydraulic fracturing chemical data in a consistent and centralized location.  This open process assists both public and industry by supplying a centralized repository for the data.
      </p>
      <div>These websites provide the following:</div>
      <ul>
        <li class="m-b--75">
          A means to Search for submitted chemical records by State/County, Operator and Well.
        </li>
        <li>
          An Education and Informative site for the public and industry on Hydraulic Fracturing Chemicals.
        </li>
      </ul>
      <p>
        FracFocus has instituted a Help Desk to address any issues you may have in using the system. You can reach the Help Desk by sending an email to: <span class="h4-size clr-tertiary-3 font-bold"><a [href]="'mailto:' + supportEmail">{{supportEmail}}</a></span>.
      </p>
    </div>
    <div class="aln-center m-t-2" *ngIf="!userInfo || (userInfo && !userInfo?.userName)">
      <a uiLibButton
         [color]="'PRIMARY' | buttonColorEnumPipe"
         [size]="'MEDIUM' | buttonSizeEnumPipe"
         [shape]="'ROUNDED' | buttonShapeEnumPipe"
         href="authentication/register"
         target="_blank">
        Register Company
      </a>
    </div>
  </div>
</div>

<ui-modal id="openpage-modal" (closed)="closeOpenPageModal()" [paddedContent]="true" [hasCloseButton]="true"
  [isVisible]="isOpenPageModalShowing" [modalSize]=" 'MEDIUM' | modalSizeEnumPipe" modalTitle="Open an external link?">
  <div modalContent class="flex-vrt">
    <p class="aln-center">
      The system is about to open an external page, do you wish to continue?
    </p>
    <div class="dis-flex open-page">
      <button uiLibButton [color]="'PRIMARY' | buttonColorEnumPipe" [size]="'MEDIUM' | buttonSizeEnumPipe"
        [shape]="'ROUNDED' | buttonShapeEnumPipe" [style]="'SOLID' | buttonStyleEnumPipe"
        (click)="handleOk()">OK</button>
      <button uiLibButton [color]="'PRIMARY' | buttonColorEnumPipe" [size]="'MEDIUM' | buttonSizeEnumPipe"
        [shape]="'ROUNDED' | buttonShapeEnumPipe" [style]="'SOLID' | buttonStyleEnumPipe"
        (click)="handleCancel()">CANCEL</button>
    </div>
  </div>
</ui-modal>
