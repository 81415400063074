import { Pipe, PipeTransform } from '@angular/core';
import { IconButtonColor } from './icon-button-color.enum';

@Pipe({
  name: 'iconButtonColorEnumPipe'
})
export class IconButtonColorEnumPipe implements PipeTransform {

  constructor() { }

  public transform(value: string): IconButtonColor {
    switch (value) {
      case 'BLUE':
        return IconButtonColor.BLUE;
      case 'RED':
        return IconButtonColor.RED;
      case 'BLACK':
        return IconButtonColor.BLACK;
      case 'GREEN':
        return IconButtonColor.GREEN;
      case 'GRAY':
        return IconButtonColor.GRAY;
      case 'WHITE':
        return IconButtonColor.WHITE;
      default:
        return IconButtonColor.BLACK;
    }
  }
}
