import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OrgRole } from "../models/organizations/org-role.model";
import { FFUser } from "../models/users/ff-user.model";
import { UserMapping } from "../models/users/user-mapping.model";
import { LoggingService } from "./logging.service";

export function convertArrayToDictionary(items: any[], labelProperty: string, valueProperty: string, includeEmptyValue: boolean = false): any {
  let dictionary: any = {};
  if (includeEmptyValue) {
    dictionary[''] = '';
  }
  items.forEach((item: any) => {
    dictionary[item[labelProperty]] = item[valueProperty];
  });
  return dictionary;
}

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export function getDistinct<T, K extends keyof T>(data: T[], property: K): T[K][] {
  const allValues = data.reduce((values: T[K][], current) => {
    if (current[property]) {
      values.push(current[property]);
    }
    return values;
  }, []);

  return [...new Set(allValues)];
}

export function descendingStringComparer(a: string, b: string){
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  return b.localeCompare(a);
}

export function ascendingDateComparer(firstDate: Date, secondDate: Date){
  if (firstDate === null || firstDate === undefined) {
    return 1;
  }
  if (secondDate === null || secondDate === undefined) {
    return -1;
  }
  const dateA = new Date(firstDate).getTime();
  const dateB = new Date(secondDate).getTime();

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
}

export function descendingDateComparer(firstDate: Date, secondDate: Date){
  if (firstDate === null || firstDate === undefined) {
    return 1;
  }
  if (secondDate === null || secondDate === undefined) {
    return -1;
  }
  const dateA = new Date(firstDate).getTime();
  const dateB = new Date(secondDate).getTime();

  if (dateA > dateB) {
    return -1;
  }
  if (dateA < dateB) {
    return 1;
  }
  return 0;
}

export function ascendingStringComparer(a: string, b: string){
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  return a.localeCompare(b);
}


export function getFlattenedUsersFromMap(userMappings: Array<UserMapping>): Array<FFUser> {
  if (!userMappings) return null;
  let yous: any[] = [];
  for (let m = 0; m < userMappings.length; m++) {
    let mp = userMappings[m];
    if (yous.find(x => x.id == mp.userId)) {
      // add role here
      const match = yous.find(x => x.id == mp.userId) as any;
      match['role-' + mp.orgRole.value] = true;
      match.status = getUserStatus(match, userMappings);
      match.officeRegion = getUserOfficeRegion(match, userMappings);
      match.createdByName = mp.createdByName;
      match.disabled = true;
    } else {
      (mp.user as any)['role-' + mp.orgRole.value] = true;
      (mp.user as any).status = getUserStatus(mp.user, userMappings);
      (mp.user as any).officeRegion = getUserOfficeRegion(mp.user, userMappings);
      (mp as any).createdByName = mp.createdByName;
      (mp as any).user.disabled = true;
      yous.push(mp.user);
    }
  }
  return yous;
}
export function getUserStatus(user: any, userMappings: Array<UserMapping>): string {
  if (!user.emailConfirmed) return "Pending";
  const mapping = userMappings.find(x => x.userId == user.id && x.orgRoleId.toLowerCase() == OrgRole.USERROLEID.toLowerCase());
  if (!mapping) return "Inactive";
  return mapping.isActive ? "Active" : "Inactive";
}

export function getUserOfficeRegion(user: any, userMappings: Array<UserMapping>): string {
  const mapping = userMappings.find(x => x.userId == user.id && x.orgRoleId.toLowerCase() == OrgRole.USERROLEID.toLowerCase());
  if (!mapping) return "";
  return mapping.officeRegion;
}

@Injectable()
export class UtilityService {
  constructor(
    private http: HttpClient,
    private loggingService: LoggingService
  ) {
  }

  public generateGuid(): Observable<string> {
    return this.http.get<string>('/api/Utilities/generate-guid/');
  }
}
