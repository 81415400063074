import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { concatMap, finalize, Subscription, tap } from 'rxjs';
import { MainService } from '../app/common/ui/services/main.service';
import { LoaderSize, LoaderStyle, FADE_IN_OUT, ToasterPosition, ModalComponent, LoaderComponent } from 'ui-library-shared';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthService } from './common/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { FFUserWithRoles } from './common/models/users/ff-user-with-roles.model';
import { LoaderData } from './common/ui/models/loader-data.model';
import { DropdownService } from './common/services/dropdown.service';
import { ClientConfig } from './common/models/client-config.model';
import { AnalyticsOptions } from './common/models/analytics-options.model';
import { LoggingService } from './common/services/logging.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [FADE_IN_OUT],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Frac Focus';
  private mainSubscription = new Subscription();
  public isDashboardLayout: boolean = false;
  public loadingText: string | null;
  public loaderSize: LoaderSize = LoaderSize.MEDIUM;
  public loaderStyle: LoaderStyle = LoaderStyle.DIM_DARK;
  public toasterPosition: ToasterPosition;
  public displayTimeoutModal: boolean = false;
  public IDLE_INTERVAL_IN_SECONDS = 10;
  private TIMEOUT_INTERVAL_IN_SECONDS = 10;
  public secondsLeftToLogout = 10;
  private clientConfig: ClientConfig = null;
  private _analyticsOptions: AnalyticsOptions = null;

  lastPing?: Date = null;

  @ViewChild('main') main!: ElementRef;
  @ViewChild('userTimeoutModal') userTimeoutModal: ModalComponent;
  @ViewChild('loader') loader: LoaderComponent;

  @HostBinding('class.dashboard') get t() {
    return this.isDashboardLayout;
  }

  constructor(
    private _mainService: MainService,
    private _authService: AuthService,
    private _dropdownService: DropdownService,
    private _idle: Idle,
    public _router: Router,
    private _changeDetection: ChangeDetectorRef,
    private _loggingService: LoggingService,
    private _zone: NgZone ) {
    
  }

  ngOnInit(): void {
    this._dropdownService.getAnalyticsOptions().then(analyticsOptions => {
      this._analyticsOptions = analyticsOptions;
    }).then(() => {
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', this._analyticsOptions.tag_Id, { 'page_path': event.urlAfterRedirects });
        }
      })
    });

   

    this._dropdownService.getClientConfig().then(config => {
      this.clientConfig = config;
    }).then(() => {
      //The source config values are in minutes, so convert
      this.TIMEOUT_INTERVAL_IN_SECONDS = this.clientConfig.idleTimeout * 60;
      this.IDLE_INTERVAL_IN_SECONDS = this.clientConfig.idleWarningInterval * 60;
      // sets the idle interval
      this._idle.setIdle(this.IDLE_INTERVAL_IN_SECONDS);
      // sets an idle timeout
      this._idle.setTimeout(this.TIMEOUT_INTERVAL_IN_SECONDS);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    });

    this.toasterPosition = ToasterPosition.TOP_RIGHT;

    const dashboardSubscription$ = this._mainService.isDashboard.pipe(finalize(() => {
      this.mainSubscription.add(dashboardSubscription$);
    })).subscribe((value: boolean) => {
      setTimeout(() => {
        this.isDashboardLayout = value;
      })
    }, error => {
      console.log("Failed to fetch the dashboard layout.");
    });

    const loaderSubscription$ = this._mainService.isLoading.pipe(finalize(() => {
      this.mainSubscription.add(loaderSubscription$);
    })).subscribe((loadingData: LoaderData) => {
      this.loader.isLoading = loadingData.loading;
      if (loadingData.loadingText) {
        this.loader.loaderText = loadingData.loadingText;
      }
    }, error => {
      console.log("Failed to check whether we're loading'.");
    });

    //hook up to auth service emitter
    const srv$ = this._authService.userInfoEmitter.pipe(finalize(() => {
      this.mainSubscription.add(srv$);
    })).subscribe(result => {
      if (this._authService.authenticated) {
        this._idle.watch();
      }
    }, error => {
      console.log('Error listening for auth service user info. ' + error);
      this._loggingService.errorSilent("app.component Error listening for auth service user info", "app.component Error listening for auth service user info " + error);
    });

    const idleStartSubscription$ = this._idle.onIdleStart.pipe(finalize(() => {
      this.mainSubscription.add(idleStartSubscription$);
    })).subscribe(() => {
      //this._loggingService.info("onIdleStart");
      this.displayTimeoutModal = true;
    }, error => {
      console.log("Failed to handle idleStart status." + error);
      this._loggingService.errorSilent("onIdleStart Error", "onIdleStart Error " + error);
    });

    const idleEndSubscription$ = this._idle.onIdleEnd.pipe(finalize(() => {
      this.mainSubscription.add(idleEndSubscription$);
    })).subscribe(() => {
      //this._loggingService.info("onIdleEnd");
      this.displayTimeoutModal = false;
      this.userTimeoutModal.closeModal();
      this._changeDetection.detectChanges();
    }, error => {
      console.log("Failed to handle idleEnd status." + error);
      this._loggingService.errorSilent("onIdleEnd Error", "onIdleEnd Error " + error);
    });

    let idleTimeoutSubscription$ = this._idle.onTimeout.pipe(
      tap(() => {
        this.displayTimeoutModal = false;
        this.userTimeoutModal.closeModal();
      }),
      concatMap(() => {
        return this._authService.logout(this._authService.userInfo.id);
      })
    ).subscribe((result: number) => {
      //this._loggingService.info("onTimeout");
      this.mainSubscription.add(idleTimeoutSubscription$);
      this._authService.resetServiceState();
      this._authService.userInfoEmitter.emit(new FFUserWithRoles);
      this._zone.run(() => {
        this._router.navigate(["/"]);
      });
    }, error => {
      this.mainSubscription.add(idleTimeoutSubscription$);
      console.log("Error trying to log user out on timeout" + error);
      this._loggingService.errorSilent("onTimeout Error", "onTimeout Error " + error);
    });

    const idleTimeoutWarningSubscription$ = this._idle.onTimeoutWarning.pipe(finalize(() => {
      //this._loggingService.info("onTimeoutWarning");
      this.mainSubscription.add(idleTimeoutWarningSubscription$);
    })).subscribe((countdown) => {
      this.secondsLeftToLogout = countdown;
    }, error => {
      console.log("Failed to handle idle timeout warning." + error);
      this._loggingService.errorSilent("onTimeoutWarning Error", "onTimeoutWarning Error " + error);
    });
  }

  ngAfterViewInit(): void {
    //set initial height
    this.onResize();
  }

  ngOnDestroy(): void {
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
    }
  }

  onResize(): void {
    /******************************************/
    //TODO: debounce?
    // 18 = account for <main> bottom padding (1rem = 16px) + border (1px) top/bottom: may not 2px in future if not using borders
    this._mainService.availableMainHeight(this.main.nativeElement.offsetHeight - 18);
    /******************************************/
  }
}
