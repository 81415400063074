import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderButtonRendererButtonParams } from '../../../models/header-button-renderer/header-button-renderer-button-params';
import { IHeaderButtonRendererParams } from '../../../models/header-button-renderer/header-button-renderer-params.model';

@Component({
  selector: 'header-button-renderer',
  templateUrl: './header-button-renderer.component.html',
  styleUrls: ['./header-button-renderer.component.scss']
})
export class HeaderButtonRendererComponent implements IHeaderAngularComp {
  public params!: IHeaderButtonRendererParams;

  public ascSort = 'inactive';
  public descSort = 'inactive';
  public noSort = 'inactive';

  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;

  agInit(params: IHeaderButtonRendererParams): void {
    this.params = params;

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
    this.params.setSort(order, event.shiftKey);
  }

  refresh(params: IHeaderButtonRendererParams) {
    return false;
  }

  public buttonDisabled(button: IHeaderButtonRendererButtonParams): boolean {
    if (button.enabledFunction instanceof Function) {
      return button.enabledFunction(button);
    }
    else {
      return false;
    }
  }

  public buttonClicked(button: IHeaderButtonRendererButtonParams): void {
    if (button.clickFunction instanceof Function) {
      button.clickFunction(button);
    }
  }
}
