<div class="default-container max-width">
    <h1 class="h3-size txt-trns-upper clr-tertiary-3 font-bold m-b-1-5 aln-center">About</h1>
    <div class="home-container">
        <p class="m-t-0">
            FracFocus is managed by the Ground Water Protection Council. The original development of FracFocus was a
            joint effort between the Ground Water Protection Council (GWPC) and the Interstate Oil and Gas Compact
            Commission (IOGCC). FracFocus has been developed, and will continue to be refined, based on the goals laid
            out in 2010 by members of both GWPC and IOGCC: to disseminate information on the chemical constituents used
            in hydraulic fracturing through the regulatory authority of the states.. The main goals of this site are to
            provide a central warehousing site for industry to provide chemical constituent data, on a well-by-well
            basis, and provide education on the processes and chemicals used to hydraulically fracture an oil and gas
            well in the U.S. For more information please visit the <span class="h4-size clr-tertiary-3 font-bold">
                <a class="cursor-pointer" (click)="openExternalLink('https://www.fracfocus.org')">
                    FracFocus Public Site
                </a></span> or email FracFocus Industry support at: <span class="h4-size clr-tertiary-3 font-bold"><a [href]="'mailto:' + supportEmail" target="_blank">{{supportEmail}}.</a></span>
        </p>
        <p>
            <b>Note: </b> <i>Use of this website and the data made available on the website is covered under the “Web
                Site Terms and Conditions of Use” document. This document is available from a link at the bottom of the
                page.</i>
        </p>
        <div class="gwpc-div">
            <span class="h4-size clr-tertiary-3 font-bold"><a class="cursor-pointer"
                    (click)="openExternalLink('http://www.gwpc.org/')">Ground Water Protection Council</a></span>
            <p>The Ground Water Protection Council is a national association of state ground water and underground
                injection control agencies whose mission is to promote the protection and conservation of ground water
                resources for all beneficial uses, recognizing ground water as a critical component of the ecosystem.
            </p>
            <p>The Ground Water Protection Council provides a forum for stakeholder communication and research in order
                to improve governments’ role in the protection and conservation of ground water.</p>
        </div>
    </div>
</div>
<ui-modal id="openpage-modal" (closed)="closeOpenPageModal()" [paddedContent]="true" [hasCloseButton]="true"
    [isVisible]="isOpenPageModalShowing" [modalSize]=" 'MEDIUM' | modalSizeEnumPipe"
    modalTitle="Open an external link?">
    <div modalContent class="flex-vrt">
        <p class="aln-center">
            The system is about to open an external page, do you wish to continue?
        </p>
        <div class="dis-flex open-page">
            <button uiLibButton [color]="'PRIMARY' | buttonColorEnumPipe" [size]="'MEDIUM' | buttonSizeEnumPipe"
                [shape]="'ROUNDED' | buttonShapeEnumPipe" [style]="'SOLID' | buttonStyleEnumPipe"
                (click)="handleOk()">OK</button>
            <button uiLibButton [color]="'PRIMARY' | buttonColorEnumPipe" [size]="'MEDIUM' | buttonSizeEnumPipe"
                [shape]="'ROUNDED' | buttonShapeEnumPipe" [style]="'SOLID' | buttonStyleEnumPipe"
                (click)="handleCancel()">CANCEL</button>
        </div>
    </div>
</ui-modal>
