import { ContentChildren, Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainNavService } from '../../services/main-nav.service';
import { NavSubMenuItemDirectiveDirective } from './nav-sub-menu-item.directive';

@Directive({
	selector: '[appNavSubMenuHeaderDirective]'
})
export class NavSubMenuHeaderDirectiveDirective implements OnInit, OnDestroy {
	@HostBinding('class.expanded') expanded: boolean = false;
	@HostBinding('attr.aria-expanded') expandedAria: boolean = false;
	isClickOnly: boolean = false;
	@ContentChildren(NavSubMenuItemDirectiveDirective, { descendants: true }) subMenuItems: QueryList<NavSubMenuItemDirectiveDirective> = new QueryList();

	isParentOver:boolean = false;

	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.setClickOnly();
	}

	/* MOUSE EVENTS > */
	//mouse over
	@HostListener('mouseenter', ['$event'])
	hasOver(event: any) {
		if (!this.isClickOnly) {
			if (this.expanded === false && this.isParentOver) {
				this.setExpanded(true);
			}
		}
	}
	//click -tablet and below
	@HostListener('click', ['$event'])
	hasClick(event: any) {
		if (this.isClickOnly) {
			this.toggleExpanded();
		}
	}
	/* < MOUSE EVENTS */

	/* KEYBOARD EVENTS > */
	//***************************************************/
	//EVENTS THAT TRAVERSE OUT OF MENU
	//TAB
	@HostListener('keydown.tab', ['$event'])
	onTab(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSetFocusOutsideNavMenu(false);
	}
		//SHIFT TAB
		@HostListener('keydown.shift.tab', ['$event'])
		onShiftTab(e: KeyboardEvent) {
			e.preventDefault();
			this._mainNavService.broadcastSetFocusOutsideNavMenu(true);
		}


	//***************************************************/
	//EVENTS THAT CHANGE FIRST LEVEL LINK FOCUS
	//LEFT/RIGHT ARROWS
	//arrow right
	@HostListener('keydown.arrowright', ['$event'])
	onRightArrow(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastMenuKey("h-ar");
	}
	//arrow left
	@HostListener('keydown.arrowleft', ['$event'])
	onLeftArrow(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastMenuKey("h-al");
	}
	//***************************************************/

	//***************************************************/
	//EVENTS THAT OPEN THE SUBMENU
	// enter
	@HostListener('keydown.enter', ['$event'])
	onEnter(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSelectedHeaderExpand(this);
		this._mainNavService.broadcastSetSelectedHeader("firstSubItem");
	}
	// space
	@HostListener('keydown.space', ['$event'])
	onSpace(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSelectedHeaderExpand(this);
		this._mainNavService.broadcastSetSelectedHeader("firstSubItem");
	}
	// arrow down
	@HostListener('keydown.arrowdown', ['$event'])
	onArrowDown(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSelectedHeaderExpand(this);
		this._mainNavService.broadcastSetSelectedHeader("firstSubItem");
	}
	// arrow up
	@HostListener('keydown.arrowup', ['$event'])
	onArrowUp(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSelectedHeaderExpand(this);
		this._mainNavService.broadcastSetSelectedHeader("lastSubItem");
	}
	//***************************************************/

	//focus
	@HostListener('focus', ['$event'])
	onFocus(e: KeyboardEvent) {
		e.preventDefault();
		this._mainNavService.broadcastSubHeaderFocus(this);
		//only trigger for keyboard event
		if (!this.isClickOnly) {
			this._mainNavService.broadcastSelectedHeaderNoExpand(this);
		}
	}
	/* < KEYBOARD EVENTS */

  private mainNavSubscription = new Subscription();


	constructor(
		private _mainNavService: MainNavService,
		public elRef?: ElementRef) {

		//Esc from submenu item, need to close the open menu
		const selectedSubMenuHeaderNoExpandSubscription = this._mainNavService.selectedSubMenuHeaderNoExpand.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
			if (value && value.elRef !== undefined) {
				if (value === this && value.elRef.nativeElement.children.length > 0) {
					//close the open menu
					this.setExpanded(false);
				}
			}
    });
    this.mainNavSubscription.add(selectedSubMenuHeaderNoExpandSubscription);

		//parent over
    const isSubMenuParentOverSubscription = this._mainNavService.isSubMenuParentOver.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
			if (value && value.elRef !== undefined) {
				if (value === this && value.elRef.nativeElement.children.length > 0) {
					this.isParentOver = true;
				}
			}
    });
    this.mainNavSubscription.add(isSubMenuParentOverSubscription);

		//parent out
    const isSubMenuParentOutSubscription = this._mainNavService.isSubMenuParentOut.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
			if (value && value.elRef !== undefined) {
				if (value === this && value.elRef.nativeElement.children.length > 0) {
					this.isParentOver = false;
					//close the open menu
					this.setExpanded(false);
				}
			}
    });
    this.mainNavSubscription.add(isSubMenuParentOutSubscription);
	}

	ngOnInit(): void {
		this.setClickOnly();
	}
	ngOnDestroy(): void {
    if (this.mainNavSubscription) {
      this.mainNavSubscription.unsubscribe();
		}
	}
	/**
	 * Opens/closes sub menu
	 */
	toggleExpanded(): void {
		if (this.expanded === true) {
			this.setExpanded(false);
		} else {
			this.setExpanded(true);
		}
	}

	public setExpanded(val: boolean): void {
		this.expanded = val;
		this.expandedAria = val;
	}
	/**
	 * Don't fire keyboard events on tablet and below
	 */
	setClickOnly(): void {
		//******************************************/
		//must match this number in variables.scss
		// $screen-lg-min:1040;
		this.isClickOnly = window.innerWidth < 1040;
		//******************************************/
		//are we moving from tablet to desktop?
		if (this.isClickOnly) {
			//close any open menus
			this.setExpanded(false);
		}
	}
}
