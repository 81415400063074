import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserService } from "./services/user.service";
import { FooterComponent } from './ui/components/footer/footer.component';
import { MainNavComponent } from './ui/components/main-nav/main-nav.component';
import { NavSubMenuHeaderDirectiveDirective } from './ui/components/main-nav/nav-sub-menu-header.directive';
import { NavSubMenuItemDirectiveDirective } from './ui/components/main-nav/nav-sub-menu-item.directive';
import { NavSubMenuParentDirectiveDirective } from './ui/components/main-nav/nav-sub-menu-parent.directive';
import { BreadcrumbsComponent } from './ui/components/breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from "./ui/components/header/header.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UiLibrarySharedModule } from 'ui-library-shared';
import { DisclosureSearchComponent } from "./components/disclosure-search/disclosure-search.component";
import { DisclosureListComponent } from "./components/disclosure-list/disclosure-list.component";
import { DisclosureService } from "./services/disclosure.service";
import { IngredientService } from "./services/ingredient.service";
import { FileService } from "./services/file.service";
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { ApproveDenyDeleteCellComponent } from '../ag-grid/components/renderers/approve-deny-delete-cell/approve-deny-delete-cell.component';
import { UserInfoComponent } from './ui/components/user-info/user-info.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { RecaptchaBaseComponent } from "./components/recaptcha-base/recaptcha-base-component";
import { SwitchOrganizationComponent } from './components/switch-organization/switch-organization.component';
import { FFAgGridModule } from "../ag-grid/ff-ag-grid.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { FilterDownloadComponent } from './components/filter-download/filter-download.component';
import { UtilityService } from "./services/utilities.service";
import { CanaccessDirective } from "./ui/components/main-nav/canaccess.directive";
import { DashboardNotificationsComponent } from "./components/dashboard-notifications/dashboard-notifications.component";
import { PopperToolTipComponent } from "../popper-tool-tip/popper-tool-tip.component";
import { NgxPopperjsModule } from 'ngx-popperjs';
import { ReportsService } from "./services/reports.service";
import { InputWithCharacterFilterComponent } from "./components/input-with-character-filter/input-with-character-filter.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MainNavComponent,
    NavSubMenuHeaderDirectiveDirective,
    NavSubMenuItemDirectiveDirective,
    NavSubMenuParentDirectiveDirective,
    BreadcrumbsComponent,
    DisclosureSearchComponent,
    InputWithCharacterFilterComponent,
    DisclosureListComponent,
    DashboardComponent,
    DashboardNotificationsComponent,
    ApproveDenyDeleteCellComponent,
    UserInfoComponent,
    ApproveDenyDeleteCellComponent,
    RecaptchaBaseComponent,
    SwitchOrganizationComponent,
    FilterDownloadComponent,
    CanaccessDirective,
    PopperToolTipComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UiLibrarySharedModule,
    IconSpriteModule,
    RecaptchaModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    DpDatePickerModule,
    FFAgGridModule,
    NgSelectModule,
    NgxPopperjsModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    MainNavComponent,
    NavSubMenuHeaderDirectiveDirective,
    NavSubMenuItemDirectiveDirective,
    NavSubMenuParentDirectiveDirective,
    BreadcrumbsComponent,
    DisclosureListComponent,
    DisclosureSearchComponent,
    InputWithCharacterFilterComponent,
    RecaptchaModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    UiLibrarySharedModule,
    IconSpriteModule,
    DpDatePickerModule,
    RecaptchaBaseComponent,
    FFAgGridModule,
    NgSelectModule,
    PopperToolTipComponent,
    NgxPopperjsModule,
    FilterDownloadComponent
  ],
  providers: [
    UserService,
    DisclosureService, IngredientService,
    FileService, ReportsService,
    UtilityService
  ]
})

export class FracFocusCommonModule { }
