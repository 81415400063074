import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { concatMap, finalize, of, Subscription, tap } from 'rxjs';
import { Organization } from '../../../models/organizations/organization.model';
import { FFUserWithRoles } from '../../../models/users/ff-user-with-roles.model';
import { AuthService } from '../../../services/auth.service';
import { MainNavService } from '../../services/main-nav.service';
import { NavSubMenuHeaderDirectiveDirective } from './nav-sub-menu-header.directive';
import { CommonRoles } from '../../../models/perimitted-roles.model';
import { ReportsService } from '../../../services/reports.service';
import { SimpleReport } from '../../../../admin/models/reports/simple-report.model';

@Component({
  selector: 'ui-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MainNavComponent implements OnInit, OnDestroy {


  private mainNavSubscription = new Subscription();

  public hamburgerMenuDisplay: boolean = false;
  private currentActiveSubMenuHeader: NavSubMenuHeaderDirectiveDirective = new NavSubMenuHeaderDirectiveDirective(this._mainNavService, undefined);

  @ViewChildren(NavSubMenuHeaderDirectiveDirective) menuSubHeaders: QueryList<NavSubMenuHeaderDirectiveDirective> = new QueryList();
  public userInfo: FFUserWithRoles;
  public currentOrg: Organization;
  public availableReports: any[] = [];
  public adminReports: SimpleReport[] = [];

  constructor(private _mainNavService: MainNavService, private authService: AuthService, private reportService: ReportsService) {

  }

  public ngOnInit(): void {

    let userInfoAndReport$ = this.authService.userInfoEmitter.pipe(
      tap((result) => {
        this.userInfo = result;
        console.log('userInfo ', result);
        this.currentOrg = result.getCurrentOrg();
        if (this.userInfo && this.userInfo.roles && this.userInfo.roles.indexOf("Admin") > -1) {
          this.reportService.getAvailableAdminReportsBasic().subscribe(res => {
            this.adminReports = res;
          });
        }
      }),
      concatMap(() => {
        if (this.currentOrg) {
          return this.reportService.getAvailableReportsBasic(this.currentOrg.organizationId);
        }
        else {
          return of([]);
        }
      })
    ).subscribe({
      next: (reports: Array<SimpleReport>) => {
        this.availableReports = reports;
        this.mainNavSubscription.add(userInfoAndReport$);
      },
      error: (error) => {
        this.mainNavSubscription.add(userInfoAndReport$);
        console.log('Error listening for auth service user info. ' + error);
      }
    });

    this.authService.getUserInfo();

    //sub menu header
    const selectedSubMenuHeaderSubscription = this._mainNavService.selectedSubMenuHeader.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
      this.currentActiveSubMenuHeader = value;
      this.menuSubHeaders.toArray().forEach((navSubMenuHeader) => {
        if (navSubMenuHeader === value) {
          this.expandCurrentSubMenu();
        } else {
          this.closeSubMenu(navSubMenuHeader);
        }
      });
    });
    this.mainNavSubscription.add(selectedSubMenuHeaderSubscription);

    //sub menu tab, sub menu right arrow
    const manuKeySubscription = this._mainNavService.menuKey.subscribe((value: string) => {
      switch (value) {
        case "h-st":
          //menu header shift tab
          this.setNavMenuPreviousItemFocus();
          break;
        case "h-ar":
          //menu header arrow right
          this.setMainNavNextMenuItemFocus();
          break;
        case "h-al":
          //menu header arrow left
          this.setNavMenuPreviousItemFocus();
          break;
        case "s-ar":
          //sub menu item arrow right
          // close open sub menu
          this.closeOpenSubMenu();
          // set next top level item focus
          this.setMainNavNextMenuItemFocus(false);
          break;
        case "s-al":
          //sub menu item arrow left
          // close open sub menu
          this.closeOpenSubMenu();
          // set previous top level item focus
          this.setNavMenuPreviousItemFocus(false);
          break;
        default:
          console.log("missed case in main-nav-menu.component.ts mainNavSubscription: " + value);
      }
    });
    this.mainNavSubscription.add(manuKeySubscription);

    //sub menu shift tab
    const menuShipftTabSubscription = this._mainNavService.menuShiftTab.subscribe((value: string) => {
      this.setNavMenuPreviousItemFocus();
    });
    this.mainNavSubscription.add(menuShipftTabSubscription);

    //sub menu focus
    const subHeaderFocusSubscription = this._mainNavService.subHeaderFocus.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
      //set currently selected first level menu item
      this.currentActiveSubMenuHeader = value;
    });
    this.mainNavSubscription.add(subHeaderFocusSubscription);

    //tabbed sub menu header button 
    const subMenuHeaderTabClickSubscription = this._mainNavService.subMenuHeaderTabClick.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
      this.setMainNavNextMenuItemFocus();
      this._mainNavService.broadcastSelectedHeaderNoExpand(value);
    });
    this.mainNavSubscription.add(subMenuHeaderTabClickSubscription);

    //ESC from submenu item
    const selectSubMenuHeaderNoExpandSubscription = this._mainNavService.selectedSubMenuHeaderNoExpand.subscribe((value: NavSubMenuHeaderDirectiveDirective) => {
      //just highlight the header
      this.setMainItemFocus(value);
    });
    this.mainNavSubscription.add(selectSubMenuHeaderNoExpandSubscription);

    //sub menu open/close
    const closeOpenSubMenuSubscription = this._mainNavService.closeOpenSubMenu.subscribe((value: boolean) => {
      this.closeOpenSubMenu();
    });
    this.mainNavSubscription.add(closeOpenSubMenuSubscription);
  }

  public ngOnDestroy(): void {
    if (this.mainNavSubscription) {
      this.mainNavSubscription.unsubscribe();
    }
  }

  private closeSubMenu(subMenu: NavSubMenuHeaderDirectiveDirective): void {
    if (subMenu.elRef && subMenu.elRef.nativeElement.children.length > 0) {
      subMenu.setExpanded(false);
    }
  }
  private expandCurrentSubMenu(): void {
    //catch for single top level link with no sub menu
    if (this.currentActiveSubMenuHeader.elRef && this.currentActiveSubMenuHeader.elRef.nativeElement.children.length > 0) {
      //open sub menu
      this.currentActiveSubMenuHeader.setExpanded(true);
    }
  }

  /* sub menu headers */
  private closeOpenSubMenu(): void {
    this.menuSubHeaders.toArray().forEach((subHeader) => {
      if (subHeader.expanded) {
        subHeader.setExpanded(false);
      }
    });
  }


  /**
   * Update property to show/hide hamburger/X
   */
  public toggleMenuHamburger(): void {
    this.hamburgerMenuDisplay = !this.hamburgerMenuDisplay;
  }

  /**
   * Previous: First level menu item focus
   */
  private setNavMenuPreviousItemFocus(noExpand: boolean = true): void {
    let index = this.getNavMenuIndex();
    const prev = this.menuSubHeaders.get(index - 1);
    //set previous focus
    if (prev && prev.elRef) {
      this.setMainItemFocus(prev);
      //broadcast shift-tab target sub menu header
      const subHeader = this.menuSubHeaders.get(index - 1);
      if (subHeader !== undefined) {
        this.broadcastSelectedHeader(subHeader, noExpand);
      }
    } else {
      //set LAST focus
      if (this.menuSubHeaders.last.elRef) {
        this.setMainItemFocus(this.menuSubHeaders.last);
        //broadcast select header
        const subHeader = this.menuSubHeaders.last;
        if (subHeader !== undefined) {
          this.broadcastSelectedHeader(subHeader, noExpand);
        }
      }
    }
  }

  /**
   * Current Selected Link: First level menu item focus
   */
  private setMainNavNextMenuItemFocus(noExpand: boolean = true): void {
    let index = this.getNavMenuIndex();
    const next = this.menuSubHeaders.get(index + 1);
    //set next focus
    if (next && next.elRef) {
      this.setMainItemFocus(next);
      if (!noExpand) {
        this._mainNavService.broadcastSelectedHeaderExpand(next);
      }
    } else {
      //set first focus
      if (this.menuSubHeaders.first.elRef) {
        const first = this.menuSubHeaders.first;
        this.setMainItemFocus(first);
        if (!noExpand) {
          this._mainNavService.broadcastSelectedHeaderExpand(first);
        }
      }
    }
  }
  private broadcastSelectedHeader(subHeader: NavSubMenuHeaderDirectiveDirective, noExpand: boolean = true): void {
    if (noExpand) {
      this._mainNavService.broadcastSelectedHeaderNoExpand(subHeader);
    } else {
      this._mainNavService.broadcastSelectedHeaderExpand(subHeader);
    }
  }
  /**
   * First level currently focused index
   */
  private getNavMenuIndex(): any {
    let rtnIndex = -1;
    this.menuSubHeaders.toArray().forEach((navSubMenuHeader, index) => {
      if (navSubMenuHeader === this.currentActiveSubMenuHeader) {
        rtnIndex = index;
      }
    });
    return rtnIndex;
  }
  /**
   * Main set focus
   */
  private setMainItemFocus(navSubMenuHeader: NavSubMenuHeaderDirectiveDirective): void {
    if (navSubMenuHeader && navSubMenuHeader.elRef) {
      navSubMenuHeader.elRef.nativeElement.focus({ focusVisible: true });
    }

  }

  /* For accessing the common roles object in the DOM */
  public get CommonRoles() {
    return CommonRoles
  }
}
