import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNotNullOrEmpty } from '../../../../common/functions/is-not-null-or-empty.function';
import { IDateCellRendererParams } from '../../../models/date-cell-renderer-params.model';

@Component({
  selector: 'date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['./date-cell-renderer.component.scss']
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  public dateViewBind = "";
  public id: string = "";
  private params: IDateCellRendererParams;
  noDatePlaceholder: string = "N/A"
  constructor() {
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  public agInit(params: IDateCellRendererParams): void {
    this.params = params;

    if (isNotNullOrEmpty(this.params.noDatePlaceholder)) {
      this.noDatePlaceholder = this.params.noDatePlaceholder;
    }

    if (isNotNullOrEmpty(this.params.value)) {
      this.dateViewBind = new Date(this.params.value).toLocaleDateString();
      if (this.params.includeTimeString) {
        this.dateViewBind += " " + (new Date(this.params.value)).toLocaleTimeString();
      }
    }
    else {
      this.dateViewBind = this.noDatePlaceholder;
    }
  }

  public ngAfterViewInit() {
  }
}
