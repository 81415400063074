import { Component, OnInit, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { dateStringToUtcDate } from '../../functions/date-string-to-utc-date.function';
import { County } from '../../models/disclosures/county.model';
import { SearchFilter } from '../../models/lookup/search-filter';
import { StateShort } from '../../models/lookup/state-short.model';
import { DropdownService } from '../../services/dropdown.service';
import { LoggingService } from '../../services/logging.service';


@Component({
  selector: 'disclosure-search',
  templateUrl: './disclosure-search.component.html',
  styleUrls: ['./disclosure-search.component.scss']
})
export class DisclosureSearchComponent implements OnInit, OnDestroy {
  @Output() SearchStart = new EventEmitter<SearchFilter>();
  public wellApiNum: string = "";
  public wellName: string = "";
  public states: StateShort[];
  public counties: County[];
  public countyOptions: County[] = [];
  public selectedState: string = "";
  public selectedCounty = "";
  public fg: FormGroup = new FormGroup({
    state: new FormControl(null, [Validators.maxLength(2)]),
    countyId: new FormControl(null),
    apiNo: new FormControl(""),
    wellName: new FormControl(""),
    dateType: new FormControl("jobStartDate"),
    startDateRange: new FormControl(""),
    endDateRange: new FormControl(""),
  })
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dropdownService: DropdownService,
    private loggingService: LoggingService
  ) {
  }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    this.dropdownService.getCounties().then(ctys => this.counties = ctys);
    this.dropdownService.getStates().then(states => this.states = states);
  }
  public stateChanged() {
    if (this.fg.controls.state.value == "") {
      this.countyOptions = [];
      // this.selectedCounty = "";
      return;
    }
    // this.selectedCounty = "";
    this.countyOptions = this.counties.filter(x => x.state_ABBR == this.fg.controls.state.value);
  }
  public formUpdated($event: any) {
    console.log('form updated');
    let val = this.fg.getRawValue();
    val.propertyName = $event.target.name;
  }

  public startSearch() {
    var search: SearchFilter = {
      "wellName": null,
      "apiNumber": null,
      "countyId": null,
      "statusId": null,
      "submissionDateStart": null,
      "submissionDateEnd": null,
      "startDateStart": null,
      "startDateEnd": null,
      "stateAbbr": null
    };

    let startDateRange: Date = null;
    let endDateRange: Date = null;
    if (this.fg.get("startDateRange") && this.fg.get("startDateRange").value) {
      startDateRange = dateStringToUtcDate(this.fg.get("startDateRange").value);
    }
    if (this.fg.get("endDateRange") && this.fg.get("endDateRange").value) {
      endDateRange = dateStringToUtcDate(this.fg.get("endDateRange").value, true, 23, 59);
    }

    if (this.fg.get("state") && this.fg.get("state").value) search.stateAbbr = this.fg.get("state").value;
    if (this.fg.get("countyId") && this.fg.get("countyId").value) search.countyId = this.fg.get("countyId").value;
    //if (this.fg.get('50029204930000'))
    if (this.fg.get("apiNo") && this.fg.get("apiNo").value) search.apiNumber = this.fg.get("apiNo").value;
    if (this.fg.get("wellName") && this.fg.get("wellName").value) search.wellName = this.fg.get("wellName").value;
    if (this.fg.get('dateType') && this.fg.get("dateType").value == "jobStartDate") {
      search.startDateStart = startDateRange;
      search.startDateEnd = endDateRange;
    } else if (this.fg.get('dateType')) {
      search.submissionDateStart = startDateRange;
      search.submissionDateEnd = endDateRange;
    }
    if (!search.stateAbbr && !search.apiNumber && !search.wellName && !search.startDateStart && !search.startDateEnd &&
      !search.submissionDateStart && !search.submissionDateEnd) {
      this.loggingService.error("No criteria defined");
      return;
    }
    this.SearchStart.emit(search);
  }

  public resetSearch() {
    this.fg.reset();
  }
}
