import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss']
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public isEnabled : boolean;
  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.isEnabled = params.isEnabled;
  }
  refresh(params: any): boolean {
    return true;
  }

  onChange(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.params.setValue(checked);
  }  
}
