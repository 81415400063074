import { Component, OnInit } from '@angular/core';
import { FFUser } from '../common/models/users/ff-user.model';
import { AuthService } from '../common/services/auth.service';
import { DropdownService } from '../common/services/dropdown.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public userInfo: FFUser;
  public isOpenPageModalShowing: boolean = false
  public linkToOpen: string;
  public supportEmail: any;

  constructor(private authService: AuthService, private dropdownService: DropdownService) { }

  ngOnInit(): void {
    this.authService.getUserInfo().then(res => {
      this.userInfo = res;
    });
    this.dropdownService.getGlobalDefaults().then(gd => {
      this.supportEmail = gd.find(x => x.globalDefaultId == "SupportEmail")?.textValue
    });
  }

  public closeOpenPageModal() { this.isOpenPageModalShowing = false, this.linkToOpen = '' }

  public openExternalLink(link: string) {
    this.linkToOpen = link;
    this.isOpenPageModalShowing = true;
  }

  public handleOk() {
    window.open(this.linkToOpen, "_blank");
    this.isOpenPageModalShowing = false;
  }

  public handleCancel() {
    this.isOpenPageModalShowing = false;
    this.linkToOpen = '';
  }
}
