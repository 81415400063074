import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { type } from 'os';
import { PermissionsService } from 'src/app/common/services/permissions.service';
import { PermittedRoles } from '../../../models/perimitted-roles.model';
import { FFUserWithRoles } from '../../../models/users/ff-user-with-roles.model';
import { AuthService } from '../../../services/auth.service';

@Directive({
  selector: '[appCanaccess]'
})
export class CanaccessDirective {
  
  
  /**
   * Will check if the user has any of the provided roles. 
   * Works like *ngIf.
   *  Will render element if user has permissions. Will hide it if else.
  */
  @Input() public set appCanaccess({user, permissions, hiddenFromPermissions = []}: canAccessParams){

    if(!user){ return }
    
    this.viewContainer.clear(); //Prevents duplicated elements
    const hasAdminPermission = permissions.some(p => p.isAdmin);
    
    if(hasAdminPermission && user.isAdmin){
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    
    const canUserAccess = this.permissionService.canUserAccess(user, permissions, hiddenFromPermissions)
    if(canUserAccess){
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else{ 
      this.viewContainer.clear();
    }
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef,
     private auth: AuthService, private permissionService: PermissionsService) { }
}

export type canAccessParams = {
  user: FFUserWithRoles,
  permissions: PermittedRoles[],
  hiddenFromPermissions?: PermittedRoles[]
}
