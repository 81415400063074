import { Injectable, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, mergeMap, map } from 'rxjs';
import { FFUser } from '../models/users/ff-user.model';
import { OrganizationShort } from '../../admin/models/organizations/organization-short.model';
import { FFUserShort } from '../models/users/ff-user-short.model';
import { Organization } from '../models/organizations/organization.model';
import { OrganizationRelation } from '../models/organizations/organization-relation.model';
import { OrgRole } from '../models/organizations/org-role.model';
import { UserMapping } from '../models/users/user-mapping.model';
import { OrgRelationType } from "../models/users/org-relation-type.model";
import { LoggingService } from './logging.service';
import { FFUserWithRoles } from '../models/users/ff-user-with-roles.model';
import { OrganizationDefault } from '../models/organizations/organization-defaults.model';
import { FFUserFlat } from '../models/users/ff-user-flat.model';
import { OrgRegistration } from '../models/users/org-registration.model';

//import { ApplicationUser } from '../../models/ApplicationUser.model';

@Injectable()
export class UserService {

  private warningTimeout: any;
  private logoutTimeout: any;
  private authTimeoutValue: any = null;
  //The number of minutes before the auto-logout to show the Warning message
  private warningTimer: number = 5;
  private warningToast: any;
  private baseRoute: string = '/api/User';
  public authenticated: boolean = false;
  private infoRetrieved = false;
  public userInfo!: FFUserWithRoles;
  public orgs: any[] | null = null;
  public usrs: FFUserFlat[] | null = null;
  public registrations: OrgRegistration[] | null = null;
  private orgRoles: OrgRole[];
  private organizationDefaults: OrganizationDefault[];
  private orgRelTypes: OrgRelationType[];
  constructor(
    private http: HttpClient,
    private loggingService: LoggingService
    //private loggingService: LoggingService,
    //private toastr: ToastrService
  ) {
  }


  public get(): Observable<any> {
    return this.http.get<any>('/api/User');
  }

  public getOrgRelationTypes(): Promise<OrgRelationType[]>  {
    return new Promise((resolve, reject) => {
      if ((this.orgRelTypes && this.orgRelTypes.length)) {
        resolve(this.orgRelTypes);
      } else {
        this.http.get<OrgRelationType[]>('/api/User/OrgRelationTypes').subscribe((response: any) => {
      this.orgRelTypes = response;
      resolve(this.orgRelTypes);
    }, (error: any) => {
      this.loggingService.error("Error getting Organization Roles", "Error getting org Roles: " + error);
      reject(error);
    });
  }
});
  }

  public getOrgRoles(): Promise<OrgRole[]> {
    return new Promise((resolve, reject) => {
      if ((this.orgRoles && this.orgRoles.length)) {
        resolve(this.orgRoles);
      } else {
        this.http.get<OrgRole[]>('/api/User/OrgRoles').subscribe((response: any) => {
          this.orgRoles = response;
          resolve(this.orgRoles);
        }, (error: any) => {
          this.loggingService.error("Error getting Organization Roles", "Error getting org Roles: " + error);
          reject(error);
        });
      }
    });
  }

  public getUserById(userId: string): Observable<FFUser> {
    return this.http.get<FFUser>(`${this.baseRoute}/User/${userId}`);
  }

  public getUserByEmail(email: string): Observable<FFUser> {
    return this.http.get<FFUser>(`${this.baseRoute}/UserByEmail/${email}`);
  }

  public getOrganizationDefaults(orgId: string, forceRefresh = false, updateCachedItem = true): Promise<OrganizationDefault[]> {

    if (forceRefresh && updateCachedItem) this.organizationDefaults = [];

    return new Promise((resolve, reject) => {
      if ((this.organizationDefaults && this.organizationDefaults.length && updateCachedItem)) {
        resolve(this.organizationDefaults);
      } else {
        this.http.get<OrganizationDefault[]>(`${this.baseRoute}/OrganizationDefaults/${orgId}`).subscribe((response: any) => {
          response.map((x: { [x: string]: any; value: any; defaultType: string; }) => x.value = x[x.defaultType.toLowerCase() + "Value"])

          if (updateCachedItem) {
            this.organizationDefaults = response;
            resolve(this.organizationDefaults);
          }
          else
            resolve(response);
        }, (error: any) => {
          this.loggingService.error("Error getting Organization Defaults", "Error getting org defaults: " + error);
          reject(error);
        });
      }
    });
  }

  public updateOrgDefault(orgDefault:any) {
    return this.http.put<any>(`${this.baseRoute}/UpdateMyOrgDefault/`, orgDefault);
  }
  public getOrg(orgId: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseRoute}/Company/${orgId}`).pipe(map(res => new Organization(res)));
  }
  /*private toOrgObj(x:Promise<Organization>) {
    return Observable.fromPromise(new Organization(x));
  }*/

  public getOrgsShort(forceRefetch: boolean = false): Promise<Array<OrganizationShort>> {
    return new Promise((resolve, reject) => {
      if (this.orgs && !forceRefetch) {
        resolve(this.orgs);
      } else {
        this.http.get<any>('/api/User/AllCompanies').subscribe((response: any) => {
          this.orgs = response;
          resolve(this.orgs);
        }, (error: any) => {
          this.loggingService.error("Error getting company list", "Error getting company list: " + error);
          reject(error);
        });
      }
    });
  }

  public GetOrgsByNameString(orgname: string) {
    return this.http.get<Organization[]>('/api/User/GetOrgsByNameString/' + orgname);
  }

  public getUsersShort(forceRefetch: boolean = false): Promise<Array<FFUserFlat>> {
    return new Promise((resolve, reject) => {
      if (this.usrs && !forceRefetch) {
        resolve(this.usrs);
      } else {
        this.http.get<any>('/api/User/AllUsers').subscribe((response: any) => {
          this.usrs = response;
          resolve(this.usrs);
        }, (error: any) => {
          this.loggingService.error("Error getting user list", "Error getting user list: " + error);
          reject(error);
        });
      }
    });
  }

  public getSupervisors(forceRefetch: boolean = false): Promise<Array<FFUserShort>> {
    return new Promise((resolve, reject) => {
      if (this.usrs && !forceRefetch) {
        resolve(this.usrs);
      } else {
        this.http.get<any>('/api/User/AllSupervisors').subscribe((response: any) => {
          this.usrs = response;
          resolve(this.usrs);
        }, (error: any) => {
          this.loggingService.error("Error getting confirmed user list", "Error getting confirmed user list: " + error);
          reject(error);
        });
      }
    });
  }

  public getRegistrations(forceRefetch: boolean = false): Promise<Array<OrgRegistration>> {
    return new Promise((resolve, reject) => {
      if (this.registrations && !forceRefetch) {
        resolve(this.registrations);
      } else {
        this.http.get<any>('/api/User/GetRegistrations').subscribe((response: any) => {
          this.registrations = response;
          resolve(this.registrations);
        }, (error: any) => {
          this.loggingService.error("Error getting registrations", "Error getting registrations: " + error);
          reject(error);
        });
      }
    });
  }

  public updateMissingRequiredFields(user: FFUser) {
    if (!user.addressLine1) user.addressLine1 = "";
    if (!user.addressLine2) user.addressLine2 = "";
    if (!user.city) user.city = "";
    if (!user.state) user.state = "";
    if (!user.zip) user.zip = "";
    if (!user.userMappings) user.userMappings = [];
    if (!user.validationAnswer) user.validationAnswer = "";
    if (!user.organization) user.organization = "";
    if (!user.organizationType) user.organizationType = "";
    if (!user.phoneNumber) user.phoneNumber = "";
    if (!user.mobilePhoneNumberForTwoFA) user.mobilePhoneNumberForTwoFA = "";
    if (!user.concurrencyStamp) user.concurrencyStamp = "";
    if (!user.normalizedEmail) user.normalizedEmail = "";
    if (!user.passwordHash) user.passwordHash = "";
    if (!user.securityStamp) user.securityStamp = "";
  }

  public getMyCompany(id: string) {
    return this.http.get<any>('/api/User/MyCompany/' + id).pipe(map(res => new Organization(res)));
  }
  public getMyCompanyWithoutUserMappings(id: string) {
    return this.http.get<any>('/api/User/MyCompanyWithoutUserMappings/' + id).pipe(map(res => new Organization(res)));
  }
  public getUserMappings(orgId: string): Observable<Array<UserMapping>> {
    return this.http.get<Array<UserMapping>>(`${this.baseRoute}/UserMappings/` + orgId);
  }
  public getCompany(id:string) {
    return this.http.get<any>('/api/User/Company/' + id).pipe(map(res => new Organization(res)));
  }
  public addMyUserMapping(um: UserMapping) {
    return this.http.post<UserMapping>("/api/User/AddMyUserMapping", um);
  }
  public addMyOrganization(co: Organization) {
    return this.http.post<any>(`/api/User/AddMyOrganization`, co);
  }
  public updateMyUser(userObj: FFUser) {
    return this.http.put<any>(`/api/User/UpdateMyUser`, userObj);
  }
  public updateUser(userObj: FFUser) {
    return this.http.put<any>(`/api/User/UpdateUser`, userObj);
  }
  public updateMyCompany(co: Organization) {
    return this.http.put<any>(`/api/User/UpdateMyCompany/` + co.organizationId, co);
  }
  public updateCompany(co: Organization) {
    return this.http.put<any>(`/api/User/UpdateCompany/` + co.organizationId, co);
  }
  public addMyOrganizationRelation({organizationId, relatedOrganizationId, orgRelationTypeId, statusId}: OrganizationRelation) {
    return this.http.get<OrganizationRelation[]>(`/api/User/AddMyOrganizationRelation/${organizationId}/${relatedOrganizationId}/${orgRelationTypeId}/${statusId}`);
  }
  public updateMyOrganizationRelation({organizationId, organizationRelationId, orgRelationTypeId, statusId}: OrganizationRelation) {
    return this.http.get<OrganizationRelation[]>(`/api/User/UpdateMyOrganizationRelation/${organizationId}/${organizationRelationId}/${orgRelationTypeId}/${statusId}`);
  }
  public removeOrgRelation(co: OrganizationRelation) {
    return this.http.delete<any>(`/api/User/removeOrgRelation/${co.organizationId}/${co.relatedOrganizationId}/${co.orgRelationTypeId}`);
  }
  public removeUserMapping(orgId: string, userId: string, roleId: string) {
    return this.http.delete<any>(`/api/User/removeUserMapping/${orgId}/${userId}/${roleId}`);
  }
  public removePendingUserMappings(orgId: string, userId: string) {
    return this.http.delete<any>(`/api/User/removePendingUserMappings/${orgId}/${userId}`);
  }
  public updateDefaultUserMapping(userMapping: UserMapping) {
    return this.http.put<any>(`/api/User/UpdateDefaultUserMapping`, userMapping);
  }
  public approveUser(o: OrgRegistration) {
    return this.http.put<any>(`/api/User/ApproveUser`, o);
  }
  public denyUser(o: OrgRegistration) {
    return this.http.put<any>(`/api/User/DenyUser`, o);
  }
  public deleteUser(o: OrgRegistration) {
    return this.http.put<any>(`/api/User/DeletePrimaryOrganizationMapping`, o);
  }
  public setMyDefaultOrg(orgid: string) {
    return this.http.get<any>(`/api/User/SetMyDefaultOrg/${orgid}`);
  }
  public getNotificationsForOrg(orgid: string) {
    return this.http.get<any>(`/api/User/GetDashboardNotifications/${orgid}`);
  }
  public getOrganizationUserHistory(orgId: string, userId: string) {
    return this.http.get<any>(`/api/User/GetOrganizationUserHistory/${orgId}/${userId}`);
  }
  public getOrgRelationHistory(orgId: string, relatedOrgId: string) {
    return this.http.get<any>(`/api/User/GetOrganizationRelationHistory/${orgId}/${relatedOrgId}`);
  }
  public getOrganizationHistory(orgId: string) {
    return this.http.get<any>(`/api/User/GetOrganizationHistory/${orgId}`);
  }
  public getMyOperatorApprovals(orgId: string) {
    return this.http.get<Organization>(`/api/User/GetMyOperatorApprovals/${orgId}`);
  }
  public updateMyOrgDefault(orgDefault: any) {
    return this.http.put<any>(`/api/User/UpdateMyOrgDefault`, orgDefault);
  }
  public getOrganizationStats() {
    return this.http.get<any>('/api/User/OrganizationStats');
  }
  public activateOrganization(o: OrgRegistration) {
    return this.http.put<any>(`/api/User/activateOrganization/${o.organizationId}`, o);
  }
  public deactivateOrganization(o: OrgRegistration) {
    return this.http.put<any>(`/api/User/deactivateOrganization/${o.organizationId}`, o);
  }
  public getRegistrationByUserId(id: string) {
    return this.http.get<OrgRegistration>('/api/User/GetRegistrationByUserId/' + id);
  }
}
