
<div class="icons-container">
    <div>
        <button uiLibButton
        *ngIf="(disclosure.submittedCount==1 && disclosure.editCount<1)"
        [color]="'PRIMARY' | buttonColorEnumPipe"
        [size]="'SMALL' | buttonSizeEnumPipe"
        [shape]="'ROUNDED' | buttonShapeEnumPipe"
        (click)="open()"
        title="Open disclosure to amend">
            Amend
        </button> 
    </div>
    <div class="pdf-icon-folder">
  <ui-icon-button [title]="'View PDF version of the disclosure'" [iconName]="'icon-pdf'" [heightInPx]="'27'" [widthInPx]="'20'"  (click)="viewPdf()"></ui-icon-button>
</div>
</div>


