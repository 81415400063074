export function dateStringToUtcDate(inputDateString: string, offsetTheTime: boolean = false, hourToSet: number = 0, minutesToSet: number = 0): Date {
  let inputDate = new Date(inputDateString);
  let timezoneOffset = inputDate.getTimezoneOffset();

  let timeDiffHours = timezoneOffset / 60;
  let timeDiffMinutes = timezoneOffset % 60;

  let hoursToAdd = offsetTheTime ? hourToSet - timeDiffHours : timeDiffHours;
  let minutesToAdd = offsetTheTime ? minutesToSet - timeDiffMinutes : timeDiffMinutes;

  inputDate.setUTCMinutes(inputDate.getUTCMinutes() + minutesToAdd);
  inputDate.setUTCHours(inputDate.getUTCHours() + hoursToAdd);
  return inputDate;
}
