import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { filter, finalize, Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { FFUser } from '../../../../common/models/users/ff-user.model';
import { DropdownService } from '../../../services/dropdown.service';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  isCursorEnable: boolean = false;
  public basicNotifications: any[];
  public isImpersonated: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dropdownService: DropdownService) {
    const header$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        finalize(() => {
          this.subscription.add(header$);
        }))
      .subscribe(
        (event: any) => {
          if (event instanceof NavigationEnd) {
            this.authService.getUserInfo().then((res: FFUser) => {
              this.isImpersonated =  res.isImpersonated;
              if (res.userName && event.url === '/') {
                this.isCursorEnable = true;
              } else if (event.url === '/' || event.url === '/dashboard') {
                this.isCursorEnable = false;
              } else {
                this.isCursorEnable = true;
              }
            })
          }
        });
  }
  ngOnInit(): void {
    this.dropdownService.getCurrentBasicNotifications().then(nots => {
      //this.dropdownService.currentBasicNotifications;
    });
    this.dropdownService.setBasicNotifications.subscribe(res => {
      this.basicNotifications = res;
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public onLogoClick() {
    let path = this.authService.authenticated ? "/dashboard" : "/";
    this.router.navigate([path]);
  }

  public exitImpersonation(): void {
    var srv$ = this.authService.impersonateLogout().subscribe({
      next: (res) => {
        const returnUrl = res.returnUrl;
        this.authService.getUserInfo(true).then((res: FFUser) => {
          this.isImpersonated = false;
          this.router.navigate([returnUrl]);
        })
        this.subscription.add(srv$);
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

}
